import { Listbox, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { ArrowDown2 } from 'iconsax-react'
import { ComponentPropsWithoutRef, Fragment, useEffect, useState } from 'react'
import { ListItem } from 'static/interfaces'

interface Props extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  list: ListItem[]
  chosenItems?: ListItem[]
  onChange: (item: ListItem[]) => void
  label: string
}

export const MultiSelect = ({
  list,
  onChange,
  label,
  chosenItems = [],
  className,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedItems, setSelectedItems] = useState<ListItem[]>(chosenItems)

  useEffect(() => {
    onChange(selectedItems)
  }, [selectedItems])

  function isSelected(item: ListItem) {
    return Boolean(
      selectedItems.find((element: ListItem) => element.id === item.id),
    )
  }

  function handleSelect(item: ListItem) {
    if (!isSelected(item)) {
      const selectedItemsUpdated = [
        ...selectedItems,
        list.find((element: ListItem) => element.id === item.id),
      ]
      setSelectedItems(selectedItemsUpdated as ListItem[])
    } else {
      handleDeselect(item)
    }
  }

  function handleDeselect(item: ListItem) {
    const selectedItemsUpdated = selectedItems.filter(
      (element: ListItem) => element.id !== item.id,
    )
    setSelectedItems(selectedItemsUpdated)
  }

  return (
    <div className={className}>
      <div className='w-full'>
        <Listbox
          as='div'
          value={selectedItems}
          // @ts-expect-error Несответствие типов
          onChange={(element: ListItem) => handleSelect(element)}
          open={isOpen}
        >
          {() => (
            <>
              <Listbox.Label className='font-medium mb-1 inline-block'>
                {label}
              </Listbox.Label>
              <div className='relative'>
                <Listbox.Button
                  className='font-medium flex flex-row items-center justify-between w-full rounded-lg border border-[#D0D5DD] outline-none px-4 py-3 text-dark'
                  onClick={() => setIsOpen(!isOpen)}
                >
									<span className='truncate'>
										{selectedItems.length === 0
                      ? 'Выберите '
                      : `Выбрано (${selectedItems.length})`}
									</span>
                  <ArrowDown2 className='ml-auto' size='18' color='#31D9BD'/>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-200'
                  enterFrom='opacity-0 translate-y-1'
                  enterTo='opacity-100 translate-y-0'
                  leave='transition ease-in duration-150'
                  leaveFrom='opacity-100 translate-y-0'
                  leaveTo='opacity-0 translate-y-1'
                >
                  <Listbox.Options
                    className={clsx('absolute rounded-3xl shadow-lg z-10 bg-white w-full')}
                  >
                    {list?.map((item: ListItem, index) => {
                      const selected = isSelected(item)
                      return (
                        <Listbox.Option
                          key={item.id}
                          value={item}
                        >
                          <div className={clsx(
                            'p-2 flex flex-row font-medium bg-white hover:bg-[#F0FCFF] transition-all cursor-pointer',
                            index === list.length - 1 && 'rounded-b-xl',
                          )}>
													<span
                            className={`${
                              selected ? 'font-semibold' : 'font-normal'
                            } block truncate`}
                          >
														{item.value}
													</span>
                          </div>
                        </Listbox.Option>
                      )
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
    </div>
  )
}

import close from 'helpers/images/closeX.svg'

interface Props {
	badges: string[]
	onDelete: (id: number) => void
}

export const ServiceBadge = ({ badges, onDelete }: Props) => {
	return (
		<div className='flex flex-row flex-wrap gap-5 py-4 w-full'>
			{badges &&
				badges.map((badge, index) => (
					<div
						key={index}
						className='flex flex-row gap-1.5 text-[#002C50] font-normal text-base rounded-2xl text-center py-2 px-4 bg-[#F5F3F7] '
					>
						<p>{badge}</p>
						<button
							className='text-[#4B3A5A] font-bold'
							onClick={() => onDelete(index)}
							type='button'
						>
							<img src={close} width={10} height={10} alt='close' />
						</button>
					</div>
				))}
		</div>
	)
}

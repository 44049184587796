import clsx from 'clsx'
import { ComponentPropsWithoutRef, forwardRef } from 'react'

interface Props extends ComponentPropsWithoutRef<'textarea'> {
	label?: string
	error?: string
	className?: string
}

export const ProfileTextarea = forwardRef<HTMLTextAreaElement, Props>(
	({ label, error, className, ...rest }: Props, ref) => {
		return (
			<>
				<label className={clsx('font-medium text-dark-blue block', className)}>
					{label && <span className='mb-1 inline-block'>{label}</span>}
					<textarea
						ref={ref}
						className='block w-full h-full rounded-lg border border-[#D0D5DD] outline-none px-4 py-3 text-dark font-medium'
						{...rest}
					/>
				</label>
				{error && <span className='text-red font-medium text-sm mt-0.5'>{error}</span>}
			</>
		)
	},
)

ProfileTextarea.displayName = 'ProfileTextarea'

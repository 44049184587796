import { AddPhoto } from 'static/interfaces'
import { $authHost } from '..'
import { schemas } from '../types'

export const getMyPhotos = async (
	id: string | number | undefined,
): Promise<schemas['GuideShots'][]> => {
	const { data } = await $authHost.get(`/v1/guide-shots/?guide_id=${id}`)
	return data
}

export const addPhoto = async ({
	formData,
}: AddPhoto): Promise<schemas['GuideShots']> => {
	$authHost.defaults.headers.common['Content-Type'] = 'multipart/form-data'
	const { data } = await $authHost.post('/v1/guide-shots/', formData)
	return data
}

export const deletePhoto = async (id: number | string | undefined) => {
	const { data } = await $authHost.delete(`/v1/guide-shots/${id}/`)
	return data
}

export const editPhoto = async (
	request: schemas['PatchedGuideShotsRequest'] & {
		id: number | string | undefined
	},
) => {
	const { data } = await $authHost.patch(
		`/v1/guide-shots/${request.id}/`,
		request,
	)
	return data
}

import { schemas } from '@/api/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deletePhoto, editPhoto } from 'api/photos'
import clsx from 'clsx'
import { AddVideo } from 'components/modal/create/AddVideo'
import { EditPhotos } from 'components/modal/edit/EditPhotos'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ContentBody } from 'components/ui/Content/ContentBody'
import { ContentHeader } from 'components/ui/Content/ContentHeader'
import { Trash } from 'iconsax-react'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useUserStore } from 'store/user'

interface Props {
	photos: schemas['TourShots'][]
	video: schemas['Tour']['youtube_url']
}

export const ChangePlacePhotos = ({ photos, video }: Props) => {
	const queryClient = useQueryClient()
	const { user } = useUserStore()
	const { mutate: _delete, isLoading: isDeleting } = useMutation(deletePhoto)
	const { mutate: edit } = useMutation(editPhoto)

	const [isChangePhotosModalVisible, setIsChangePhotosModalVisible] =
		useState(false)
	const [isAddVideoModalVisible, setIsAddVideoModalVisible] = useState(false)

	const [draggedPhoto, setDraggedPhoto] = useState<schemas['TourShots']>()

	const onDragStart = (
		event: React.DragEvent<HTMLDivElement>,
		photo: schemas['TourShots'],
	) => {
		setDraggedPhoto(photo)
	}
	const onDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
		event.currentTarget.style.transform = 'translateX(0px)'
	}
	const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
		event.currentTarget.style.transform = 'translateX(0px)'
	}
	const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault()
		event.currentTarget.style.transform = 'translateX(10px)'
	}

	const onDrop = (
		event: React.DragEvent<HTMLDivElement>,
		droppedOnPhoto: schemas['TourShots'],
	) => {
		event.preventDefault()
		queryClient.cancelQueries(['org', 'photos', user?.partner?.tourId])
		queryClient.setQueryData(
			['org-photos', user?.partner?.tourId],
			photos.map(photo => {
				if (photo.id === droppedOnPhoto?.id) {
					edit({ id: droppedOnPhoto?.id, order: draggedPhoto?.order })
					return { ...photo, order: draggedPhoto?.order }
				}

				if (photo.id === draggedPhoto?.id) {
					edit({ id: draggedPhoto?.id, order: droppedOnPhoto?.order })
					return { ...photo, order: droppedOnPhoto.order }
				}

				return photo
			}),
		)

		event.currentTarget.style.transform = 'translateX(0px)'
	}

	return (
		<>
			<EditPhotos
				isVisible={isChangePhotosModalVisible}
				onClose={() => {
					setIsChangePhotosModalVisible(false)
				}}
			/>
			<AddVideo
				isVisible={isAddVideoModalVisible}
				onClose={() => {
					setIsAddVideoModalVisible(false)
				}}
				tourId={user?.partner?.tourId}
			/>
			<div className='block mx-auto w-3/4 max-w-5xl'>
				<ContentHeader className='flex w-full items-center justify-between py-5 mt-10'>
					<p className='font-bold text-2xl text-dark'>Фотографии</p>
					<div className='flex flex-row gap-5'>
						<ProfileButton
							onClick={() => {
								setIsAddVideoModalVisible(true)
							}}
						>
							{video ? 'Изменить видео' : 'Добавить видео'}
						</ProfileButton>
						<ProfileButton
							onClick={() => {
								setIsChangePhotosModalVisible(true)
							}}
						>
							Добавить фото
						</ProfileButton>
					</div>
				</ContentHeader>
				<ContentBody className='flex flex-wrap py-16 px-16 justify-start gap-y-5'>
					{photos
						.sort((a, b) => a.order - b.order)
						.map(photo => (
							<div
								onDragStart={event => {
									onDragStart(event, photo)
								}}
								onDragEnd={event => {
									onDragEnd(event)
								}}
								onDragLeave={event => {
									onDragLeave(event)
								}}
								onDragOver={event => {
									onDragOver(event)
								}}
								onDrop={event => {
									onDrop(event, photo)
								}}
								draggable
								key={photo.id}
								className='relative image-wrapper group transition-all duration-200 ease-in-out mr-4 cursor-grab'
							>
								<span
									style={{
										backgroundColor: 'rgba(0, 0, 0, 0.3)',
									}}
									className='transition-all duration-200 ease-in-out group-hover:visible invisible flex group-hover:opacity-100 opacity-0 backdrop-blur-md rounded-xl absolute z-10 w-full items-center justify-center h-full'
								>
									{isDeleting ? (
										<svg
											className={clsx(
												'h-0 w-0 transition-all ease-out duration-300',
												isDeleting ? 'h-max w-[25px]' : '',
											)}
											version='1.1'
											id='L9'
											xmlns='http://www.w3.org/2000/svg'
											xmlnsXlink='http://www.w3.org/1999/xlink'
											x='0px'
											y='0px'
											viewBox='0 0 100 100'
											enableBackground='new 0 0 0 0'
											xmlSpace='preserve'
										>
											<path
												fill='#FFFFFF'
												d='M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50'
											>
												<animateTransform
													attributeName='transform'
													attributeType='XML'
													type='rotate'
													dur='1s'
													from='0 50 50'
													to='360 50 50'
													repeatCount='indefinite'
												/>
											</path>
										</svg>
									) : (
										<Trash
											onClick={() => {
												_delete(photo.id, {
													onSuccess: () => {
														toast.success('Успешно удалено')
														queryClient.refetchQueries([
															'org-photos',
															user?.partner?.tourId,
														])
													},
													onError: () => {
														toast.error(
															'Произошла ошибка при удалении, попробуйте позднее',
														)
													},
												})
											}}
											className='bg-[#FF3030] w-10 p-[6px] h-10 flex items-center justify-center rounded-xl cursor-pointer'
											size='20'
											color='#FFFFFF'
										/>
									)}
								</span>
								<span className='relative block rounded-xl'>
									<img
										className='rounded-xl w-[200px] h-[150px]'
										src={photo.photo}
										alt='Загруженная фотография'
									/>
								</span>
							</div>
						))}
				</ContentBody>
			</div>
		</>
	)
}

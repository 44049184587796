import { TickSquare } from 'iconsax-react'

interface Props {
    checked: boolean
    onChange: (state: boolean) => void
}

export const Checkbox = ({ checked, onChange }: Props) => {
    return (
        <label className=''>
            <input
                className='hidden'
                type='checkbox'
                onChange={() => {
                    onChange(!checked)
                }}
            />
            {checked ? (
                <TickSquare size='16' color='#21BCDE' />
            ) : (
                <div className='w-4 h-4 rounded-md border border-[#21BCDE]'></div>
            )}
        </label>
    )
}

export default Checkbox

import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

export interface IdsStore {
    numberId: number
    setNumberId: (numberId: number) => void
    serviceId: number
    setServiceId: (serviceId: number) => void
    packageId: number
    setPackageId: (packageId: number) => void
}

export const useIdsStore = create<IdsStore>()(
    devtools(
        persist(
            set => ({
                numberId: 0,
                serviceId: 0,
                packageId: 0,
                setNumberId: (id: number) =>
                    set(() => ({
                        numberId: id
                    })),
                setServiceId: (id: number) =>
                    set(() => ({
                        serviceId: id
                    })),
                setPackageId: (id: number) =>
                    set(() => ({
                        packageId: id
                    }))
            }),
            {
                name: 'ids',
            },
        ),
    ),
)
import { DehydratedState } from '@tanstack/react-query'
import { schemas } from '../api/types'
import { Guest } from '../store/payment'
import { calcDayDifference } from './bookingSystemUtils'

export const priceSpacing = (number: number) => {
	const parts = number.toString().split('.')
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
	return parts.join('.')
}

export const formatBytes = (bytes: number, decimals = 2) => {
	if (!+bytes) return '0 Bytes'

	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Байт', 'КБ', 'МБ', 'ГБ']

	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const calcAvgRate = (tour: schemas['TourList'] | schemas['TourRead']) =>
	Math.round(
		((tour.averageRating.location__avg! +
			tour.averageRating.proportion__avg! +
			tour.averageRating.purity__avg! +
			tour.averageRating.service__avg! +
			tour.averageRating.staff__avg!) /
			5) *
			100,
	) / 100

export const json = (data: DehydratedState) => JSON.parse(JSON.stringify(data))

export const encodeQueryData = (data: any) => {
	if (!data) return ''
	const ret = []
	for (const d in data)
		ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
	return ret.join('&')
}

export const refreshStartDate = (date: Date) => {
	if (calcDayDifference(new Date(date), new Date()) < 1) {
		return new Date(date)
	} else {
		return new Date()
	}
}

export const refreshEndDate = (startDate: Date, endDate: Date) => {
	if (calcDayDifference(new Date(startDate), new Date(endDate)) <= 0) {
		return getNextDay(new Date(startDate))
	}
	return new Date()
}

export const generateUUID = () => {
	let d = new Date().getTime() //Timestamp
	let d2 =
		(typeof performance !== 'undefined' &&
			performance.now &&
			performance.now() * 1000) ||
		0 //Time in microseconds since page-load or 0 if unsupported
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		let r = Math.random() * 16 //random number between 0 and 16
		if (d > 0) {
			//Use timestamp until depleted
			r = (d + r) % 16 | 0
			d = Math.floor(d / 16)
		} else {
			//Use microseconds since page-load if supported
			r = (d2 + r) % 16 | 0
			d2 = Math.floor(d2 / 16)
		}
		return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
	})
}

export const getNextDay = (date: Date = new Date()) => {
	return new Date(date.getTime() + 1000 * 3600 * 24)
}

export const getPrevDay = (date: Date = new Date()) => {
	return new Date(date.getTime() - 1000 * 3600 * 24)
}

export const getSummMultiplier = (guests: Guest[]) => {
	let init = 0
	guests.forEach(guest => {
		if (guest.age === 'adult') {
			init++
		} else {
			init = init + 0.5
		}
	})
	return init
}

export const getAmountOfGuests = (guests: Guest[]) => {
	let adults = 0
	let children = 0
	let babies = 0
	guests.forEach(guest => {
		if (guest.age === 'adult') {
			adults++
		} else if (guest.age === 'child') {
			children++
		} else if (guest.age === 'baby') {
			babies++
		}
	})

	return { adults, children, babies }
}

export const getAdress = (adress: (string | undefined | null)[]) => {
	return adress.filter(item => item).join(', ')
}

export const getImageURL = (url: string) => {
	return process.env.REACT_APP_API_URL + '/media/' + url
}

export const removeSpaces = (value: string) => {
	return value.trim() === value
}

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createBooking } from 'api/crm'
import { getNumberRoomsByNumberId } from 'api/numberCabinets'
import clsx from 'clsx'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ProfileListBox } from 'components/profile/ProfileListBox'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import toast from 'react-hot-toast'
import { useUserStore } from 'store/user'
import { getNextDay, getPrevDay } from 'utils/utils'
import { Modal } from '../Modal'

interface Props {
	isVisible: boolean
	onClose: () => void
	numberId?: number
}

export const CloseNumber = ({ isVisible, onClose, numberId }: Props) => {
	const queryClient = useQueryClient()
	const { user } = useUserStore()
	const { mutate } = useMutation(createBooking)

	const [startDate, setStartDate] = useState(new Date())
	const [endDate, setEndDate] = useState(getNextDay())
	const [room, setRoom] = useState<number>()

	const numberCabinets = useQuery(['close-number'], () =>
		getNumberRoomsByNumberId(numberId),
	)

	const onSubmit = () => {
		if (!room) {
			return
		}
		mutate(
			{
				closed_for_repair: true,
				start: startDate.toISOString().split('T')[0],
				end: endDate.toISOString().split('T')[0],
				number: numberId as number,
				number_cabinets: room,
				partner: user?.partner?.id,
				tour: user?.partner?.tourId as number,
				amount: 0,
			},
			{
				onSuccess: () => {
					toast.success('Бронь успешно создана')
					queryClient.refetchQueries(['bookings', user?.partner?.tourId])
					onClose()
				},
				onError: () => {
					toast.error('Не получилось создать бронь')
				},
			},
		)
	}

	return (
		<Modal
			className='px-8 py-8 bg-white max-w-xl'
			isVisible={isVisible}
			onClose={onClose}
		>
			<div className='flex flex-row gap-x-3'>
				<div className='basis-1/2 '>
					<span className='font-medium inline-block mb-1'>Дата начала</span>
					<DatePicker
						selectsStart
						startDate={new Date(startDate)}
						minDate={new Date()}
						endDate={new Date(endDate)}
						tabIndex={-1}
						selected={new Date(startDate)}
						dateFormat='d MMMM, yyyy'
						showPopperArrow={false}
						popperPlacement='bottom'
						locale='ru'
						preventOpenOnFocus={false}
						customInput={
							<input
								type='text'
								className={clsx(
									'text-[#6B7280] outline-none  border rounded-lg px-3 py-2 w-full',
								)}
							/>
						}
						onChange={date => {

							if (date) {
								if (date.getTime() >= new Date(endDate).getTime()) {
									setEndDate(getNextDay(date))
								}
								setStartDate(date)
							}
						}}
					/>
				</div>
				<div className='basis-1/2 '>
					<span className='font-medium inline-block mb-1'>Дата конца</span>
					<DatePicker
						selectsEnd
						selected={new Date(endDate)}
						minDate={getNextDay()}
						startDate={startDate}
						endDate={new Date(endDate)}
						showPopperArrow={false}
						tabIndex={-1}
						autoFocus={false}
						dateFormat='d MMMM, yyyy'
						popperPlacement='bottom'
						locale='ru'
						preventOpenOnFocus={false}
						customInput={
							<input
								type='text'
								className={clsx(
									'text-[#6B7280] outline-none border rounded-lg px-3 py-2 w-full',
								)}
							/>
						}
						onChange={date => {

							if (date) {
								if (date.getTime() <= new Date(startDate).getTime()) {
									setStartDate(getPrevDay(date))
								}
								setEndDate(date)
							}
						}}
					/>
				</div>
			</div>
			<ProfileListBox
				className='mt-4'
				label='Выберите номер'
				list={
					numberCabinets.data?.map(item => ({
						id: item.id,
						value: item.humanize_name ?? '',
					})) ?? []
				}
				onChange={item => {
					setRoom(item.id)
				}}
				width='w-full'
			/>
			<ProfileButton className='mt-4 ml-auto' onClick={onSubmit}>
				Закрыть номер
			</ProfileButton>
		</Modal>
	)
}

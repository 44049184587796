import { useQuery } from '@tanstack/react-query'
import { getAllMyServices } from 'api/paidServices'
import { Spinner } from 'components/ui/Spinner'
import { Services } from 'modules/pages/services/Services'
import { Helmet } from 'react-helmet'
import { useUserStore } from 'store/user'

export const ServicesPage = () => {
	const { user } = useUserStore()
	const { data, isLoading, isError } = useQuery(
		['organization-services', user?.partner?.tourId],
		() => {
			return getAllMyServices(user?.partner?.tourId)
		},
	)


	if (isLoading) return <Spinner />
	if (isError) return <>Error</>

	return (
		<>
			<Helmet>
				<title>Услуги | MTour</title>
				<meta name='robots' content='noindex' />
			</Helmet>
			<Services services={data} />
		</>
	)
}

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { phoneNumberDelete } from 'api/ProfileService'
import {
  addForTourAdditionalInfoTitle,
  createTitleAdditionalService,
  deleteTitleAdditionalService,
  getAllTourAdditionalInfoTitles,
  getTourTitles,
} from 'api/additionalServices'
import { editTourLocation } from 'api/location'
import { deletePhoto, editPhoto } from 'api/photos'
import { editTour as _editTour } from 'api/tour'
import { schemas } from 'api/types'
import { changeDatesOfWeek } from 'api/weekDays'
import clsx from 'clsx'
import { AddVideo } from 'components/modal/create/AddVideo'
import { AddPhone } from 'components/modal/create/Phone'
import { EditAddress } from 'components/modal/edit/Address'
import { EditPhotos } from 'components/modal/edit/EditPhotos'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ProfileInput } from 'components/profile/ProfileInput'
import { ProfileTextarea } from 'components/profile/ProfileTextarea'
import Checkbox from 'components/ui/Checkbox'
import { Container } from 'components/ui/Container'
import { ContentBody } from 'components/ui/Content/ContentBody'
import { ContentHeader } from 'components/ui/Content/ContentHeader'
import { MultiSelect } from 'components/ui/MultiSelect'
import { Trash } from 'iconsax-react'
import { AdditionalServicesContent } from 'modules/pages/additionalService/AdditionalServicesContent'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Map, Placemark, Polygon, YMaps } from 'react-yandex-maps'
import { weeks } from 'static/consts'
import { ListItem, ProfileFields } from 'static/interfaces'
import { useUserStore } from 'store/user'
import { getAdress, getImageURL } from 'utils/utils'

interface Props {
  phones: schemas['TourPhones'][]
  location: schemas['TourLocation']
  info: schemas['TourRead']
  photos: schemas['TourShots'][]
  profiles: schemas['TourMedicalProfile'][]
  entryDates: schemas['TourBookingWeekDays']
}

export const OrganizationProfile = ({
  info,
  location,
  phones,
  photos,
  profiles,
  entryDates,
}: Props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<ProfileFields>()
  const queryClient = useQueryClient()
  const {user} = useUserStore()
  const {mutate: _delete, isLoading: isDeleting} = useMutation(deletePhoto)
  const {mutate: edit} = useMutation(editPhoto)
  const {mutate: editTour} = useMutation(_editTour)
  const {mutate: deletePhone} = useMutation(phoneNumberDelete)
  const {mutate: editLocation} = useMutation(editTourLocation)
  const {mutate: editEntryDates} = useMutation(changeDatesOfWeek)

  const [chosenProfiles, setChosenProfiles] = useState<number[]>(
    info.medical_profiles.map(profile => profile.id),
  )
  const [isEditPhotos, setIsEditPhotos] = useState(false)
  const [isEditAddress, setIsEditAddress] = useState(false)
  const [isAddVideo, setIsAddVideo] = useState(false)
  const [isAddPhone, setIsAddPhone] = useState(false)
  const [draggedPhoto, setDraggedPhoto] = useState<schemas['TourShots']>()
  const [selectedEntryDates, setSelectedEntryDates] = useState<ListItem[]>(
    entryDates?.days.map(item => ({
      id: item,
      value: weeks[item],
    })),
  )
  const [coords, setCoords] = useState<number[]>(
    location.lat && location.lon
      ? [location.lat, location.lon]
      : [51.18, 71.45],
  )

  useEffect(() => {
    setValue('name', info.title)
    setValue('firstName', info.first_name ?? '')
    setValue('lastName', info.last_name ?? '')
    setValue('description', info.description ?? '')
    setValue('workingTime', info.working_time ?? '')
    setValue('email', info.email ?? '')
    setValue('bin', info.BIN ?? '')
    setValue('bik', info.BIK ?? '')
    setValue('iik', info.IIK ?? '')
  }, [info])

  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    photo: schemas['TourShots'],
  ) => {
    setDraggedPhoto(photo)
  }
  const onDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    event.currentTarget.style.transform = 'translateX(0px)'
  }
  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.currentTarget.style.transform = 'translateX(0px)'
  }
  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.currentTarget.style.transform = 'translateX(10px)'
  }

  const onDrop = (
    event: React.DragEvent<HTMLDivElement>,
    droppedOnPhoto: schemas['TourShots'],
  ) => {
    event.preventDefault()
    queryClient.cancelQueries(['org', 'photos', user?.partner?.tourId])
    queryClient.setQueryData(
      ['org-photos', user?.partner?.tourId],
      photos.map(photo => {
        if (photo.id === droppedOnPhoto?.id) {
          edit({id: droppedOnPhoto?.id, order: draggedPhoto?.order})
          return {...photo, order: draggedPhoto?.order}
        }

        if (photo.id === draggedPhoto?.id) {
          edit({id: draggedPhoto?.id, order: droppedOnPhoto?.order})
          return {...photo, order: droppedOnPhoto.order}
        }

        return photo
      }),
    )
    event.currentTarget.style.transform = 'translateX(0px)'
  }

  const eventAppointHandler = () => {
    editEntryDates(
      {
        tour: user?.partner?.tourId,
        days: selectedEntryDates.map(item => item.id) as schemas['DaysEnum'][],
      },
      {
        onSuccess: () => {
          toast.success('Сохранено')
        },
        onError: () => {
          toast.error('Произошла ошибка')
        },
      },
    )
  }

  const saveOrganizationData = (data: ProfileFields) => {
    if (!user || !user?.partner?.tourId) return
    if (photos.length < 4) return toast.error('Загрузите не менее 4-х фото')
    editTour(
      {
        id: user.partner.tourId,
        title: data.name,
        description: data.description,
        first_name: data.firstName,
        last_name: data.lastName,
        working_time: data.workingTime,
        BIK: data.bik,
        IIK: data.iik,
        BIN: data.bin,
        email: data.email,
        medical_profiles: chosenProfiles,
      },
      {
        onSuccess: () => {
          toast.success('Сохранено')
        },
        onError: () => {
          toast.error('Произошла ошибка, попробуйте позднее')
        },
        onSettled: () => {
          eventAppointHandler()
          editLocation(
            {
              id: location?.id as number,
              lon: coords[1],
              lat: coords[0],
              tour: user?.partner?.tourId as number,
            },
            {
              onSuccess: () => {
                queryClient.refetchQueries(['location'])
                toast.success('Сохранено')
              },
            },
          )
        },
      },
    )
  }

  const getAllTourAdditionalInfoTitlesQuery = useQuery(
    ['additional-services-titles', user?.partner?.id],
    () => getAllTourAdditionalInfoTitles(user?.partner?.tourId),
  )

  const getTourTitlesQuery = useQuery<schemas['AdditonalInfoTitle'][]>(
    ['additional-titles', user?.partner?.tourId],
    () => getTourTitles(user?.partner?.tourId),
  )

  const deleteTitleAdditionalServiceMutation = useMutation(
    deleteTitleAdditionalService,
  )

  const createTitleAdditionalServiceMutation = useMutation(
    createTitleAdditionalService,
  )

  const addForTourAdditionalInfoTitleMutation = useMutation(
    addForTourAdditionalInfoTitle,
  )

  const handleAddTitleAdditionalService = useCallback(
    (titleId: number, service: string) => {
      const selectedTitle = getTourTitlesQuery.data?.find(
        title => title.title === titleId,
      )

      if (!selectedTitle) {
        addForTourAdditionalInfoTitleMutation.mutate(
          {title: titleId, tour: user?.partner?.tourId},
          {
            onSuccess: (data: schemas['AdditonalInfoTitle']) => {
              createTitleAdditionalServiceMutation.mutate(
                {
                  service: service,
                  title: data.id,
                },
                {
                  onSuccess: () => {
                    getTourTitlesQuery.refetch()
                  },
                },
              )
            },
          },
        )
      }

      if (selectedTitle) {
        createTitleAdditionalServiceMutation.mutate(
          {
            service: service,
            title: selectedTitle.id,
          },
          {
            onSuccess: () => {
              getTourTitlesQuery.refetch()
            },
          },
        )
      }
    },
    [
      getTourTitlesQuery.data,
      addForTourAdditionalInfoTitleMutation,
      createTitleAdditionalServiceMutation,
    ],
  )

  const handleDeleteTitleAdditionalService = (id: number) => {
    deleteTitleAdditionalServiceMutation.mutate(id, {
      onSuccess: () => {
        getTourTitlesQuery.refetch()
      },
    })
  }

  const removePhoto = (id: number | string | undefined) => {
    _delete(id, {
      onSuccess: () => {
        toast.success('Успешно удалено')
        queryClient.refetchQueries([
          'org-photos',
          user?.partner?.tourId,
        ])
      },
      onError: () => {
        toast.error(
          'Произошла ошибка при удалении, попробуйте позднее',
        )
      },
    })
  }

  

  return (
    <>
      <EditPhotos
        isVisible={isEditPhotos}
        onClose={() => {
          setIsEditPhotos(false)
        }}
      />
      <AddVideo
        isVisible={isAddVideo}
        onClose={() => {
          setIsAddVideo(false)
        }}
        tourId={user?.partner?.tourId}
      />
      <EditAddress
        isVisible={isEditAddress}
        onClose={() => setIsEditAddress(false)}
      />
      <AddPhone isVisible={isAddPhone} onClose={() => setIsAddPhone(false)}/>
      <Container className="block w-3/4 max-w-6xl my-10 text-dark">
        <form onSubmit={handleSubmit(saveOrganizationData)}>
          <ContentHeader className="flex w-full items-center justify-between py-5">
            <p className="font-medium text-xl text-dark">Организация</p>
          </ContentHeader>
          <ContentBody className="flex flex-wrap flex-row gap-3 mb-10">
            <div className="flex flex-row gap-4 flex-wrap w-full">
              <ProfileInput
                className="basis-[calc(50%-16px)]"
                placeholder="Имя"
                required={true}
                {...register('firstName', {
                  required: 'Заполните поле',
                  minLength: 3,
                })}
                error={errors.firstName?.message}
              />
              <ProfileInput
                className="basis-[calc(50%-16px)]"
                placeholder="Фамилия"
                required={true}
                {...register('lastName', {
                  required: 'Заполните поле',
                  minLength: 2,
                })}
                error={errors.lastName?.message}
              />
              <ProfileInput
                className="basis-[calc(50%-16px)]"
                placeholder="Название организации"
                {...register('name', {
                  required: 'Заполните поле',
                  minLength: 2,
                })}
                error={errors.name?.message}
              />
            </div>
          </ContentBody>
          <ContentHeader className="flex w-full items-center justify-between py-5">
            <p className="font-medium text-xl text-dark">Фотографии и видео</p>
            <div className="flex flex-row gap-5">
              <ProfileButton
                type="button"
                onClick={() => {
                  setIsAddVideo(true)
                }}
              >
                {info.youtube_url ? 'Изменить видео' : 'Добавить видео'}
              </ProfileButton>
              <ProfileButton
                type="button"
                onClick={() => {
                  setIsEditPhotos(true)
                }}
              >
                Добавить фото
              </ProfileButton>
            </div>
          </ContentHeader>
          <ContentBody className="flex flex-wrap flex-row gap-3 mb-10">
            {photos
              .sort((a, b) => a.order - b.order)
              .map(photo => (
                <div
                  onDragStart={event => {
                    onDragStart(event, photo)
                  }}
                  onDragEnd={event => {
                    onDragEnd(event)
                  }}
                  onDragLeave={event => {
                    onDragLeave(event)
                  }}
                  onDragOver={event => {
                    onDragOver(event)
                  }}
                  onDrop={event => {
                    onDrop(event, photo)
                  }}
                  draggable
                  key={photo.id}
                  className="relative image-wrapper group transition-all duration-200 ease-in-out cursor-grab"
                >
									<span
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    }}
                    className="transition-all duration-200 ease-in-out group-hover:visible invisible flex group-hover:opacity-100 opacity-0 backdrop-blur-md rounded-xl absolute z-10 w-full items-center justify-center h-full"
                  >
										{isDeleting ? (
                      <svg
                        className={clsx(
                          'h-0 w-0 transition-all ease-out duration-300',
                          isDeleting ? 'h-max w-[25px]' : '',
                        )}
                        version="1.1"
                        id="L9"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 100 100"
                        enableBackground="new 0 0 0 0"
                        xmlSpace="preserve"
                      >
                        <path
                          fill="#FFFFFF"
                          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                        >
                          <animateTransform
                            attributeName="transform"
                            attributeType="XML"
                            type="rotate"
                            dur="1s"
                            from="0 50 50"
                            to="360 50 50"
                            repeatCount="indefinite"
                          />
                        </path>
                      </svg>
                    ) : (
                      <Trash
                        onClick={() => removePhoto(photo.id)}
                        className="bg-[#FF3030] w-10 p-[6px] h-10 flex items-center justify-center rounded-xl cursor-pointer"
                        size="20"
                        color="#FFFFFF"
                      />
                    )}
									</span>
                  <span className="relative block rounded-xl">
										<img
                      className="rounded-xl w-[200px] h-[150px]"
                      src={getImageURL(photo.photo)}
                      alt="Загруженная фотография"
                    />
									</span>
                </div>
              ))}
          </ContentBody>
          <ContentHeader className="flex w-full items-center justify-between py-5">
            <p className="font-medium text-xl text-dark">Описание</p>
          </ContentHeader>
          <ContentBody className="flex flex-wrap flex-row gap-3 mb-10">
            <ProfileTextarea
              rows={5}
              className="w-full resize-none"
              placeholder="Лечебно-оздоровительный комплекс или просто санаторий Ок-Жетпес расположен на берегу озера Боровое, немного в стороне от шумного курортного посёлка Бурабай. Здесь в тишине и покое его гости могут не только прекрасно отдохнуть, но и поправить своё здоровье."
              {...register('description', {
                required: 'Заполните поле',
              })}
              error={errors.description?.message}
            />
            <span>Напишите 500 символов</span>
          </ContentBody>
          {info.category_slug !== 'zona-otdykha' && (
            <>
              <ContentHeader className="flex w-full items-center justify-between py-5">
                <p className="font-medium text-xl text-dark">
                  Медицинский профиль
                </p>
              </ContentHeader>
              <ContentBody className="flex flex-wrap flex-row gap-y-3 mb-10">
                {profiles?.map(comfort => (
                  <div
                    key={comfort.id}
                    className="flex gap-x-2 items-center cursor-pointer basis-1/2 noselect"
                    onClick={() =>
                      setChosenProfiles(prevComforts => {
                        if (
                          prevComforts.find(_comfort => _comfort === comfort.id)
                        ) {
                          return prevComforts.filter(
                            _comfort => comfort.id !== _comfort,
                          )
                        } else {
                          return [...prevComforts, comfort.id]
                        }
                      })
                    }
                  >
                    <Checkbox
                      checked={
                        !!chosenProfiles.find(
                          _comfort => comfort.id === _comfort,
                        )
                      }
                      onChange={() =>
                        setChosenProfiles(prevComforts => [
                          ...prevComforts,
                          comfort.id,
                        ])
                      }
                    />
                    {comfort.name}
                  </div>
                ))}
              </ContentBody>
            </>
          )}
          <ContentHeader className="flex w-full items-center justify-between py-5">
            <p className="font-medium text-xl text-dark">Удобства и услуги</p>
          </ContentHeader>
          <ContentBody className="flex flex-wrap flex-row gap-3 mb-10">
            <AdditionalServicesContent
              titles={getTourTitlesQuery.data}
              additionalInfo={getAllTourAdditionalInfoTitlesQuery.data}
              handleAdd={handleAddTitleAdditionalService}
              handleDelete={handleDeleteTitleAdditionalService}
            />
          </ContentBody>
          <ContentHeader className="flex w-full items-center justify-between py-5">
            <p className="font-medium text-xl text-dark">
              Контактная информация
            </p>
          </ContentHeader>
          <ContentBody className="flex flex-wrap flex-row gap-3 mb-10">
            <div className="flex justify-between w-full ">
              <div>
                <p className="font-medium">Адрес:</p>
                <p className="ml-4 mt-2">
                  {getAdress([
                    info?.country?.name,
                    info?.region?.name,
                    info?.district,
                    info?.street,
                    info.home_number,
                  ])}
                </p>
              </div>
              <button
                type="button"
                onClick={() => setIsEditAddress(true)}
                className="font-medium text-sm text-[#2A5885]"
              >
                Изменить
              </button>
            </div>
            <div className="flex justify-between w-full items-center">
              <div>
                <p className="font-medium">Номера телефонов:</p>
                <div className="ml-4 mt-2 flex flex-wrap ">
                  {phones?.length != 0 ? (
                    phones.map(phone => (
                      <div
                        className="mb-2 basis-5/12 flex-row flex mr-2"
                        key={phone.id}
                      >
                        {phone.phone}
                        <button
                          type="button"
                          onClick={() =>
                            deletePhone(phone.id, {
                              onSuccess: () => {
                                toast.success('Успешно удалено')
                                queryClient.refetchQueries([
                                  'phones',
                                  user?.partner?.tourId,
                                ])
                              },
                            })
                          }
                          className="text-[#FF6666] font-medium ml-4 text-sm"
                        >
                          Удалить
                        </button>
                      </div>
                    ))
                  ) : (
                    <p>У вас нет номеров телефона</p>
                  )}
                </div>
              </div>
              <button
                type="button"
                onClick={() => setIsAddPhone(true)}
                className="text-[#2A5885] ml-10 font-medium text-sm"
              >
                Добавить
              </button>
            </div>
            <div className="flex flex-row gap-4 justify-around w-full">
              <ProfileInput
                className="basis-[calc(50%-16px)]"
                label="График работы (Пишите через запятую)"
                placeholder="Пн - Вт: 9:00 - 18:00, Сб - Вс: Выходной"
                {...register('workingTime', {
                  required: 'Заполните поле',
                  minLength: 3,
                })}
                error={errors.workingTime?.message}
              />
              <ProfileInput
                className="basis-[calc(50%-16px)]"
                label="Email"
                {...register('email', {
                  required: 'Заполните поле',
                  minLength: 3,
                })}
                error={errors.email?.message}
              />
            </div>
          </ContentBody>
          <ContentHeader className="flex w-full items-center justify-between py-5">
            <p className="font-medium text-xl text-dark">Прочая информация</p>
          </ContentHeader>
          <ContentBody className="flex flex-wrap flex-row gap-3 mb-10">
            <div className="flex flex-row gap-4 flex-wrap">
              <ProfileInput
                className="basis-[calc(50%-16px)]"
                label="БИН"
                {...register('bin', {
                  required: 'Заполните поле',
                })}
                error={errors.bin?.message}
              />
              <ProfileInput
                className="basis-[calc(50%-16px)]"
                label="БИК"
                {...register('bik', {
                  required: 'Заполните поле',
                })}
                error={errors.bik?.message}
              />
              <ProfileInput
                className="basis-[calc(50%-16px)]"
                label="ИИК"
                {...register('iik', {
                  required: 'Заполните поле',
                })}
                error={errors.iik?.message}
              />
              <MultiSelect
                className="basis-[calc(50%-16px)]"
                chosenItems={selectedEntryDates}
                label="Выберите дни заезда"
                onChange={item => {
                  setSelectedEntryDates(item)
                }}
                list={weeks.map((element, index) => ({
                  id: index,
                  value: weeks[index],
                }))}
              />
            </div>
          </ContentBody>
          <ContentHeader className="flex w-full items-center justify-between py-5">
            <p className="font-medium text-xl text-dark">
              Карта{' '}
              <span className="text-gray text-sm inline-block ml-2">
								(Укажите на карте организацию)
							</span>
            </p>
          </ContentHeader>
          <ContentBody className="mb-10">
            <YMaps query={{apikey: 'f80bf96b-8fa3-4478-ac9a-39e597b9757a'}}>
              <Map
                modules={['geocode']}
                defaultState={{
                  center: coords,
                  zoom: 15,
                }}
                width={'100%'}
                height={'250px'}
                onClick={(event: any) => {
                  setCoords(event.get('coords'))
                }}
              >
                <Polygon options={{visible: false}}/>
                {coords && <Placemark geometry={coords}/>}
              </Map>
            </YMaps>
          </ContentBody>
          <div className="flex ml-auto w-max">
            <ProfileButton type="submit">Сохранить</ProfileButton>
          </div>
        </form>
      </Container>
    </>
  )
}

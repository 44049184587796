import { $authHost, $host } from '.'
import { schemas } from './types'

export const deleteNumber = async (id: string | undefined | number) => {
	const { data } = await $authHost.delete(`/v1/numbers/${id}/`)
	return data
}

export const createNumber = async (
	request: schemas['WriteTourNumbersRequest'],
): Promise<schemas['WriteTourNumbers']> => {
	const { data } = await $authHost.post('/v1/numbers/', request)
	return data
}

export const changeNumber = async (
	request: schemas['PatchedWriteTourNumbersRequest'] & { id: number | string },
): Promise<schemas['WriteTourNumbers']> => {
	const { data } = await $authHost.patch(`/v1/numbers/${request.id}/`, request)
	return data
}

export const getNumber = async (
	id: string | number | undefined,
): Promise<schemas['ListNumbers']> => {
	const { data } = await $host.get(`/v1/numbers/${id}/`)
	return data
}


import React from 'react'
import { HeaderButton } from './HeaderButton'
import { useNavigate } from '@tanstack/react-location'
import { schemas } from '@/api/types'

interface GuideProps {
  guide: schemas['GuideList'][]
  setIsConfirmVisible: (on: boolean) => void
}

export const Guide: React.FC<GuideProps> = ({guide, setIsConfirmVisible}: GuideProps) => {

  const navigate = useNavigate()
  
  return (
    <>
      {!guide[0] ? (
          <HeaderButton
            title='Добавление агентства'
            textColor='green'
            onClick={() => navigate({to: '/add-guide'})}
            titleButton='Создать'
            className='mt-8'
          />
        )
        :
        <HeaderButton
          title='Удаление гида'
          textColor='red'
          onClick={() => setIsConfirmVisible(true)}
          titleButton='Удалить'
        />
      }
    </>
  )
}

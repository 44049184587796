import React from 'react'
import { ProfileListBox } from '../../profile/ProfileListBox'
import { HeaderButton } from './HeaderButton'
import { User } from 'static/interfaces'
import { schemas } from 'api/types'
import { useNavigate } from '@tanstack/react-location'

interface ResortProps {
  user: User,
  data: schemas['TourRead'][]
  setIsConfirmVisible: (on: boolean) => void
  editUser: (user: User) => void
}

export const Resort: React.FC<ResortProps> = ({ user, data, setIsConfirmVisible, editUser }: ResortProps) => {

  const chosenTour = data.find(tour => tour.id === user?.partner?.tourId)

  const list = data
    .filter(tour => !tour.is_deleted)
    .map(tour => ({
      id: tour.id,
      value: tour.title,
    }))

  const navigate = useNavigate()
  
  return (
    <>
      <div className='flex justify-between w-full'>
        <div className='flex items-center font-medium'>
          Сменить организацию
        </div>
        <div className={'w-1/3'}>
          <ProfileListBox
            {...(chosenTour && {
              chosenItem: {
                id: chosenTour.id,
                value: chosenTour.title,
              },
            })}
            list={list}
            onChange={item => {
              if (!user?.id) return
              editUser({
                ...user,
                partner: {
                  ...user.partner,
                  tourId: item && item.id,
                },
              })
            }}
            width='w-full'
          />
        </div>
      </div>
      <HeaderButton
        title='Удаление тура'
        textColor={'red'}
        onClick={() => setIsConfirmVisible(true)}
        titleButton='Удалить'
        className='mt-8'
      />
      <HeaderButton
        title='Создание тура'
        textColor='green'
        onClick={() => navigate({to: '/add-organization'})}
        titleButton='Создать'
        className='mt-8'
      />
    </>
  )
}

import { $authHost, $host } from '.'
import { schemas } from './types'

export const createService = async (
	request: schemas['TourPaidServicesRequest'],
) => {
	const { data } = await $authHost.post('/v1/paid-services/', request)
	return data
}

export const getAllCartServices = async (
	servicesList: number[],
	tour_id?: string | number,
): Promise<schemas['TourPaidServices'][]> => {
	if (!servicesList.length) return []
	const { data } = await $host.get(
		`/v1/paid-services/?id__in=${servicesList.join()}&tour_id=${tour_id}`,
	)
	return data
}

export const deleteService = async (id: string | undefined | number) => {
	const { data } = await $authHost.delete(`/v1/paid-services/${id}/`)
	return data
}

export const patchService = async (
	request: schemas['PatchedTourPaidServicesRequest'] & { id: number },
): Promise<schemas['WriteTourNumbers']> => {
	const { data } = await $authHost.patch(
		`/v1/paid-services/${request.id}/`,
		request,
	)
	return data
}

export const getAllMyServices = async (
	id: string | number | undefined,
): Promise<schemas['TourPaidServices'][]> => {
	const { data } = await $authHost.get(`/v1/paid-services/?tour_id=${id}`)
	return data
}

export const getService = async (
	id: string | number | undefined,
): Promise<schemas['TourPaidServices']> => {
	const { data } = await $authHost.get(`/v1/paid-services/${id}/`)
	return data
}

import clsx from 'clsx'
import { ComponentPropsWithoutRef, ReactNode } from 'react'

interface Props extends ComponentPropsWithoutRef<'div'> {
	children: ReactNode
}

export const ContentBody = ({ children, className }: Props) => {
	return (
		<div
			className={clsx(
				'bg-[#FFF] border-solid border-2 border-[#E4E7EC] border-t-0 rounded-b-xl py-5 px-8 shadow-lg',
				className,
			)}
		>
			{children}
		</div>
	)
}

import { useNavigate } from '@tanstack/react-location'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getAllMyNumbers } from 'api/ProfileService'
import { createPackage } from 'api/package'
import clsx from 'clsx'
import { AddService } from 'components/partner/numbers/AddService'
import { ServiceBadge } from 'components/partner/numbers/ServiceBadge'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ProfileInput } from 'components/profile/ProfileInput'
import { ProfileListBox } from 'components/profile/ProfileListBox'
import { ProfileTextarea } from 'components/profile/ProfileTextarea'
import { ContentBody } from 'components/ui/Content/ContentBody'
import { ContentHeader } from 'components/ui/Content/ContentHeader'
import { Spinner } from 'components/ui/Spinner'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { ListItem, NumberFields } from 'static'
import { useUserStore } from 'store/user'
import { removeSpaces } from 'utils/utils'

export const AddPackage = () => {
	const { mutate, isLoading: addPackage } = useMutation(createPackage)
	const { user } = useUserStore()
	const { data: numbers, isLoading } = useQuery(['numbers'], () =>
		getAllMyNumbers(user?.partner?.tourId),
	)
	const queryClient = useQueryClient()
	const [number, setNumber] = useState<ListItem>()
	const [services, setServices] = useState<string[]>([])

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<NumberFields>()

	const navigate = useNavigate()

	const listboxFormat = numbers?.map(item => ({
		id: item.id,
		value: item.title,
	}))
	const onSubmit = (data: NumberFields) => {
		if (numbers?.length === 0) {
			toast.error('Сначала создайте номер')
			return
		}
		if (!number) {
			toast.error('Выберите номер пожалуйста')
			return
		}
		mutate(
			{
				tour: user?.partner?.tourId as number,
				title: data.title,
				price: data.price,
				number: number?.id as number,
				package_services: services,
				remarks: data.remarks,
			},
			{
				onSuccess: () => {
					toast.success('Успешно добавлено')
					queryClient.refetchQueries([
						'organization-packages',
						user?.partner?.tourId,
					])
					navigate({ to: '/resort/packages' })
				},
				onError: () => {
					toast.error('Произошла ошибка')
				},
			},
		)
	}

	if (isLoading) return <Spinner />

	return (
		<div className='mx-auto w-5/12 my-10'>
			<ContentHeader className='flex w-full items-center justify-between py-5'>
				<p className='text-2xl font-semibold text-dark text-center'>
					Добавить пакет
				</p>
			</ContentHeader>
			<ContentBody>
				<form className='flex flex-col' onSubmit={handleSubmit(onSubmit)}>
					<ProfileInput
						label='Введите название пакета'
						placeholder='Стандарт'
						type='text'
						className='block w-full mx-auto mt-7 mb-5'
						error={errors.title?.message}
						{...register('title', {
							required: 'Заполните поле',
							validate: {
								noSpaces: value =>
									removeSpaces(value) ||
									'Поле не должно начинаться или заканчиваться пробелами',
							},
						})}
					/>
					<ProfileInput
						{...register('price', {
							required: 'Заполните поле',
							min: { value: 1, message: 'Минимальная цена 1 тг' },
							max: {
								value: 1000000,
								message: 'Максимальная цена 1000000 тг',
							},
							validate: {
								noSpaces: value =>
									removeSpaces(value.toString()) ||
									'Поле не должно начинаться или заканчиваться пробелами',
								onlyNumbers: value =>
									/^\d+$/.test(value.toString()) ||
									'Поле должно содержать только числа',
							},
						})}
						label='Укажите цену пакета'
						placeholder='5000 тг'
						type='number'
						error={errors.price?.message}
						className='block w-full mx-auto mb-5'
					/>
					<ProfileListBox
						label='Тип номера'
						list={listboxFormat as ListItem[]}
						width={'w-full'}
						onChange={option => {
							setNumber(option)
						}}
						defaultChosen={true}
						className='mb-5'
					/>
					<ProfileTextarea
						{...register('remarks')}
						label='Примечания'
						placeholder='1 место с подселением (1 кровать)'
						className='mb-5'
					/>
					<AddService
						title='Доп. услуги'
						onAdd={(title, service) => {
							setServices(prevServices => [...prevServices, service])
						}}
					/>
					<ServiceBadge
						badges={services}
						onDelete={chosenIndex => {
							setServices(prevServices =>
								prevServices.filter((service, index) => index !== chosenIndex),
							)
						}}
					/>
					<ProfileButton
						type='submit'
						className={clsx(
							'mt-7 block mx-auto w-full',
							addPackage && 'bg-gray',
						)}
						disabled={addPackage}
					>
						Добавить
					</ProfileButton>
				</form>
			</ContentBody>
		</div>
	)
}

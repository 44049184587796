import {useQuery} from '@tanstack/react-query'
import {getMyPhotos} from 'api/photos'
import {getTour} from 'api/tour'
import {Helmet} from 'react-helmet'
import {useUserStore} from 'store/user'
import {ChangePlacePhotos} from '../../modules/pages/changePhoto/changePlacePhoto'

export const ChangePhoto = () => {

  const {user} = useUserStore()
  const {data, isLoading, isError} = useQuery(
    ['org-photos', user?.partner?.tourId],
    () => getMyPhotos(user?.partner?.tourId),
  )
  const {data: tourData} = useQuery(['tour', user?.partner?.tourId], () =>
    getTour(user?.partner?.tourId),
  )

  if (isLoading) return <>Loading...</>
  if (isError) return <>Error...</>

  return (
    <>
      <Helmet>
        <title>Фотографии Тура</title>
        <meta name='robots' content='noindex'/>
      </Helmet>
      <ChangePlacePhotos photos={data} video={tourData?.youtube_url}/>
    </>
  )
}

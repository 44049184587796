import { schemas } from 'api/types'
import { Dispatch, SetStateAction } from 'react'
import { generateUUID, refreshEndDate, refreshStartDate } from 'utils/utils'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

export interface PaymentContext {
	isReg: boolean
	setIsReg: Dispatch<SetStateAction<boolean>>
	isDetail: boolean
	setIsDetail: Dispatch<SetStateAction<boolean>>
	guests: Guest[]
	setGuests: Dispatch<SetStateAction<Guest[]>>
	number?: schemas['RetrieveNumbers']
	setNumber: Dispatch<SetStateAction<schemas['RetrieveNumbers'] | undefined>>
	isPart: boolean
	setIsPart: Dispatch<SetStateAction<boolean>>
	startDate: Date
	endDate: Date
	changeDates: (startDate: Date, endDate: Date) => void
	isFill: boolean
	setIsFill: Dispatch<SetStateAction<boolean>>
}

interface Document {
	number: string
	type: 0 | 1 | 2
}

export interface Guest {
	id: string
	firstName: string
	lastName: string
	dateOfBirth: Date
	citizen: string
	document: Document
	gender: 0 | 1
	age: 'adult' | 'child' | 'baby'
}

interface Payment {
	isReg: boolean
	reg: (flag?: boolean) => void
	isDetail: boolean
	detail: (flag?: boolean) => void
	guests: Guest[]
	setGuests: (guests: Guest[]) => void
	number?: schemas['ListNumbers']
	_package?: schemas['ListPackage']
	setPackage: (
		_package: schemas['ListPackage'] | undefined,
	) => void
	setNumber: (number: schemas['ListNumbers'] | undefined) => void
	isPart: boolean
	part: (flag?: boolean) => void
	startDate: Date
	setStartDate: (date: Date) => void
	setEndDate: (date: Date) => void
	endDate: Date
	changeDates: (startDate: Date, endDate: Date) => void
	refreshDates: () => void
	isFill: boolean
	fill: (flag?: boolean) => void
	addGuest: (guest: Guest) => void
	removeAllGuests: () => void
	removeGuest: (id: string) => void
	removeAdult: () => void
	removeChild: () => void
	removeBaby: () => void
	editGuest: (guest: Guest) => void
}

export const usePaymentStore = create<Payment>()(
	devtools(
		persist(
			set => ({
				isReg: false,
				isFill: false,
				isPart: false,
				isDetail: false,
				guests: [
					{
						id: generateUUID(),
						age: 'adult',
						gender: 0,
						document: {
							type: 0,
							number: '',
						},
						citizen: '',
						firstName: '',
						lastName: '',
						dateOfBirth: new Date(),
					},
				],
				startDate: new Date(),
				setStartDate: date =>
					set(state => ({
						startDate: date,
					})),
				setEndDate: date =>
					set(state => ({
						endDate: date,
					})),
				endDate: new Date(new Date().getTime() + 1000 * 3600 * 24),
				refreshDates: () =>
					set(state => ({
						startDate: refreshStartDate(state.startDate),
						endDate: refreshEndDate(
							refreshStartDate(state.startDate),
							state.endDate,
						),
					})),
				changeDates: (startDate, endDate) =>
					set(state => ({
						startDate,
						endDate,
					})),
				setGuests: guests =>
					set(state => ({
						guests,
					})),
				addGuest: guest => set(state => ({ guests: [...state.guests, guest] })),
				removeGuest: id =>
					set(state => ({
						guests: state.guests.filter(guest => guest.id !== id),
					})),
				removeAdult: () =>
					set(state => ({
						guests: state.guests.slice(0, -1),
					})),
				removeChild: () =>
					set(state => ({
						guests: state.guests.slice(0, -1),
					})),
				removeBaby: () =>
					set(state => ({
						guests: state.guests.slice(0, -1),
					})),
				removeAllGuests: () =>
					set(() => ({
						guests: [],
					})),
				editGuest: guest =>
					set(state => ({
						guests: state.guests.map(_guest => {
							if (guest.id === _guest.id) {
								return guest
							}
							return _guest
						}),
					})),
				reg: flag =>
					set(state => ({
						isReg: flag === undefined ? !state.isReg : flag,
					})),
				detail: flag =>
					set(state => ({
						isDetail: flag === undefined ? !state.isDetail : flag,
					})),
				part: flag =>
					set(state => ({
						isPart: flag === undefined ? !state.isPart : flag,
					})),
				fill: flag =>
					set(state => ({
						isFill: flag === undefined ? !state.isFill : flag,
					})),
				_package: undefined,
				number: undefined,
				setNumber: number =>
					set(state => ({
						number,
					})),
				setPackage: _package =>
					set(state => ({
						_package,
					})),
			}),
			{
				name: 'payment_1.0.0',
			},
		),
	),
)

import { useQuery } from '@tanstack/react-query'
import { getLocation } from 'api/location'
import { getPhones } from 'api/phones'
import { getMyPhotos } from 'api/photos'
import { getAllMedicalProfiles, getTour } from 'api/tour'
import { getDatesOfWeek } from 'api/weekDays'
import { Spinner } from 'components/ui/Spinner'
import { OrganizationProfile } from 'modules/pages/organization/OrganizationProfile'
import { Helmet } from 'react-helmet'
import { useUserStore } from 'store/user'

export const OrganizationPage = () => {
	const { user } = useUserStore()

	const { data, isLoading, isError, isSuccess } = useQuery(
		['tour', user?.partner?.tourId],
		() => getTour(user?.partner?.tourId),
	)

	const {
		data: phones,
		isLoading: isPhonesLoading,
		isError: isPhonesError,
	} = useQuery(['phones', user?.partner?.tourId], () =>
		getPhones(user?.partner?.tourId),
	)

	const {
		data: location,
		isLoading: isLocationLoading,
		isError: isLocationError,
	} = useQuery(['location', user?.partner?.tourId], () =>
		getLocation(user?.partner?.tourId),
	)

	const {
		data: photos,
		isLoading: isPhotosLoading,
		isError: isPhotosError,
	} = useQuery(['org-photos', user?.partner?.tourId], () =>
		getMyPhotos(user?.partner?.tourId),
	)

	const {
		data: profiles,
		isLoading: isProfilesLoading,
		isError: isProfilesError,
	} = useQuery(['medical-profiles'], getAllMedicalProfiles)

	const {
		data: entryDates,
		isLoading: isEntryDatesLoading,
		isError: isEntryDatesError,
	} = useQuery(['check-in-dates', user?.partner?.tourId], () =>
		getDatesOfWeek(user?.partner?.tourId as number),
	)

	if (
		isLoading ||
		isPhonesLoading ||
		isLocationLoading ||
		isPhotosLoading ||
		isProfilesLoading ||
		isEntryDatesLoading
	)
		return <Spinner />
	if (
		isError ||
		isPhonesError ||
		isLocationError ||
		isPhotosError ||
		isProfilesError ||
		isEntryDatesError
	)
		return <>Error</>

	return (
		<>
			<Helmet>
				<title>Организация | MTour</title>
			</Helmet>
			<OrganizationProfile
				entryDates={entryDates}
				profiles={profiles}
				photos={photos}
				info={data}
				location={location}
				phones={phones}
			/>
		</>
	)
}

import { $authHost, $host } from '.'
import { schemas } from './types'

export const createTourLocation = async (
	request: schemas['TourLocationRequest'],
): Promise<schemas['TourLocation']> => {
	const { data } = await $authHost.post('/v1/location/', request)
	return data
}

export const editTourLocation = async (
	request: schemas['TourLocationRequest'] & { id: number },
): Promise<schemas['TourLocation']> => {
	const { data } = await $authHost.patch(`/v1/location/${request.id}/`, request)
	return data
}

export const getLocation = async (
	id?: number | string,
): Promise<schemas['TourLocation']> => {
	const { data } = await $host.get(`/v1/location/tour/?id=${id}`)
	return data
}

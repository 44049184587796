import { useQuery } from '@tanstack/react-query'
import { getPartner } from 'api/partner'
import { getUser } from 'api/user'
import { Helmet } from 'react-helmet'
import { useUserStore } from 'store/user'
import { PartnerProfile } from '../../modules/pages/partner/PartnerProfile'

const PartnerPage = () => {
	const { user } = useUserStore()
	const {
		data: organization,
		isLoading: isLoadingOrg,
		isError: isErrorOrg,
	} = useQuery(['organization', 'profile', user?.partner?.id], () =>
		getPartner(user?.partner?.id),
	)


	const {
		data: _user,
		isLoading: isLoadingUser,
		isError: isErrorUser,
	} = useQuery(['user', user?.id], () => getUser(user?.id))

	if (isLoadingOrg || isLoadingUser) return <>Loading...</>
	if (isErrorUser || isErrorOrg) return <>Error...</>

	return (
		<>
			<Helmet>
				<title>Страница организации | MTour</title>
				<meta name='robots' content='noindex' />
			</Helmet>
			<PartnerProfile partner={organization?.data} user={_user} />
		</>
	)
}

export default PartnerPage

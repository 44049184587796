import { useMutation } from '@tanstack/react-query'
import { editTour } from 'api/tour'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ProfileInput } from 'components/profile/ProfileInput'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { Modal } from '../Modal'

interface Props {
	isVisible: boolean
	onClose: () => void
	tourId: number | undefined
}

interface Field {
	link: string
}

export const AddVideo = ({ isVisible, onClose, tourId }: Props) => {
	const editTourMutation = useMutation(editTour)

	const { register, handleSubmit, reset } = useForm<Field>()

	const onSubmit = (data: Field) => {
		if (!tourId) {
			return toast.error('Что-то пошло не так, попробуйте позднее')
		}

		editTourMutation.mutate(
			{
				id: tourId,
				youtube_url: data.link,
			},

			{
				onSuccess: () => {
					console.log('success')
					onClose()
				},
				onError: () => {
					console.log('error')
				},
			},
		)
		reset()
	}

	return (
		<>
			<Modal
				className='py-8 px-8 bg-white max-w-lg text-dark'
				isVisible={isVisible}
				onClose={onClose}
			>
				<div>
					<h5 className='font-medium mb-2'>
						Для того чтобы видео корректно отображалось, следуйте следующей
						инструкции
					</h5>
					<ol className='list-decimal list-inside font-medium'>
						<li>Выберите ваше видео на Youtube</li>
						<li>Нажмите кнопку &quot;Поделиться&quot;</li>
						<li>Скопируйте появившуюся ссылку и вставьте в поле ниже</li>
					</ol>
				</div>
				<form onSubmit={handleSubmit(onSubmit)} className='mt-4'>
					<ProfileInput
						{...register('link')}
						placeholder='Ссылка на видео'
						label='Вставьте ссылку на Youtube видео'
					/>
					<div className='flex flex-row justify-end'>
						<ProfileButton type='submit' onClick={onClose} className='mt-4'>
							Сохранить
						</ProfileButton>
					</div>
				</form>
			</Modal>
		</>
	)
}

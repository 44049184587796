import { ServiceFields } from '@/static/interfaces'
import { useLocation, useNavigate } from '@tanstack/react-location'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getService, patchService } from 'api/paidServices'
import clsx from 'clsx'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ProfileInput } from 'components/profile/ProfileInput'
import { ProfileTextarea } from 'components/profile/ProfileTextarea'
import { ContentBody } from 'components/ui/Content/ContentBody'
import { ContentHeader } from 'components/ui/Content/ContentHeader'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useUserStore } from 'store/user'
import {useIdsStore} from 'store/idsInsideResort'

export const EditService = () => {
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<ServiceFields>()
	const { current } = useLocation()
	const id = current.search.id as string
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const { mutate: changeServiceMutation, isLoading: editing } =
		useMutation(patchService)
	const { user } = useUserStore()
	const { serviceId } = useIdsStore()
	const {
		data: service,
		isLoading,
		isError,
	} = useQuery(['service', serviceId], () => getService(serviceId))

	const onSubmit = (data: ServiceFields) => {
		if (!service?.id || !user?.partner?.tourId) {
			toast.error('Произошла ошибка, попробуйте позднее')
			return
		}
		changeServiceMutation(
			{
				tour: user?.partner.tourId,
				name: data.title,
				price: Number(data.price),
				description: data.description,
				id: service.id,
			},
			{
				onSuccess: () => {
					toast.success('Успешно')
					queryClient.refetchQueries([
						'organization-services',
						user?.partner?.tourId,
					])
					reset()
				},
				onError: () => {
					toast.error('Произошла ошибка, попробуйте позднее')
					reset()
				},
				onSettled: () => {
					navigate({ to: '/resort/service' })
				},
			},
		)
	}

	useEffect(() => {
		setValue('title', service?.name ?? '')
		setValue('price', `${service?.price}` ?? '')
		setValue('description', service?.description ?? '')
	}, [service])

	if (isLoading) return <>Loading</>
	if (isError) return <>Error</>

	return (
		<div className=' mx-auto w-5/12 my-10'>
			<ContentHeader className='flex w-full items-center justify-between py-5'>
				<p className='font-semibold text-2xl text-dark'>
					Услуги
					<span className='text-gray text-sm font-normal inline-block ml-2'>
						Для отмены перейдите к предыдущей вкладке
					</span>
				</p>
			</ContentHeader>
			<ContentBody>
				<form onSubmit={handleSubmit(onSubmit)}>
					<ProfileInput
						label='Введите заголовок услуги'
						placeholder='Душ Шарко'
						type='text'
						className='block mx-auto mt-7 mb-5'
						error={errors.title?.message}
						{...register('title', {
							required: 'Заполните поле',
						})}
					/>
					<ProfileInput
						{...register('price', {
							required: 'Заполните поле',
							min: { value: 1, message: 'Минимальная цена 1 тг' },
							max: {
								value: 1000000,
								message: 'Максимальная цена 1000000 тг',
							},
						})}
						error={errors.price?.message}
						label='Укажите цену услуги'
						placeholder='5000 тг.'
						type='number'
						className='block mx-auto mb-5'
					/>
					<ProfileTextarea
						error={errors.description?.message}
						{...register('description', {
							required: 'Заполните поле',
						})}
						label='Введите описание услуги'
						placeholder='Душ Шарко – это метод гидротерапии, успешно применяемый в косметологических, лечебных и реабилитационных целях. '
						className='block mx-auto mb-5'
					/>
					<ProfileButton
						type='submit'
						className={clsx('mt-7 block mx-auto w-full', editing && 'bg-gray')}
						disabled={editing}
					>
						Сохранить
					</ProfileButton>
				</form>
			</ContentBody>
		</div>
	)
}

import { $authHost } from '.'
import { schemas } from './types'

export const addPhone = async (
	requestData: schemas['PhoneNumberRequest'],
): Promise<schemas['PhoneNumber']> => {
	const { data } = await $authHost.post('/v1/phone/', requestData)
	return data
}

export const updatePhone = async (
	requestData: schemas['PhoneNumberRequest'] & { phoneId: number },
): Promise<schemas['PhoneNumber']> => {
	const { data } = await $authHost.put(
		`/v1/phone/${requestData.phoneId}/`,
		requestData,
	)
	return data
}

export const BASE_URL = 'https://mtour.kz'
export const weeks = [
	'Воскресенье',
	'Понедельник',
	'Вторник',
	'Среда',
	'Четверг',
	'Пятница',
	'Суббота',
]

import { Popover, Transition } from '@headlessui/react'
import { useNavigate } from '@tanstack/react-location'
import { useMutation } from '@tanstack/react-query'
import { logoutUser } from 'api/auth'
import { ArrowDown2 } from 'iconsax-react'
import { Fragment } from 'react'
import toast from 'react-hot-toast'
import { useUserStore } from 'store/user'

export interface List {
	id: number
	value: string
	href: string
}

interface Props {
	list: List[]
}

export const CustomHeaderPopover = ({ list }: Props) => {
	const navigate = useNavigate()
	const { user, removeUser } = useUserStore()
	const { mutate } = useMutation(logoutUser)

	const logoutUserEvent = () => {
		navigate({ to: '/authorization', replace: true })
		mutate()
		localStorage.removeItem('accessToken')
		removeUser()
		toast.success('Вы успешно вышли из аккаунта')
	}

	const organizationName = user?.partner?.orgName

	return (
		<div className='inset-0'>
			<Popover className='relative mx-auto'>
				<Popover.Button className='focus:outline-none flex items-end'>
					<div className='mr-4'>
						<div className={'font-semibold'}>{organizationName}</div>
						<span>Юридическое лицо</span>
					</div>
					<div className='flex items-center'>
						<img
							src={`https://ui-avatars.com/api/?name=
                            ${organizationName}
                            &color=21BCDE&background=fff&rounded=true&bold=true&font-size=0.6&size=128`}
							className='w-[50px] mr-4 rounded-full shadow-md p-1.5'
							alt={'user'}
						/>
						<ArrowDown2 />
					</div>
				</Popover.Button>
				<Transition
					as={Fragment}
					enter='transition ease-out duration-200'
					enterFrom='opacity-0 translate-y-1'
					enterTo='opacity-100 translate-y-0'
					leave='transition ease-in duration-150'
					leaveFrom='opacity-100 translate-y-0'
					leaveTo='opacity-0 translate-y-1'
				>
					<Popover.Panel className='w-48 text-[#1A4F6E] absolute left-[60%] z-10 mt-5 -translate-x-1/2 transform sm:px-0 lg:max-w-3xl'>
						{list.map((item: List, index: number) => (
							<Popover.Button
								key={item.id}
								onClick={() => navigate({ to: item.href, replace: true })}
								className={`p-2 shadow-lg font-medium bg-white hover:bg-[#F0FCFF] 
                                cursor-pointer w-full text-left
                                ${index === 0 ? 'rounded-t-2xl' : ''}`}
							>
								<div className='ml-2'>{item.value}</div>
							</Popover.Button>
						))}
						<Popover.Button
							className='w-full text-left p-2 bg-[#fff] font-medium rounded-b-2xl shadow-lg rounded-t-none
                            hover:bg-[#F0FCFF] border-t-2 border-[#F2F4F7]'
							type='button'
							onClick={logoutUserEvent}
						>
							<div className='ml-2'>Выйти</div>
						</Popover.Button>
					</Popover.Panel>
				</Transition>
			</Popover>
		</div>
	)
}

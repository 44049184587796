import { $authHost, $host } from '.'
import { schemas } from './types'

export const getUser = async (
	id: string | number | undefined,
): Promise<schemas['User']> => {
	const { data } = await $authHost.get(`/v1/users/${id}/`)
	return data
}

export const restorePassword = async (
	request: schemas['PasswordResetChangeRequest'],
): Promise<schemas['PasswordResetChange']> => {
	const { data } = await $authHost.put(
		'/v1/users/passwordReset/change/',
		request,
	)
	return data
}

export const registerClient = async (
	request: schemas['RegisterUserRequest'],
) => {
	const { data } = await $host.post('/v1/users/register/client/', request)
	return data
}

export const registerPartner = async (
	request: schemas['RegisterOrgRequest'],
): Promise<schemas['ResponseRegister']> => {
	const { data } = await $host.post('/v1/users/register/partner/', request)
	return data
}

export const changePassword = async (
	requestData: schemas['PasswordChange'],
) => {
	const { data } = await $authHost.put(
		'/v1/users/password/change/',
		requestData,
	)
	return data
}

export const partlyRegister = async (
	request: schemas['PartUserCreateRequest'],
): Promise<schemas['PartUserCreate']> => {
	const { data } = await $host.post('/v1/users/register/part/', request)
	return data
}

export const getMe = async (): Promise<schemas['UserRead']> => {
	const { data } = await $authHost.get('/v1/users/me/')
	return data
}

import { useQuery } from '@tanstack/react-query'
import { getAllMyNumbers } from 'api/ProfileService'
import { getBookings } from 'api/crm'
import { getNumberRoomsByNumberId } from 'api/numberCabinets'
import { schemas } from 'api/types'
import clsx from 'clsx'
import { AddBooking } from 'components/modal/create/Booking'
import { AddBookingByTable } from 'components/modal/create/BookingByTable'
import { ReservationDetail } from 'components/modal/display/ReservationDetail'
import { ProfileButton } from 'components/profile/ProfileButton'
import { PartnerDatePicker } from 'components/ui/Date/PartnerDatePicker'
import { useFirstRender } from 'hooks/useFirstRender'
import { ArrowLeft2 } from 'iconsax-react'
import { BookingTable } from 'modules/pages/booking/BookingTable'
import { Month } from 'modules/pages/booking/Month'
import { useEffect, useState } from 'react'
import { months } from 'static/staticLists'
import { useUserStore } from 'store/user'
import { FreeMode, Thumbs } from 'swiper'
import 'swiper/css'
import 'swiper/css/free-mode'
// import 'swiper/css/navigation'
import { CloseNumber } from 'components/modal/create/CloseNumber'
import { Helmet } from 'react-helmet'
import 'swiper/css/thumbs'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getDateRange, switchMonth } from 'utils/bookingSystemUtils'
import '../../styles/date-picker.css'
import { Submit } from 'components/modal/other/SubmitBooking'

export const BookingPage = () => {
	const firstRender = useFirstRender()
	const { user } = useUserStore()

	const [numberRoom, setNumberRoom] = useState<schemas['NumberCabinets']>()
	const [chosenDate, setChosenDate] = useState(new Date())
	const [startDate, setStartDate] = useState(new Date())
	const [reservatorInfo, setReservatorInfo] = useState<schemas['Reservations']>()

	const [isReservationItemDetailsModal, setIsReservationItemDetailsModal] = useState(false)
	const [submitClientModal, setSubmitClientModal] = useState(false)
	const [isAddBookingVisible, setIsAddBookingVisible] = useState(false)
	const [isAddBookingByTableVisible, setIsAddBookingByTableVisible] = useState(false)
	const [isClose, setIsClose] = useState(false)

	const [numberId, setNumberId] = useState<number>()
	const date = getDateRange(chosenDate)

	const {
		data: bookings,
		isError: isBookingsError,
		isLoading: isBookingsLoading,
		refetch: bookingsRefetch,
	} = useQuery(['bookings', user?.partner?.tourId], () => {
		return getBookings(date.firstDate, date.lastDate, user?.partner?.tourId)
	})

	const myNumbersQuery = useQuery(
		['organization-numbers', user?.partner?.tourId],
		() => {
			return getAllMyNumbers(user?.partner?.tourId)
		},
	)

	const numberCabinets = useQuery(
		['number-cabinets', user?.partner?.tourId],
		() => {
			if (!myNumbersQuery.isSuccess) return
			return getNumberRoomsByNumberId(numberId ?? myNumbersQuery.data[0].id)
		},
		{
			enabled: myNumbersQuery.isSuccess,
		},
	)

	useEffect(() => {
		myNumbersQuery.data ? setNumberId(myNumbersQuery.data[0]?.id) : null
	}, [myNumbersQuery.data])

	useEffect(() => {
		if (firstRender) return
		bookingsRefetch()
		numberCabinets.refetch()
	}, [chosenDate, numberId])

	if (
		numberCabinets.isLoading ||
		isBookingsLoading ||
		myNumbersQuery.isLoading
	) {
		return <>Загрузка...</>
	}
	if (numberCabinets.isError || isBookingsError || myNumbersQuery.isError) {
		return <>Произошла ошибка, проверьте пожалуйста, есть ли у вас номера</>
	}

	console.log(reservatorInfo?.approved_status)

	return (
		<>
			<Helmet>
				<title>График бронирования</title>
			</Helmet>
			<AddBooking
				numberId={numberId}
				isVisible={isAddBookingVisible}
				onClose={() => {
					setIsAddBookingVisible(false)
				}}
			/>
			<AddBookingByTable
				parentStartDate={startDate}
				numberId={numberId}
				numberRoom={numberRoom}
				isVisible={isAddBookingByTableVisible}
				onClose={() => {
					setIsAddBookingByTableVisible(false)
				}}
			/>
			<CloseNumber
				isVisible={isClose}
				onClose={() => {
					setIsClose(false)
				}}
				numberId={numberId}
			/>
			<ReservationDetail
				info={reservatorInfo}
				isVisible={isReservationItemDetailsModal}
				onClose={() => {
					setIsReservationItemDetailsModal(false)
				}}
				openSubmitModal={() => {
					setIsReservationItemDetailsModal(false)
					setSubmitClientModal(true)
				}}
			/>
			<Submit
				isVisible={submitClientModal}
				onClose={() => setSubmitClientModal(false)}
				id={reservatorInfo?.id.toString()}
			/>
			<div className='w-full pt-16 bg-bg-light'>
				<div className='block mx-auto p-5 bg-white'>
					<div className='flex justify-between items-center mb-7'>
						<span></span>
						<span className='font-bold text-2xl text-dark-blue'>
							График бронирования
						</span>
						<div className='flex gap-x-3'>
							<ProfileButton
								onClick={() => {
									setIsAddBookingVisible(true)
								}}
							>
								Добавить бронь
							</ProfileButton>
							<ProfileButton
								className='bg-red'
								onClick={() => {
									setIsClose(true)
								}}
							>
								Закрыть номер
							</ProfileButton>
						</div>
					</div>
					<div>
						<Swiper
							modules={[FreeMode, Thumbs]}
							className='mb-5 noselect'
							width={150}
						>
							{myNumbersQuery.data.map(number => (
								<SwiperSlide key={number.id}>
									<button
										className={clsx(
											'text-dark-blue text-lg font-semibold outline-none',
											numberId === number.id &&
												'underline-offset-4	decoration-dark-blue underline decoration-[3px]',
										)}
										onClick={() => {
											setNumberId(number.id)
										}}
									>
										{number.title}
									</button>
								</SwiperSlide>
							))}
						</Swiper>
					</div>
					<div>
						<div className='mb-8'>
							<table className='overflow-hidden table-fixed'>
								<Month
									number={myNumbersQuery.data.find(
										number => number.id === numberId,
									)}
									date={chosenDate}
								/>
								<BookingTable
									onDoubleClick={(numberRoom, date) => {
										setIsAddBookingByTableVisible(true)
										setNumberRoom(numberRoom)
										setStartDate(date)
									}}
									showInfoModal={info => {
										setReservatorInfo(info)
										setIsReservationItemDetailsModal(true)
									}}
									bookings={bookings}
									date={chosenDate}
									numberRooms={numberCabinets.data}
								/>
							</table>
						</div>
					</div>
					<div className='flex justify-between '>
						<div className='flex'>
							<div className='flex border-[#21BCDE] rounded-xl border-2 items-center'>
								<PartnerDatePicker
									onChange={date => {
										setChosenDate(date)
									}}
									label={''}
								/>
								<p className='text-dark-blue font-semibold px-6 py-2'>
									{`${months[new Date(date.lastDate).getMonth()]}, ${new Date(
										date.firstDate,
									).getFullYear()}`}
								</p>
							</div>
							<div className='flex border-2 border-[#21BCDE] rounded-xl ml-8'>
								<button
									onClick={() => {
										setChosenDate(prevDate => switchMonth(prevDate, -1))
									}}
									className='px-5 py-4 border-r-2 border-[#21BCDE]  flex items-center'
								>
									<ArrowLeft2 size='20' color='#114869	' />
								</button>
								<button
									onClick={() => {
										setChosenDate(prevDate => switchMonth(prevDate, 1))
									}}
									className='px-5 py-4 rotate-180 flex items-center'
								>
									<ArrowLeft2 size='20' color='#114869	' />
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

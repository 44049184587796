import { useQuery } from '@tanstack/react-query'
import { getNumber } from 'api/number'
import { Spinner } from 'components/ui/Spinner'
import { ru } from 'date-fns/locale'
import imageNotFound from 'helpers/images/NoImage.svg'
import tick from 'helpers/images/tick_number.svg'
import { useState } from 'react'
import { registerLocale } from 'react-datepicker'
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getImageURL } from 'utils/utils'
import { Modal } from '../Modal'

registerLocale('ru', ru)

interface Props {
	isVisible: boolean
	onClose: () => void
	_number?: number
}

export const NumberDetails = ({ isVisible, onClose, _number }: Props) => {
	const {
		data: number,
		isLoading,
		isError,
	} = useQuery(['place', 'number', _number], () => getNumber(_number), {
		enabled: !!_number,
	})

	const [thumbsSwiper, setThumbsSwiper] = useState<any>(null)

	return (
		<Modal
			className='px-8 py-8 bg-white max-w-7xl xs:px-4 xs:py-4 xs:pb-20'
			isVisible={isVisible}
			onClose={() => onClose()}
		>
			{isError || isLoading ? (
				<Spinner />
			) : (
				<>
					<div className='flex gap-x-10 md:flex-col md:gap-y-2'>
						<div className='w-5/12 md:basis-5/12 md:w-full md:overflow-hidden'>
							<Swiper
								mousewheel={{ forceToAxis: false, invert: true }}
								spaceBetween={10}
								className=' mb-5 rounded-xl swiper'
								slidesPerView={1}
								pagination={{
									dynamicBullets: true,
								}}
								navigation={true}
								thumbs={{
									swiper:
										thumbsSwiper && !thumbsSwiper.destroyed
											? thumbsSwiper
											: null,
								}}
								modules={[Pagination, Navigation, Thumbs]}
							>
								{number.numbershots_set.length && number.numbershots_set ? (
									number.numbershots_set.map(slide => (
										<SwiperSlide key={slide.id}>
											<img
												className='rounded-md md:h-96  xs:h-[180px] w-full h-[273px] select-none'
												alt=''
												src={getImageURL(slide.thumbnail)}
											/>
										</SwiperSlide>
									))
								) : (
									<SwiperSlide>
										<img
											className='rounded-md md:h-96  xs:h-[180px] w-full h-[273px] select-none'
											alt=''
											src={imageNotFound}
										/>
									</SwiperSlide>
								)}
							</Swiper>
							<Swiper
								watchSlidesProgress={true}
								onSwiper={setThumbsSwiper}
								spaceBetween={10}
								className='swiper_thumb mb-5 swiper2 w-full'
								slidesPerView={3}
								modules={[FreeMode, Navigation, Thumbs]}
							>
								{number.numbershots_set.length && number.numbershots_set
									? number.numbershots_set.map(slide => (
											<SwiperSlide key={slide.id}>
												<img
													className='rounded-md md:h-96 xs:h-[90px] w-full h-[80px] select-none'
													alt='Изображение номера'
													src={getImageURL(slide.thumbnail)}
												/>
											</SwiperSlide>
									  ))
									: ''}
							</Swiper>
						</div>
						<div>
							<p className='font-semibold text-2xl mb-5'>{number?.title}</p>
							<p className='font-semibold mb-3'>Удобства:</p>
							<div className='flex flex-wrap items-center'>
								{number?.comforts.map(benefit => (
									<p
										className='flex basis-1/2 gap-x-2 text-[#6B7280] mb-2.5 font-medium'
										key={benefit.id}
									>
										<img
											src={`${getImageURL(benefit.icon)}`}
											alt='icon'
											className='fill-dark'
										/>
										{benefit.name}
									</p>
								))}
							</div>
							{number.number_services.length > 0 && (
								<div>
									<p className='font-semibold mb-3 mt-3'>
										Услуги входящие в пакет:
									</p>
									<div className='flex flex-wrap items-center'>
										{number?.number_services.map((benefit, index) => (
											<p
												className='flex basis-1/2 gap-x-2 text-[#6B7280] mb-2.5 font-medium'
												key={index}
											>
												<img
													src={tick}
													alt='icon'
													className='fill-dark -translate-y-0.5'
												/>
												{benefit.title}
											</p>
										))}
									</div>
								</div>
							)}
							{number?.remarks && (
								<div>
									<p className='font-semibold mb-3 mt-3'>Примечание:</p>
									<p className='flex basis-1/2 gap-x-2 text-[#6B7280] mb-2.5 font-medium'>
										<img
											src={tick}
											alt='icon'
											className='fill-dark -translate-y-0.5'
										/>
										{number?.remarks}
									</p>
								</div>
							)}
						</div>
					</div>
				</>
			)}
		</Modal>
	)
}

import { CustomHeaderPopover } from 'components/ui/CustomPopover'
import { partnerPopoverList } from 'static/staticLists'

export const Header = () => {
	return (
		<div
			className={
				'w-full flex mx-auto h-[80px] bg-white shadow-xl rounded-lg justify-end px-10 items-center'
			}
		>
			<CustomHeaderPopover list={partnerPopoverList} />
		</div>
	)
}

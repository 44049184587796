import {schemas} from '@/api/types'
import {AddAdditionalServiceRow} from './AddAdditionalServiceRow'
import {AdditionalServiceBadgeItems} from './AdditionalServiceBadgeItems'

interface Props {
  titles: schemas['AdditonalInfoTitle'][] | undefined
  additionalInfo: schemas['AdditionalTitles'][] | undefined
  handleAdd: (title: number, service: string) => void
  handleDelete: (id: number) => void
}

export const AddAdditionalServiceRowItems = ({
  titles,
  additionalInfo,
  handleAdd,
  handleDelete,
}: Props) => {


  return (
    <div className='flex flex-col gap-8 w-full'>
      {additionalInfo?.map((info: schemas['AdditionalTitles']) => (
        <div key={info.id}>
          <AddAdditionalServiceRow title={info} onAdd={handleAdd}/>
          <AdditionalServiceBadgeItems
            serviceBadges={
              titles &&
              titles.filter(title => title.title === info.id).at(0)?.additional_services
            }
            onDelete={handleDelete}
          />
        </div>
      ))}
    </div>
  )
}

import { ProfileButton } from '../../profile/ProfileButton'
import { Modal } from '../Modal'

interface Props<T> {
	isVisible: boolean
	onClose: () => void
	requestFunction: (arg: T) => void
	requestArg: T
	onSuccess?: () => void
	onError?: () => void
}

export const Confirm = <T,>({
	isVisible,
	onClose,
	requestFunction,
	requestArg,
	onSuccess,
	onError,
}: Props<T>) => {
	const handleConfirm = async () => {
		try {
			await requestFunction(requestArg)
			if (onSuccess) {
				onSuccess()
			}
		} catch (error) {
			if (onError) {
				onError()
			}
		}
		onClose()
	}

	return (
		<Modal isVisible={isVisible} onClose={onClose} className='max-w-xl p-8'>
			<div>
				<p className='text-dark-blue font-medium text-2xl text-center'>
					Вы точно хотите удалить?
				</p>
				<div className='flex justify-center gap-x-20'>
					<ProfileButton
						type='button'
						onClick={onClose}
						className='mt-6 mr-4 bg-blue'
					>
						Отменить
					</ProfileButton>
					<ProfileButton
						type='button'
						onClick={handleConfirm}
						className='mt-6 mr-4 bg-red'
					>
						Удалить
					</ProfileButton>
				</div>
			</div>
		</Modal>
	)
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addPhoneNumber } from 'api/ProfileService'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ProfileInput } from 'components/profile/ProfileInput'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { PhoneNumberField } from 'static/interfaces'
import { useUserStore } from 'store/user'
import { Modal } from '../Modal'

interface Props {
	isVisible: boolean
	onClose: () => void
}

export const AddPhone = ({ isVisible, onClose }: Props) => {
	const queryClient = useQueryClient()
	const { handleSubmit } = useForm<PhoneNumberField>()
	const { user } = useUserStore()
	const changePhoneNumberMutation = useMutation(addPhoneNumber)
	const [phone, setPhone] = useState('')

	const onSubmit = () => {
		if (!user?.partner?.tourId) {
			toast.error('Что-то пошло не так, попробуйте позднее')
			return
		}
		if (!phone.trim()) {
			toast.error('Введите пожалуйста номер телефона')
			return
		}
		changePhoneNumberMutation.mutate(
			{
				tour: user.partner.tourId,
			},
			{
				onSuccess: () => {
					toast.success('Номер телефона успешно добавлен')
					queryClient.refetchQueries(['phones', user?.partner?.tourId])
					onClose()
				},
				onError: () => {
					toast.error('Не удалось добавить номер телефона')
				},
			},
		)
	}

	return (
		<Modal
			className='px-8 py-10 bg-white max-w-lg'
			isVisible={isVisible}
			onClose={onClose}
		>
			<p className='text-xl  font-semibold text-dark text-center mb-5'>
				Добавить номер телефона
			</p>
			<form onSubmit={handleSubmit(onSubmit)}>
				<ProfileInput
					label='Введите номер телефона'
					placeholder='+77082684861'
					type='text'
					className='block mx-auto'
					onChange={e => setPhone(e.target.value)}
					pattern='+[0-9]{12}'
				/>
				<ProfileButton className='mt-7 block ml-auto' type='submit'>
					Подтвердить
				</ProfileButton>
			</form>
		</Modal>
	)
}

import { $authHost } from '.'
import { schemas } from './types'

export const createNumberType = async (
	request: schemas['TourNumberTypeRequest'],
) => {
	const { data } = await $authHost.post('/v1/number-type/', request)
	return data
}

export const getAllNumberTypes = async (
	tourId: string | number | undefined,
): Promise<schemas['TourNumberType'][]> => {
	const { data } = await $authHost.get(`/v1/number-type/?tour_id=${tourId}`)
	return data
}

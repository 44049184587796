import React from 'react'
import { Helmet } from 'react-helmet'
import { RegisterPartner } from '../../modules/pages/registration/RegisterAsPartner'

export const RegistrationPage = () => {
	return (
		<div className='flex justify-center items-center min-h-screen'>
			<Helmet>
				<title>Регистрация | MTour</title>
				<meta name='robots' content='noindex' />
			</Helmet>
			<RegisterPartner />
		</div>
	)
}

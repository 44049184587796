import { $authHost, $host } from '.'
import { schemas } from './types'

export const getDatesOfWeek = async (
	id?: number,
): Promise<schemas['TourBookingWeekDays']> => {
	const { data } = await $host.get(`/v1/weekdays/${id}/`)
	return data
}

export const changeDatesOfWeek = async (
	request: schemas['PatchedTourBookingWeekDaysRequest'],
) => {
	const { data } = await $authHost.patch(
		`/v1/weekdays/${request.tour}/`,
		request,
	)
	return data
}

import {
	Cog6ToothIcon,
	HeartIcon,
	HomeIcon,
	ShoppingCartIcon,
	UserIcon,
} from '@heroicons/react/24/solid'
import { Complexity } from './interfaces'

export const aboutProjectList = [
	{
		id: 1,
		name: 'О Проекте',
		href: '/about-us',
		isDoc: false,
	},
	{
		id: 2,
		name: 'Публичная оферта',
		href: 'https://mtour.kz/docs/public_offer_of_legal_entities.html',
		isDoc: true,
	},
	{
		id: 3,
		name: 'Политика конфиденциальности',
		href: 'https://mtour.kz/docs/USER_PERSONAL_DATA_PROTECTION_AGREEMENT.html',
		isDoc: true,
	},
]

export const resortSidebarLinks = [
	{
		title: 'Данные организации',
		id: 1,
		href: '/resort/organization',
	},
	{
		title: 'Услуги',
		id: 3,
		href: '/resort/service',
	},
	{
		title: 'Номера',
		id: 4,
		href: '/resort/numbers',
	},
	{
		title: 'Пакеты',
		id: 5,
		href: '/resort/packages',
	},
	{
		title: 'График бронирования',
		id: 6,
		href: '/resort/booking',
	},
	{
		title: 'Заявки',
		id: 7,
		href: '/resort/applications',
	},
	// {
	// 	title: 'Подписка',
	// 	id: 8,
	// 	href: '/resort/subscriptions',
	// },
	{
		title: 'Настройки',
		id: 9,
		href: '/settings',
	},
]

export const guideSidebarLinks = [
	{
		title: 'Данные агентства',
		id: 1,
		href: '/guide/organization',
	},
	{
		title: 'Программы',
		id: 2,
		href: '/guide/program',
	},
	{
		title: 'Настройки',
		id: 3,
		href: '/settings',
	},
]

export const langList = [
	{
		id: 1,
		icon: '/images/ruLang.svg',
		name: 'RU',
		value: 'RU',
	},
	{
		id: 2,
		icon: '/images/kzLang.svg',
		name: 'KZ',
		value: 'KZ',
	},
	{
		id: 3,
		icon: '/images/engLang.svg',
		name: 'EN',
		value: 'EN',
	},
]
// temporary
export const featureList = [
	'3-х разовое питание',
	'Кондиционер',
	'Холодильник',
	'Кабельное ТВ',
	'Сан. узел',
	'Телефония',
	'Бесплатный WI-FI',
	'Сейф',
]

export const categoryList = [
	{
		id: 1,
		value: 'Санатории',
		name: 'Санатории',
	},
	{
		id: 2,
		value: 'Физиотерапия и реабилитация',
		name: 'Физиотерапия и реабилитация',
	},
	{
		id: 3,
		value: 'Зоны отдыха',
		name: 'Зоны отдыха',
	},
]

export const exchangeList = [
	{
		id: 1,
		icon: '/images/kzLang.svg',
		name: 'KZT (₸)',
		value: 'KZT',
	},
	{
		id: 2,
		icon: '/images/ruLang.svg',
		name: 'RUB (₽)',
		value: 'RUB',
	},
	{
		id: 3,
		icon: '/images/engLang.svg',
		name: 'USA ($)',
		value: 'USD',
	},
]

// export const sideBarItems = [
// 	{
// 		id: 1,
// 		icon: <User />,
// 		label: 'Личные данные',
// 		href: '/user/profile',
// 	},
// 	{
// 		id: 2,
// 		icon: <Document />,
// 		label: 'История покупок',
// 		href: '/user/history',
// 	},
// ]
// temporary

export const slidesList = [
	{ id: 1, path: 'https://swiperjs.com/demos/images/nature-1.jpg' },
	{ id: 2, path: 'https://swiperjs.com/demos/images/nature-1.jpg' },
	{ id: 3, path: 'https://swiperjs.com/demos/images/nature-1.jpg' },
	{ id: 4, path: 'https://swiperjs.com/demos/images/nature-1.jpg' },
	{ id: 5, path: 'https://swiperjs.com/demos/images/nature-1.jpg' },
]

// temporary
export const roomList = [
	{
		id: 1,
		name: '1-местные номера',
		value: '1-местные номера',
	},
	{
		id: 2,
		name: '2-местные номера',
		value: '2-местные номера',
	},
	{
		id: 3,
		name: 'Семейные вечера',
		value: 'Семейные вечера',
	},
]
// temporary
export const requisites = [
	{
		label: 'БИН',
		value: '140340022287',
	},
	{
		label: 'ИИК',
		value: 'KZ106010291000194146 АО «Народный Банк Казахстана»',
	},
	{
		label: 'БИК',
		value: 'HSBKKZKX',
	},
	{
		label: 'Для пред оплаты KaspiGold',
		value: '4400 4301 5963 4663',
	},
	{
		label: 'Директор',
		value: 'Минжасаров Тилек',
	},
]
// temporary
export const profileInfo = [
	{
		label: 'Номера телефонов',
		value: '+7 (7253) 75 13-45',
	},
	{
		label: 'Рабочий телефон',
		value: '+7 (778) 901 81-75',
	},
	{
		label: 'E-mail',
		value: 'aktilek.san@mail.ru',
	},
	{
		label: 'Местонахождение',
		value: 'РК, Туркестанская область, Сарыагашский р-он., ул. Абая д. 76.',
	},
]
// temporary
export const advantagesList = [
	{
		id: 1,
		title: 'Удобства',
		list: [
			{
				id: 1,
				text: 'Регистрация заезда -12:00',
			},
			{
				id: 2,
				text: 'Караоке',
			},
			{
				id: 3,
				text: 'Места для курения',
			},
			{
				id: 4,
				text: 'Общий лаундж/гостиная с телевизором',
			},
			{
				id: 5,
				text: 'Регистрация отъезда -10:00',
			},
			{
				id: 6,
				text: 'Вечерняя программа',
			},
			{
				id: 7,
				text: 'Оборудования для инвалидов',
			},
			{
				id: 8,
				text: 'Библиотека',
			},
			{
				id: 9,
				text: 'Анимационный персонал',
			},
			{
				id: 10,
				text: 'Прокат спортивного инвентаря',
			},
		],
	},
	{
		id: 1,
		title: 'Удобства',
		list: [
			{
				id: 1,
				text: 'Регистрация заезда -12:00',
			},
			{
				id: 2,
				text: 'Караоке',
			},
			{
				id: 3,
				text: 'Места для курения',
			},
			{
				id: 4,
				text: 'Общий лаундж/гостиная с телевизором',
			},
			{
				id: 5,
				text: 'Регистрация отъезда -10:00',
			},
			{
				id: 6,
				text: 'Вечерняя программа',
			},
			{
				id: 7,
				text: 'Оборудования для инвалидов',
			},
			{
				id: 8,
				text: 'Библиотека',
			},
			{
				id: 9,
				text: 'Анимационный персонал',
			},
			{
				id: 10,
				text: 'Прокат спортивного инвентаря',
			},
		],
	},
]

export const partnerPopoverList = [
	{
		id: 1,
		value: 'Профиль',
		href: '/',
	},
	{
		id: 2,
		value: 'Настройки',
		href: '/settings',
	},
]

export const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
export const months = [
	'Январь',
	'Февраль',
	'Март',
	'Апрель',
	'Май',
	'Июнь',
	'Июль',
	'Август',
	'Сентябрь',
	'Октябрь',
	'Ноябрь',
	'Декабрь',
]

export const tariffs = [
	{
		id: 1,
		month: 3,
		price: 150000,
		name: '3-х месячная подписка',
	},
	{
		id: 2,
		month: 6,
		price: 220000,
		name: 'Полугодовая подписка',
	},
	{
		id: 3,
		month: 12,
		price: 270000,
		name: 'Годовая подписка',
	},
]

export const userHistorySettings = [
	{
		id: 1,
		label: 'За все время',
	},
	{
		id: 2,
		label: 'За последнюю неделю',
	},
	{
		id: 3,
		label: 'За последний месяц',
	},
	{
		id: 4,
		label: 'За последний год',
	},
]
export const navList = [
	{
		id: 1,
		name: 'Главная',
		icon: <HomeIcon />,
	},
	{
		id: 2,
		name: 'Корзина',
		icon: <ShoppingCartIcon />,
	},
	{
		id: 3,
		name: 'Избранное',
		icon: <HeartIcon />,
	},
	{
		id: 4,
		name: 'Профиль',
		icon: <UserIcon />,
	},
	{
		id: 5,
		name: 'Настройки',
		icon: <Cog6ToothIcon />,
	},
]

export const criterias = [
	{
		id: 0,
		name: 'Чистота',
	},
	{
		id: 1,
		name: 'Сервис',
	},
	{
		id: 2,
		name: 'Местоположение',
	},
	{
		id: 3,
		name: 'Персонал',
	},
	{
		id: 4,
		name: 'Соотншошение цена/качество',
	},
]

export const customMonths = [
	'Январь',
	'Февраль',
	'Март',
	'Апрель',
	'Май',
	'Июнь',
	'Июль',
	'Август',
	'Сентябрь',
	'Ноябрь',
	'Декабрь',
]

export const navbarTabsItems = [
	{
		id: 1,
		name: 'Общая информация',
	},
	{
		id: 2,
		name: 'Номера',
	},
	{
		id: 3,
		name: 'Платные услуги',
	},
	{
		id: 4,
		name: 'Авиа и ЖД билеты',
	},
	{
		id: 5,
		name: 'Контакты',
	},
	{
		id: 6,
		name: ' Отзывы',
	},
]

export const programComplexity: Complexity[] = [
	{
		id: 1,
		value: 'Легкий',
		name: 'easy',
	},
	{
		id: 2,
		value: 'Средний',
		name: 'medium',
	},
	{
		id: 3,
		value: 'Сложный',
		name: 'hard',
	},
]

import { Settings } from 'modules/pages/settings/Settings'
import { Helmet } from 'react-helmet'
import { useUserStore } from 'store/user'

export const SettingsPage = () => {
	const { user } = useUserStore()

	return (
		<div className={'w-full'}>
			<Helmet>
				<title>Настройки | MTour</title>
			</Helmet>
			<Settings />
		</div>
	)
}

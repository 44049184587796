import { ProfileFields } from '@/static/interfaces'
import { useNavigate } from '@tanstack/react-location'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createTourLocation } from 'api/location'
import { getAllOrgTypes } from 'api/organizationType'
import { changeAvatar, createTour } from 'api/tour'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ProfileInput } from 'components/profile/ProfileInput'
import { ProfileListBox } from 'components/profile/ProfileListBox'
import { ProfileTextarea } from 'components/profile/ProfileTextarea'
import { Container } from 'components/ui/Container'
import { ContentBody } from 'components/ui/Content/ContentBody'
import { ContentHeader } from 'components/ui/Content/ContentHeader'
import { Spinner } from 'components/ui/Spinner'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Map, Placemark, Polygon, YMaps } from 'react-yandex-maps'
import { useUserStore } from 'store/user'
import { removeSpaces } from 'utils/utils'

export const AddTour = () => {
	const {
		data: getAllOrgTypesQuery,
		isLoading,
		isError,
	} = useQuery(['org-types'], getAllOrgTypes)
	const [orgType, setOrgType] = useState(1)
	const createTourMutation = useMutation(createTour)
	const createTourLocationMutation = useMutation(createTourLocation)
	const changeAvatarMutation = useMutation(changeAvatar)
	const [selectedFile, setSelectedFile] = useState<File | null>(null)
	const queryClient = useQueryClient()
	const [coordinates, setCoordinates] = useState<number[]>([51.18, 71.45])
	const { user, editUser } = useUserStore()

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ProfileFields>()
	const navigate = useNavigate()

	
	const addOrganizationData = (data: ProfileFields) => {
		createTourMutation.mutate(
			{
				description: data.description,
				first_name: data.firstName,
				last_name: data.lastName,
				title: data.name,
				email: data.email,
				BIK: data.bik,
				IIK: data.bik,
				BIN: data.bin,
				currency: 1,
				category: orgType,
				org: user?.partner?.id as number,
			},
			{
				onSuccess: tourData => {
					const formData = new FormData()
					if (selectedFile) {
						formData.append('logo', selectedFile as File)
						changeAvatarMutation.mutate({
							tourId: user?.partner?.tourId,
							formData: formData,
						})
					}
					createTourLocationMutation.mutate(
						{
							tour: tourData.id,
							lat: coordinates[0],
							lon: coordinates[1],
						},
						{
							onSuccess: () => {
								if (user) {
									editUser({
										...user,
										partner: {
											...user?.partner,
											tourId: tourData.id,
										},
									})
									toast.success('Данные успешно обновлены')
									queryClient.refetchQueries(['me'])
									navigate({ to: '/', replace: true })
								}
							},
						},
					)
				},
				onError: (error: any) => {
					for (const key in error.response.data) {
						toast.error(error.response.data[key])
					}
				},
			},
		)
	}

	if (isLoading) return <Spinner />
	if (isError) return <></>

	const formedOrgTypes = getAllOrgTypesQuery.map(orgType => {
		return { id: orgType.id, value: orgType.title }
	})

	return (
		<Container className='block w-3/4 max-w-6xl my-10 text-dark'>
			<div className='font-bold text-3xl mb-10'>
				Добавьте перед началом организацию
			</div>
			<form onSubmit={handleSubmit(addOrganizationData)}>
				<ContentHeader className='flex w-full items-center justify-between py-5'>
					<p className='font-medium text-xl text-dark'>Организация</p>
				</ContentHeader>
				<ContentBody className='flex flex-wrap flex-row gap-3 gap-y-3 mb-10'>
					<ProfileInput
						className='basis-[calc(50%-16px)]'
						required={true}
						{...register('firstName', {
							required: 'Заполните поле',
							minLength: 3,
							validate: {
								noSpaces: (value) =>
									removeSpaces(value) ||
									'Поле не должно начинаться или заканчиваться пробелами',
							},
						})}
						placeholder='Имя'
						error={errors.firstName?.message}
					/>
					<ProfileInput
						className='basis-[calc(50%-16px)]'
						required={true}
						{...register('lastName', {
							required: 'Заполните поле',
							minLength: 2,
							validate: {
								noSpaces: (value) =>
									removeSpaces(value) ||
									'Поле не должно начинаться или заканчиваться пробелами',
							},
						})}
						placeholder='Фамилия'
						error={errors.lastName?.message}
					/>
					<ProfileInput
						className='basis-[calc(50%-16px)]'
						{...register('name', {
							required: 'Заполните поле',
							minLength: 2,
							validate: {
								noSpaces: (value) =>
									removeSpaces(value) ||
									'Поле не должно начинаться или заканчиваться пробелами',
							},
						})}
						error={errors.name?.message}
						placeholder='Название организации'
					/>
					<ProfileListBox
						list={formedOrgTypes}
						width='w-full'
						className='basis-[calc(50%-16px)]'
						defaultChosen={true}
						onChange={option => setOrgType(option.id)}
					/>
				</ContentBody>
				<ContentHeader className='flex w-full items-center justify-between py-5'>
					<p className='font-medium text-xl text-dark'>Описание</p>
				</ContentHeader>
				<ContentBody className='flex flex-wrap flex-row gap-3 mb-10'>
					<ProfileTextarea
						rows={5}
						className='w-full resize-none'
						placeholder='Лечебно-оздоровительный комплекс или просто санаторий Ок-Жетпес расположен на берегу озера Боровое, немного в стороне от шумного курортного посёлка Бурабай. Здесь в тишине и покое его гости могут не только прекрасно отдохнуть, но и поправить своё здоровье.'
						{...register('description', {
							required: 'Заполните поле',
							validate: {
								noSpaces: (value) =>
									removeSpaces(value) ||
									'Поле не должно начинаться или заканчиваться пробелами',
							},
						})}
						error={errors.description?.message}
					/>
					<span>Напишите 500 символов</span>
				</ContentBody>
				<ContentHeader className='flex w-full items-center justify-between py-5'>
					<p className='font-medium text-xl text-dark'>Прочая информация</p>
				</ContentHeader>
				<ContentBody className='flex flex-wrap flex-row gap-3 mb-10'>
					<ProfileInput
						className='basis-[calc(50%-16px)]'
						{...register('bin', {
							required: 'Заполните поле',
							validate: {
								noSpaces: (value) =>
									removeSpaces(value) ||
									'Поле не должно начинаться или заканчиваться пробелами',
								minLength: value =>
									value.length >= 12 || 'Минимальная длина 12 символов',
							},
						})}
						error={errors.bin?.message}
						placeholder='Введите свой БИН'
					/>
					<ProfileInput
						className='basis-[calc(50%-16px)]'
						{...register('bik', {
							required: 'Заполните поле',
							validate: {
								noSpaces: (value) =>
									removeSpaces(value) ||
									'Поле не должно начинаться или заканчиваться пробелами',
							},
						})}
						error={errors.bik?.message}
						placeholder='Введите свой БИК'
						pattern='[A-Z0-9]{8}'
					/>
					<ProfileInput
						className='basis-[calc(50%-16px)]'
						error={errors.iik?.message}
						{...register('iik', {
							required: 'Заполните поле',
							pattern: {
								value:
									/^([A-Z]{2}[-]?[0-9]{2})(?=(?:[-]?[A-Z0-9]){9,30}$)((?:[-]?[A-Z0-9]{3,5}){2,7})([-]?[A-Z0-9]{1,3})?$/,
								message: 'Введите ИИК в в верном формате',
							},
						})}
						placeholder='Введите свой ИИК'
					/>
					<ProfileInput
						error={errors.email?.message}
						{...register('email', {
							required: 'Заполните поле',
							minLength: 3,
							validate: {
								noSpaces: (value) =>
									removeSpaces(value) ||
									'Поле не должно начинаться или заканчиваться пробелами',
							},
						})}
						placeholder='Введите свой Email'
						className='basis-[calc(50%-16px)]'
						type='text'
						pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
					/>
				</ContentBody>
				<ContentHeader className='flex w-full items-center justify-between py-5'>
					<p className='font-medium text-xl text-dark'>
						Карта{' '}
						<span className='text-gray text-sm inline-block ml-2'>
							(Укажите на карте организацию)
						</span>
					</p>
				</ContentHeader>
				<ContentBody className='mb-8'>
					<YMaps query={{ apikey: 'f80bf96b-8fa3-4478-ac9a-39e597b9757a' }}>
						{/* <ReactNotification /> */}
						<Map
							// className='my-10'
							modules={['geocode']}
							defaultState={{ center: [51.18, 71.45], zoom: 9 }}
							width={'100%'}
							height={'250px'}
							onClick={(event: any) => {
								setCoordinates(event.get('coords'))
								// geocode() // отображаем адрес
							}}
						>
							<Polygon
								// instanceRef={(ref) => (this.polygonInst = ref)}
								// geometry={moscow.coordinates}
								options={{ visible: false }}
							/>
							{coordinates && (
								<Placemark
									geometry={coordinates}
									// options={{
									//   iconLayout: 'default#image',
									//   iconImageHref: vector,
									//   iconImageSize: [32, 32],
									//   iconImageOffset: [-18, -18],
									// }}
								/>
							)}
						</Map>
					</YMaps>
				</ContentBody>
				<div className='flex ml-auto w-max'>
					<ProfileButton type='submit'>Добавить организацию</ProfileButton>
				</div>
			</form>
		</Container>
	)
}

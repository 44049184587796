import { Outlet, useLocation, useNavigate } from '@tanstack/react-location'
import { useQuery } from '@tanstack/react-query'
import { Header } from 'common/Header'
import { SideBar } from 'common/SideBar'
import { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { getAllMyTours } from './api/tour'
import { Footer } from './common/Footer'
import { useUserStore } from './store/user'

function App() {

	const location = useLocation().current.pathname.split('/')[1]
	const navigate = useNavigate()
	const { user } = useUserStore()
	
	const auth = 'authorization'
	const reg = 'registration'

	const { data, isLoading, isError } = useQuery(
		['my-tours', user?.partner?.id],
		getAllMyTours,
		{
			enabled: !!user?.partner?.id,
		},
	)

	useEffect(() => {
		if (!user) {
			if (location !== auth && location !== reg)
				navigate({ to: '/authorization', replace: true })
		}
	}, [])

	return (
		<div>
			{location !== auth && location !== reg ? (
				<>
					<Header />
					<main className='flex items-stretch'>
						<Toaster />
						<SideBar />
						<Outlet />
					</main>
					<Footer />
				</>
			) : (
				<div>
					<Outlet />
				</div>
			)}
		</div>
	)
}

export default App

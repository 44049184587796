import { AddPhoto } from '../static'
import { $authHost } from '.'
import { schemas } from './types'

export const getMyPhotos = async (
	tourId: string | number | undefined,
): Promise<schemas['NumberShots'][]> => {
	const { data } = await $authHost.get(`/v1/number-shots/?tour_id=${tourId}`)
	return data
}

export const addPhoto = async ({
	formData,
}: AddPhoto): Promise<schemas['NumberShotsCreate']> => {
	$authHost.defaults.headers.common['Content-Type'] = 'multipart/form-data'
	const { data } = await $authHost.post('/v1/number-shots/', formData)
	return data
}

export const deletePhoto = async (id: number | string | undefined) => {
	const { data } = await $authHost.delete(`/v1/number-shots/${id}/`)
	return data
}

export const editPhoto = async ({
	formData,
	id,
}: AddPhoto & { id?: string | number }) => {
	$authHost.defaults.headers.common['Content-Type'] = 'multipart/form-data'
	const { data } = await $authHost.patch(`/v1/number-shots/${id}/`, formData)
	return data
}

import { $authHost } from '.'
import { schemas } from './types'

export const createBooking = async (
	request: schemas['ReservationsRequest'],
) => {
	const { data } = await $authHost.post('/v1/crm/', request)
	return data
}

export const getBookings = async (
	startDate: string,
	endDate: string,
	tourId: number | string | undefined,
): Promise<schemas['Reservations'][]> => {
	const { data } = await $authHost.get(
		`/v1/crm/?tour_id=${tourId}&daterange=${startDate},${endDate}`,
	)
	return data
}

export const sendBookingCode = async (id: number | undefined) => {
	const { data } = await $authHost.post(`/v1/confirmationCode/${id}/`)
	return data
}

export const validateConfirmCode = async (requestData: {bookingId: string | undefined, validateId: string}) => {

	const data = {
		number: requestData.validateId
	}

	const { data: responseData } = await $authHost.post(`/v1/confirmationCode/validate/${requestData.bookingId}/`, data)
	return responseData
}

export const deleteBooking = async (
	id: number | string | undefined,
): Promise<schemas['NumberCabinets'][]> => {
	const { data } = await $authHost.delete(`/v1/crm/${id}/`)
	return data
}

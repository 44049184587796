import React from 'react'
import clsx from 'clsx'
import { Link } from '@tanstack/react-location'

interface SpecialLinkProps {
  href: string
  text: string
  open: boolean
  icon: React.ReactNode
  className?: string
}

export const SpecialLink: React.FC<SpecialLinkProps> = ({
    text,
    open,
    href,
    icon,
    className
  }: SpecialLinkProps) => {

  return (
    <Link
      className={clsx('flex rounded-md cursor-pointer hover:bg-light-white text-gray-300 items-center gap-x-4 mt-2', className)}
      to={href}
    >
      {icon}
      <span
        className={clsx(
          'origin-left mt-0.5 duration-200 truncate',
          !open && 'hidden',
        )}
      >
        {text}
      </span>
    </Link>
  )
}


import { Link } from '@tanstack/react-location'
import { useQueryClient } from '@tanstack/react-query'
import { deletePackage } from 'api/package'
import { schemas } from 'api/types'
import { useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { usePaymentStore } from 'store/payment'
import { useUserStore } from 'store/user'
import SwiperCore, { Autoplay, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getImageURL, priceSpacing } from 'utils/utils'
import { Confirm } from '../../modal/delete/Confirm'
import NoImage from '../../../helpers/images/NoImage.svg'
import {useIdsStore} from 'store/idsInsideResort'

interface Props {
	_package: schemas['ListPackage']
}

export const ProfilePackage = ({ _package }: Props) => {
	const queryClient = useQueryClient()
	const { user } = useUserStore()
	const { setPackageId } = useIdsStore()
	const { setPackage, detail, isDetail } = usePaymentStore()
	const swiperRef = useRef<SwiperCore>()
	const [isConfirmVisible, setIsConfirmVisible] = useState(false)

	const onInit = (Swiper: SwiperCore): void => {
		swiperRef.current = Swiper
		swiperRef.current.autoplay.stop()
	}
	const handleMouseOver = () => {
		if (swiperRef.current) swiperRef.current.autoplay.start()
	}
	const handleMouseLeave = () => {
		if (swiperRef.current) swiperRef.current.autoplay.stop()
	}

	return (
		<>
			<Confirm
				isVisible={isConfirmVisible}
				onClose={() => setIsConfirmVisible(false)}
				requestFunction={deletePackage}
				requestArg={_package.id}
				onSuccess={() => {
					toast.success('Успешно удалено')
					queryClient.refetchQueries([
						'organization-packages',
						user?.partner?.tourId,
					])
					setIsConfirmVisible(false)
				}}
				onError={() => {
					toast.error('Что-то пошло не так, попробуйте позднее')
					setIsConfirmVisible(false)
				}}
			/>
			<div className='w-80 sm:max-w-[360px] sm:w-full rounded-xl bg-white mx-auto shadow-lg sm:mr-2'>
				<div className='w-full md:shrink shrink-0 md:w-full overflow-hidden h-[200px]'>
					<Swiper
						onInit={onInit}
						autoplay={{
							delay: 1200,
							disableOnInteraction: true,
						}}
						mousewheel={{ forceToAxis: false, invert: true }}
						spaceBetween={10}
						className='overflow-hidden'
						centeredSlides={true}
						slidesPerView={1}
						navigation={false}
						pagination={{
							dynamicBullets: true,
						}}
						modules={[Pagination, Autoplay]}
					>
						{_package.number.numbershots_set.length &&
						_package.number.numbershots_set ? (
							_package.number.numbershots_set.map(slide => (
								<SwiperSlide key={slide.id} className='overflow-hidden'>
									<img
										onMouseOver={handleMouseOver}
										onMouseLeave={handleMouseLeave}
										className='rounded-md w-full h-full select-none'
										alt=''
										src={getImageURL(slide.thumbnail)}
										style={{
											objectFit: 'cover',
										}}
									/>
								</SwiperSlide>
							))
						) : (
							<SwiperSlide>
								<img
									className='rounded-md w-full h-[200px] select-none p-[10px] border-2 border-solid border-[rgba(37,36,36,0.128)] border-b-0 rounded-b-none'
									alt=''
									src={NoImage}
								/>
							</SwiperSlide>
						)}
					</Swiper>
				</div>
				<div className='flex flex-col items-start w-full p-5 md:p-5 sm:p-4 border-[#DDD] border-2 border-t-0 rounded-b-xl'>
					<h6 className='font-semibold text-[#111827] text-xl mb-2 mt-2'>
						{_package.title}
					</h6>
					<div className='flex flex-col'>
						{_package.number.comforts.map(benefit => (
							<p
								className='flex basis-1/2 gap-x-2 text-[#6B7280] mb-2.5 font-medium sm:text-sm'
								key={benefit.id}
							>
								<img
									src={getImageURL(benefit.icon)}
									alt='icon'
									className='fill-dark -translate-y-0.5 '
								/>
								{benefit.name}
							</p>
						))}
					</div>
					<button
						onClick={() => {
							setPackage(_package)
							detail(true)
						}}
					>
						<span className='mt-1.5 mb-2 inline-block bg-gradient-to-r from-light-blue to-green text-transparent bg-clip-text font-medium text-base sm:text-sm'>
							Подробнее о пакете
						</span>
					</button>
					<div className='w-full'>
						<span className='flex flex-row gap-1 items-end text-[#111827]'>
							<span className='uppercase font-medium text-xl sm:text-xl'>
								₸ {priceSpacing(_package.price)}
							</span>
							<span className='lowercase font-normal text-base sm:text-sm -translate-y-[2px]'>
								ночь
							</span>
						</span>
						<div className='flex flex-row gap-2.5 mt-4 w-full justify-evenly font-medium'>
							<button
								className='border-[#21BCDE] rounded-xl text-red hover:bg-dark-blue/[.1] p-3 transition-all duration-200 ease-in-out'
								onClick={() => setIsConfirmVisible(true)}
							>
								Удалить
							</button>
							<Link
								onClick={() => setPackageId(_package.id)}
								className='border-[#21BCDE] rounded-xl flex items-center text-dark-blue hover:bg-dark-blue/[.1] p-3 transition-all duration-200 ease-in-out'
								to={`/resort/packages/edit?id=${_package.id}`}
							>
								Редактировать
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

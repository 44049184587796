import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getAllMyNumbers } from 'api/ProfileService'
import { createBooking } from 'api/crm'
import { getAllMyServices } from 'api/paidServices'
import { schemas } from 'api/types'
import clsx from 'clsx'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ProfileInput } from 'components/profile/ProfileInput'
import { ProfileTextarea } from 'components/profile/ProfileTextarea'
import { MultiSelect } from 'components/ui/MultiSelect'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { AddBookingFields, ListItem } from 'static/interfaces'
import { useUserStore } from 'store/user'
import { getNextDay, getPrevDay } from 'utils/utils'
import { Modal } from '../Modal'

interface Props {
	isVisible: boolean
	onClose: () => void
	numberId?: number
	numberRoom?: schemas['NumberCabinets']
	parentStartDate: Date
}

export const AddBookingByTable = ({
	isVisible,
	onClose,
	numberId,
	numberRoom,
	parentStartDate,
}: Props) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		watch,
	} = useForm<AddBookingFields>()
	const queryClient = useQueryClient()
	const { user } = useUserStore()

	const getAllMyServicesQuery = useQuery(['myServicesForBooking'], () =>
		getAllMyServices(user?.partner?.tourId),
	)
	const getAllMyNumbersQuery = useQuery(['myNumbersForBooking'], () =>
		getAllMyNumbers(user?.partner?.tourId),
	)

	const createBookingMutation = useMutation(createBooking)

	const changedServices: ListItem[] | undefined =
		getAllMyServicesQuery.data?.map((item: schemas['TourPaidServices']) => ({
			id: item.id,
			value: `${item.name} - ${item.price} тг.`,
		}))

	const [startDate, setStartDate] = useState(parentStartDate ?? new Date())
	const [endDate, setEndDate] = useState(
		new Date(parentStartDate.getTime() + 24 * 60 * 60 * 1000) ?? new Date(),
	)

	useEffect(() => {
		setStartDate(parentStartDate ?? new Date())
		setEndDate(
			new Date(parentStartDate.getTime() + 24 * 60 * 60 * 1000) ?? new Date(),
		)
	}, [parentStartDate])

	const [selectedServices, setSelectedServices] = useState<ListItem[]>([])

	const onSubmit = (data: AddBookingFields) => {
		createBookingMutation.mutate(
			{
				...(selectedServices.length
					? {
							services: selectedServices.map(service => ({
								service: service.id,
								count: 1,
							})),
					  }
					: null),
				...(numberRoom
					? {
							number_cabinets: numberRoom.id,
					  }
					: null),
				start: startDate.toISOString().split('T')[0],
				end: endDate.toISOString().split('T')[0],
				paid: data.price !== '' ? true : false,
				phoneNumber: data.phone,
				email: data.email,
				fullName: data.name,
				number: numberId as number,
				partner: user?.partner?.id,
				tour: user?.partner?.tourId as number,
				amount: data.price !== '' ? Number(data.price) : 0,
			},
			{
				onSuccess: () => {
					toast.success('Бронь успешно создана')
					queryClient.refetchQueries(['bookings', user?.partner?.tourId])
					onClose()
					reset()
				},
				onError: () => {
					toast.error('Не получилось создать бронь')
				},
			},
		)
	}
	if (!getAllMyNumbersQuery.isSuccess) return <></>

	return (
		<Modal
			className='px-8 py-16 bg-white max-w-xl'
			isVisible={isVisible}
			onClose={onClose}
		>
			<div className=' w-full font-semibold rounded-full bg-white mb-5 '>
				<div className='flex justify-between gap-x-2'>
					<div className='basis-1/2 '>
						<span className='font-medium inline-block mb-1'>Дата начала</span>
						<DatePicker
							selectsStart
							startDate={new Date(startDate)}
							minDate={new Date()}
							endDate={new Date(endDate)}
							tabIndex={-1}
							selected={new Date(startDate)}
							dateFormat='d MMMM, yyyy'
							showPopperArrow={false}
							popperPlacement='bottom'
							locale='ru'
							preventOpenOnFocus={false}
							customInput={
								<input
									type='text'
									className={clsx(
										'text-[#6B7280] outline-none  border rounded-lg px-3 py-2 w-full',
									)}
								/>
							}
							onChange={date => {

								if (date) {
									if (date.getTime() >= new Date(endDate).getTime()) {
										setEndDate(getNextDay(date))
									}
									setStartDate(date)
								}
							}}
						/>
					</div>
					<div className='basis-1/2 '>
						<span className='font-medium inline-block mb-1'>Дата конца</span>
						<DatePicker
							selectsEnd
							selected={new Date(endDate)}
							minDate={getNextDay()}
							startDate={startDate}
							endDate={new Date(endDate)}
							showPopperArrow={false}
							tabIndex={-1}
							autoFocus={false}
							dateFormat='d MMMM, yyyy'
							popperPlacement='bottom'
							locale='ru'
							preventOpenOnFocus={false}
							customInput={
								<input
									type='text'
									className={clsx(
										'text-[#6B7280] outline-none border rounded-lg px-3 py-2 w-full',
									)}
								/>
							}
							onChange={date => {

								if (date) {
									if (date.getTime() <= new Date(startDate).getTime()) {
										setStartDate(getPrevDay(date))
									}
									setEndDate(date)
								}
							}}
						/>
					</div>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={clsx('transition-all duration-300 ease-in-out ')}>
						<ProfileInput
							className='mt-10'
							label='Телефон номера'
							placeholder='+77081527207'
							{...register('phone', {
								pattern: {
									value:
										/^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
									message: 'Неверный формат телефона',
								},
							})}
							error={errors.phone?.message}
						/>
						<ProfileInput
							{...register('email', {
								pattern: {
									value:
										/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: 'Неверный формат email',
								},
							})}
							className='mt-3'
							label='Email'
							placeholder='info@example.com'
							error={errors.email?.message}
						/>
						<ProfileInput
							className='mt-3'
							label='Полное имя'
							placeholder='Арман Кумалаков'
							{...register('name', {
								required: 'Заполните поле',
							})}
							error={errors.name?.message}
						/>
						<ProfileInput
							className='mt-3'
							label='Оплачено'
							placeholder='12000 тг.'
							{...register('price', {
								required: 'Заполните поле',
								min: { value: 1, message: 'Минимальная цена 1 тг' },
								max: {
									value: 1000000,
									message: 'Максимальная цена 1000000 тг',
								},
							})}
							error={errors.price?.message}
						/>
						<ProfileTextarea
							className='mt-3'
							label='Примечание'
							placeholder='Пользователь попросил дополнительную подушку'
							{...register('remark')}
							error={errors.remark?.message}
						/>
						<MultiSelect
							className='mt-3'
							label='Выберите услуги'
							onChange={item => {
								setSelectedServices(item)
							}}
							list={changedServices as ListItem[]}
						/>
					</div>
					<div className='flex justify-between items-center mt-4'>
						<p className='font-semibold'>
							Выбран номер: {numberRoom?.humanize_name}
						</p>
						<ProfileButton>Добавить</ProfileButton>
					</div>
				</form>
			</div>
		</Modal>
	)
}

import { $authHost, $host } from '.'
import { schemas } from './types'

export const loginUser = async (
	request: schemas['TokenObtainLifetimeRequest'],
): Promise<schemas['TokenObtainLifetime']> => {
	const { data } = await $host.post('/api/auth/token/', request)
	return data
}

export const logoutUser = async () => {
	const { data } = await $authHost.post('/api/auth/token/logout/')
	return data
}

export const refreshToken = async () => {
	const { data } = await $authHost.post('/api/auth/token/refresh/')
	return data
}

export const forgotPassword = async (
	request: schemas['ResetPasswordRequest'],
): Promise<schemas['ResetPassword']> => {
	const { data } = await $host.post('/api/auth/resetPassword/', request)
	return data
}

export const getToken = async (
	request: schemas['CodeRequest'],
): Promise<schemas['UserVerify']> => {
	const { data } = await $host.post('/api/auth/verify/', request)
	return data
}

import React from 'react'
import clsx from 'clsx'

interface CourseProps {
  description: string
  icon: string
  bgColor: string
  hint: string
  button: string
  onClick: () => void
}

export const Course: React.FC<CourseProps> = (
  {
    button,
    hint,
    description,
    icon,
    bgColor,
    onClick
  }: CourseProps) => {
  return (
    <div className='max-w-[520px] h-[100px] border rounded-xl border-solid border-[#EDF2FB] flex items-center px-5 justify-between'>
      <div className='flex items-center h-full'>
        <div className={clsx('rounded-xl flex items-center justify-center p-4', bgColor)}>
          <img src={icon} alt='image'/>
        </div>
        <div className='ml-[21px] w-[268px]'>
          <p className='font-semibold text-base'>{description}</p>
          <p className='text-[#ABADC6] text-sm'>
            {hint}
          </p>
        </div>
      </div>
      <div>
        <button
          className='border-[#21BCDE] font-medium bg-light-blue text-white px-4 py-3 rounded-xl text-sm hover:cursor-pointer h-max'
          onClick={onClick}>
          {button}
        </button>
      </div>
    </div>
  )
}

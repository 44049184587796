import { schemas } from '@/api/types'
import clsx from 'clsx'
import useWindowDimensions from 'hooks/useWindowDimensions'
import {
	getAmountOfDaysInMonth,
	getBookingAmountOfDays,
} from 'utils/bookingSystemUtils'

interface Props {
	onClick: (info: schemas['Reservations']) => void
	info: schemas['Reservations']
	date: Date
}

export const ReservationItem = ({ info, onClick, date }: Props) => {
	const { width } = useWindowDimensions()

	const result = getBookingAmountOfDays(
		date,
		info.reservations_start,
		info.reservations_end,
	)

	return (
		<div
			onClick={() => onClick(info)}
			style={{
				width: `${
					result.amountOfDays * ((width - 308) / getAmountOfDaysInMonth(date)) -
					4 +
					(result.startedInPreviousMonth ? 15 : 0)
				}px`,
				maxWidth: `${getAmountOfDaysInMonth(date) * 36}px`,
				top: '1px',
				left: '1px',
			}}
			className={clsx(
				'absolute text-white bg-[#245953] h-11 z-[1] pl-4 flex items-center cursor-pointer overflow-hidden',
				!result.startedInPreviousMonth && 'translate-x-[15px]',
			)}
		>
			<div
				className={clsx(
					'absolute top-1 right-1 w-2 h-2 rounded-full',
					info.paid ? 'bg-green' : 'bg-red',
				)}
			/>
			<span>
				{info.closed_for_repair
					? 'Закрыт'
					: info.reservator
					? info.reservator.partner
						? info.reservator.partner?.org_name
						: `${info.reservator.client?.first_name} ${info.reservator.client?.last_name}`
					: info.fullName}
			</span>
		</div>
	)
}

import { $authHost, $host } from '.'
import { ChangeAvatar } from '../static/'
import { schemas } from './types'

type Tour = schemas['Tour']
// export type Paginated = schemas['PaginatedTourListList']

export const myTours = async (): Promise<schemas['TourRead'][]> => {
	const { data } = await $authHost.get('/v1/tours/me/')
	return data
}

export const changeAvatar = async ({ formData, tourId }: ChangeAvatar) => {
	$authHost.defaults.headers.common['Content-Type'] = 'multipart/form-data'
	const { data } = await $authHost.patch(`/v1/tours/${tourId}`, formData)
	return data
}

export const createTour = async (
	request: schemas['TourRequest'],
): Promise<schemas['TourRead']> => {
	const { data } = await $authHost.post('/v1/tours/', request)
	return data
}

export const getTour = async (
	id?: number | string,
): Promise<schemas['TourRead']> => {
	const { data } = await $host.get(`/v1/tours/${id}/`)
	return data
}

export const getTourBySlug = async (
	slug: string,
): Promise<schemas['TourRead']> => {
	const { data } = await $host.get(`/v1/tours/slug/${slug}/`)
	return data
}


export const getTourList = async (
	url: string,
): Promise<schemas['TourList'][]> => {
	const { data } = await $host.get(`/v1/manyTours/${url}`)
	return data
}

export const editTour = async (
	requestData: schemas['PatchedTourRequest'] & { id: number },
) => {
	const { data } = await $authHost.patch(
		`/v1/tours/${requestData.id}/`,
		requestData,
	)
	return data
}

export const deleteTour = async (id: number | undefined) => {
	const { data } = await $authHost.delete(`/v1/tours/${id}`)
	return data
}

export const filterByRegionId = async (requestData: number) => {
	const { data } = await $host.get(`/v1/tours/?region_id=${requestData}`)
	return data
}

export const getTourPaidServices = async (
	tourId: number,
): Promise<schemas['TourPaidServices'][]> => {
	const { data } = await $host.get(`/v1/paid-services/?tour_id=${tourId}`)
	return data
}

export const getTourPhones = async (
	tourId: number,
): Promise<schemas['TourPhones'][]> => {
	const { data } = await $host.get(`/v1/phones/?tour_id=${tourId}`)
	return data
}

export const getTourPackages = async (
	tourId: number,
): Promise<schemas['ListPackage'][]> => {
	const { data } = await $host.get(`/v1/packages/?tour_id=${tourId}`)
	return data
}

export const getTourNumbers = async (
	tourId: number,
): Promise<schemas['ListNumbers'][]> => {
	const { data } = await $host.get(`/v1/numbers/?tour_id=${tourId}`)
	return data
}

export const getAllMyTours = async (): Promise<schemas['TourRead'][]> => {
	const { data } = await $authHost.get('/v1/tours/me/')
	return data
}

export const getAllMedicalProfiles = async (): Promise<
	schemas['TourMedicalProfile'][]
> => {
	const { data } = await $host.get('/v1/tours/medicalProfiles/')
	return data
}

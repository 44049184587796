import { schemas } from '@/api/types'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  addForTourAdditionalInfoTitle,
  createTitleAdditionalService,
  deleteTitleAdditionalService,
  getAllTourAdditionalInfoTitles,
  getTourTitles,
} from 'api/additionalServices'
import { AdditionalServicesContent } from 'modules/pages/additionalService/AdditionalServicesContent'
import { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { useUserStore } from 'store/user'

export const AddAdditionalServiceContentPage = () => {
  const {user} = useUserStore()

  const getAllTourAdditionalInfoTitlesQuery = useQuery(
    ['additional-services-titles', user?.partner?.id],
    () => getAllTourAdditionalInfoTitles(user?.partner?.tourId),
  )
  const getTourTitlesQuery = useQuery<schemas['AdditonalInfoTitle'][]>(
    ['additional-titles', user?.partner?.tourId],
    () => getTourTitles(user?.partner?.tourId),
  )

  const deleteTitleAdditionalServiceMutation = useMutation(
    deleteTitleAdditionalService,
  )

  const createTitleAdditionalServiceMutation = useMutation(
    createTitleAdditionalService,
  )

  const addForTourAdditionalInfoTitleMutation = useMutation(
    addForTourAdditionalInfoTitle,
  )

  const handleAddTitleAdditionalService = useCallback(
    (titleId: number, service: string) => {
      const selectedTitle = getTourTitlesQuery.data?.find(
        title => title.title === titleId,
      )

      if (!selectedTitle) {
        addForTourAdditionalInfoTitleMutation.mutate(
          {title: titleId, tour: user?.partner?.tourId},
          {
            onSuccess: (data: schemas['AdditonalInfoTitle']) => {
              createTitleAdditionalServiceMutation.mutate(
                {
                  service: service,
                  title: data.id,
                },
                {
                  onSuccess: () => {
                    getTourTitlesQuery.refetch()
                  },
                },
              )
            },
          },
        )
      }

      if (selectedTitle) {
        createTitleAdditionalServiceMutation.mutate(
          {
            service: service,
            title: selectedTitle.id,
          },
          {
            onSuccess: () => {
              getTourTitlesQuery.refetch()
            },
          },
        )
      }
    },
    [
      getTourTitlesQuery.data,
      addForTourAdditionalInfoTitleMutation,
      createTitleAdditionalServiceMutation,
    ],
  )

  const handleDeleteTitleAdditionalService = (id: number) => {
    deleteTitleAdditionalServiceMutation.mutate(id, {
      onSuccess: () => {
        getTourTitlesQuery.refetch()
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>Дополнительные услуги</title>
        <meta name='robots' content='noindex'/>
      </Helmet>
      <AdditionalServicesContent
        titles={getTourTitlesQuery.data}
        additionalInfo={getAllTourAdditionalInfoTitlesQuery.data}
        handleAdd={handleAddTitleAdditionalService}
        handleDelete={handleDeleteTitleAdditionalService}
      />
    </>
  )
}

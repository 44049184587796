import { useNavigate } from '@tanstack/react-location'
import { useMutation } from '@tanstack/react-query'
import { deleteProgram } from 'api/guide/program'
import { ProfileButton } from 'components/profile/ProfileButton'
import toast from 'react-hot-toast'
import { Modal } from '../Modal'

interface Props {
	isVisible: boolean
	onClose: () => void
	id?: number
}

export const EditGuide = ({ isVisible, onClose, id }: Props) => {
	const navigate = useNavigate()
	const { mutate } = useMutation(deleteProgram)
	return (
		<Modal
			className='px-16 py-8 bg-white max-w-lg'
			isVisible={isVisible}
			onClose={() => {
				onClose()
			}}
		>
			<p className='text-2xl  font-semibold text-dark text-center mb-5'>
				Редактирование программы
			</p>
			<form className='flex flex-col gap-2.5'>
				<div className={'flex justify-between mt-5'}>
					<ProfileButton
						className='block bg-light-blue'
						onClick={() => {
							if (id) navigate({ to: `/guide/program/edit/?id=${id}` })
						}}
					>
						Редактировать
					</ProfileButton>
					<ProfileButton
						className='block bg-red'
						onClick={() => {
							if (id)
								mutate(id, {
									onSuccess: () => {
										toast.success('Успешно удалено')
									},
									onError: () => {
										toast.error('Ошибка при удалении')
									},
								})
						}}
					>
						Удалить
					</ProfileButton>
				</div>
			</form>
		</Modal>
	)
}

import { Link, useNavigate } from '@tanstack/react-location'
import { useMutation } from '@tanstack/react-query'
import { loginUser } from 'api/auth'
import { Button } from 'components/ui/Button'
import { Input } from 'components/ui/Input'
import emailPhoto from 'helpers/images/email.png'
import passwordPhoto from 'helpers/images/password.png'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast, { Toaster } from 'react-hot-toast'
import { LoginFields } from 'static/interfaces'
import { useUserStore } from 'store/user'
import { removeSpaces } from 'utils/utils'

interface Props {
	forgotPassword: () => void
}

export const Authorization = ({ forgotPassword }: Props) => {
	const navigate = useNavigate()
	const { addUser } = useUserStore()

	const [showPassword, setShowPassword] = useState(false)
	const { mutate, isLoading } = useMutation(loginUser)

	
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<LoginFields>()
	const onSubmit = (data: LoginFields) => {
		mutate(
			{
				username: data.username,
				password: data.password,
			},
			{
				onSuccess: loginData => {
					if (!loginData.partner) {
						toast.error('Вы не являетесь партнером')
					}
					localStorage.setItem('accessToken', loginData.access)
					toast.success('Вы успешно вошли в аккаунт')
					reset()
					addUser({
						id: loginData.userId,
						...(loginData.email
							? {
									email: {
										id: loginData.email.id,
										value: loginData.email.email,
									},
							  }
							: null),
						...(loginData.phone
							? {
									phone: {
										id: loginData.phone.id,
										value: loginData.phone.phone,
									},
							  }
							: null),
						partner: {
							id: loginData.partner.id,
							orgName: loginData.partner.org_name ?? '',
							...(loginData.guide ? { guide: { id: loginData.guide } } : null),
							pick: loginData.pick === 'guide' ? 'Guide' : 'Resort',
							tourId:
								loginData.tours.length !== 0
									? loginData.tours[0].id
									: undefined,
						},
					})
					if (loginData.tours.length === 0 && loginData.pick === 'resort') {
						navigate({ to: '/add-organization' })
					} else if (!loginData.guide && loginData.pick === 'guide') {
						navigate({ to: '/add-guide' })
					} else {
						navigate({ to: '/' })
					}
				},

				onError: (error: any) => {
					if (Array.isArray(error.response.data)) {
						for (const key in error.response.data) {
							toast.error(error.response.data[key])
						}
					} else {
						toast.error(error.response.data.detail)
					}
				},
			},
		)
	}

	return (
		<>
			<Toaster />
			<div className={'max-w-md shadow-2xl p-10 rounded-2xl'}>
				<p className='text-2xl font-semibold text-dark text-center mb-5'>
					Вход
				</p>
				<p className='flex text-dark text-sm mb-3 font-medium justify-center'>
					У вас нет учетной записи?
					<Link className='text-green font-medium' to={'/registration'}>
						&nbsp; Создать учетную запись
					</Link>
				</p>

				<form onSubmit={handleSubmit(onSubmit)}>
					<Input
						error={errors.username?.message}
						{...register('username', {
							required: 'Заполните поле',
							validate: {
								isEmailOrPhone: value =>
									/^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/.test(
										value,
									) ||
									/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
										value,
									) ||
									'Неверный формат номера телефона',
							},
						})}
						icon={emailPhoto}
						placeholder='E-mail или номер телефона'
						className='mb-4'
					/>
					<Input
						error={errors.password?.message}
						{...register('password', {
							required: 'Заполните поле',
							validate: {
								noSpaces: (value) =>
									removeSpaces(value) ||
									'Поле не должно начинаться или заканчиваться пробелами',
							},
						})}
						className='mb-1'
						placeholder='Пароль'
						type={showPassword ? 'text' : 'password'}
						icon={passwordPhoto}
						showPassword={showPassword}
						setShowPassword={() => setShowPassword(!showPassword)}
					/>
					<button
						onClick={forgotPassword}
						className='text-sm text-[#002C50] font-medium mb-4 inline-block'
						type='button'
					>
						Забыли пароль?
					</button>

					<Button
						isLoading={isLoading}
						disabled={isLoading}
						type='submit'
						className='w-full font-medium h-11 mb-7'
						variant='inline'
					>
						Войти
					</Button>
				</form>

				<p className='text-center font-medium'>
					При входе вы соглашаетесь с нашими{' '}
				</p>
				<p className='justify-center  font-semibold mx-auto block text-center mb-5'>
					<a
						className=' text-light-green'
						href='https://mtour.kz/docs/user_rules.html'
						rel='noopener noreferrer'
						target='_blank'
					>
						Условия использования
					</a>{' '}
					&nbsp;и&nbsp;
					<a
						className=' text-light-green'
						href='https://mtour.kz/docs/public_offer_of_individuals.html'
						rel='noopener noreferrer'
						target='_blank'
					>
						Публичная оферта для клиентов
					</a>
				</p>
			</div>
		</>
	)
}

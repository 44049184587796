import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { changeAddress } from 'api/ProfileService'
import { getCountries, getRegions } from 'api/address'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ProfileInput } from 'components/profile/ProfileInput'
import { ProfileListBox } from 'components/profile/ProfileListBox'
import { useFirstRender } from 'hooks/useFirstRender'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { AddressFields } from 'static/interfaces'
import { useUserStore } from 'store/user'
import { Modal } from '../Modal'
interface Props {
	isVisible: boolean
	onClose: () => void
}

export const EditAddress = ({ isVisible, onClose }: Props) => {
	const queryClient = useQueryClient()
	const { user } = useUserStore()
	const firstRender = useFirstRender()
	const { register, handleSubmit } = useForm<AddressFields>()

	const changeAddressMutation = useMutation(changeAddress)

	const [region, setRegion] = useState<number | undefined>()
	const [country, setCountry] = useState<number | undefined>()
	const [isRegionsDisabled, setIsRegionsDisabled] = useState(true)
	const getCountriesQuery = useQuery(['countries'], getCountries)
	const getRegionsQuery = useQuery(
		['regions'],
		() => {
			// @ts-expect-error getCountriesQuery может быть undefined
			return getRegions(country ?? getCountriesQuery.data[0].id)
		},
		{
			enabled: getCountriesQuery.isSuccess,
		},
	)
	useEffect(() => {
		if (firstRender) return
		getRegionsQuery.refetch()
		setIsRegionsDisabled(false)
	}, [country])

	const onSubmit = (data: AddressFields) => {
		changeAddressMutation.mutate(
			{
				address: {
					...data,
					region,
					country,
				},
				tour: user?.partner?.tourId,
			},
			{
				onSuccess: () => {
					queryClient.refetchQueries(['tour', user?.partner?.tourId])
					toast.success('Адрес успешно изменен')
					onClose()
				},
			},
		)
	}

	if (getRegionsQuery.isLoading || getCountriesQuery.isLoading)
		return <>Loading...</>
	if (getRegionsQuery.isError || getCountriesQuery.isError) return <>Error</>

	return (
		<div>
			<Modal
				className='px-10 py-8 bg-white max-w-lg'
				isVisible={isVisible}
				onClose={onClose}
			>
				<p className='text-2xl  font-semibold text-dark text-center mb-5'>
					Редактирование адреса
				</p>
				<form onSubmit={handleSubmit(onSubmit)}>
					<ProfileListBox
						label='Страна'
						className='mb-2'
						width='w-full'
						onChange={option => {
							setCountry(option.id)
						}}
						list={getCountriesQuery.data.map(country => ({
							id: country.id,
							value: country.name,
						}))}
					/>
					<ProfileListBox
						key={country}
						label='Регион'
						className='mb-2'
						width='w-full'
						disabled={isRegionsDisabled}
						onChange={option => {
							setRegion(option.id)
						}}
						list={getRegionsQuery.data.map(region => ({
							id: region.id,
							value: region.name,
						}))}
					/>
					<ProfileInput
						label='Район'
						placeholder='Сарыагашский р-н'
						type='text'
						className='mb-2 block mx-auto'
						{...register('district', {
							required: 'Заполните поле',
						})}
					/>
					<ProfileInput
						label='Улица'
						placeholder='ул. Абая'
						type='text'
						className='mb-2 block mx-auto'
						{...register('street', {
							required: 'Заполните поле',
						})}
					/>
					<ProfileInput
						label='Номер дома'
						placeholder='76'
						type='text'
						className='mb-2 block mx-auto'
						{...register('home_number', {
							required: 'Заполните поле',
						})}
					/>
					<ProfileButton className='mt-7 block mx-auto' onClick={() => null}>
						Подтвердить
					</ProfileButton>
				</form>
			</Modal>
		</div>
	)
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addPhoto, deletePhoto } from 'api/photos'
import clsx from 'clsx'
import upload from 'helpers/images/uploadMini.svg'
import { Trash } from 'iconsax-react'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-hot-toast'
import { UploadImages } from 'static/interfaces'
import { useUserStore } from 'store/user'
import { formatBytes } from 'utils/utils'
import { Modal } from '../Modal'

interface Props {
	isVisible: boolean
	onClose: () => void
}

export const EditPhotos = ({ isVisible, onClose }: Props) => {
	const queryClient = useQueryClient()

	const deletePhotoMutation = useMutation(deletePhoto)
	const addPhotoMutation = useMutation(addPhoto)

	const { user } = useUserStore()
	const [selectedFiles, setSelectedFiles] = useState<UploadImages[]>([])

	const onDrop = useCallback((acceptedFiles: File[]) => {
		acceptedFiles.map(file => {
			const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png']
			if (!acceptedImageTypes.includes(file['type'])) {
				toast.error('Выберите соответствующий тип файла')
				return
			} else if (file.size > 1048576 * 5) {
				toast.error('Файл слишком большой')
				return
			}
			const formData = new FormData()
			formData.append('photo', file)
			formData.append('tour', `${user?.partner?.tourId}`)
			setSelectedFiles(prevFiles => [
				...prevFiles,
				{
					file,
					preview: URL.createObjectURL(file),
					status: 'loading',
					id: prevFiles.length - 1,
				},
			])
			addPhotoMutation
				.mutateAsync({ formData })
				.then(result => {
					queryClient.refetchQueries(['org-photos', user?.partner?.tourId])
					setSelectedFiles(prevFiles =>
						prevFiles.map(file => {
							if (
								result.photo
									.toLocaleLowerCase()
									.includes(
										file.file.name.toLocaleLowerCase().replace(/\.[^/.]+$/, ''),
									)
							) {
								return {
									...file,
									id: result.id,
									status: 'uploaded',
								}
							}
							return file
						}),
					)
				})
				.catch(error => {
					toast.error('Что-то пошло не так, попробуйте еще раз')
				})
		})
	}, [])

	const { getRootProps, getInputProps } = useDropzone({ onDrop })
	useEffect(() => {
		return () => selectedFiles.forEach(file => URL.revokeObjectURL(file.preview))
	}, [])

	return (
		<div>
			<Modal
				className='px-10 py-8 bg-white max-w-3xl'
				isVisible={isVisible}
				onClose={() => {
					onClose()
					setSelectedFiles([])
				}}
			>
				<div
					{...getRootProps({ className: 'dropzone' })}
					className='flex flex-col items-center justify-center border-dashed	border-2 rounded-lg border-[#D7D7D7] py-10 mb-5 cursor-pointer'
				>
					<img src={upload} width={'64'} height={'64'} alt='' />
					<p className='text-center mt-5'>
						Перетащите изображения или нажмите загрузить <br />
						<span>JPG, PNG, JPEG</span>
					</p>
					<input
						id='file'
						type='file'
						className='input-zone'
						{...getInputProps()}
						name='theFile'
					/>
				</div>
				<div className='flex gap-5 flex-wrap mt-8'>
					<div className=''>
						<label
							htmlFor='file'
							className='border-2 border-light-green rounded-xl w-[120px] h-20 flex flex-col items-center justify-center hover:cursor-pointer'
						>
							<img alt='' src={upload} width='24' height='24' />
							<span className='text-sm text-dark'>Загрузить</span>
						</label>
						<span className='flex flex-col'>
							<span className='font-bold text-xs text-dark mt-2 mb-1'>
								Jpg, Png, Gif
							</span>
							<span className='text-xs text-[#5C5C5C]'>Макс. размер 5Мб. </span>
						</span>
					</div>
					{selectedFiles?.map((selectedFile, index) => (
						<div
							className='relative w-[120px] group'
							key={selectedFile.file.name}
						>
							<span
								style={{
									backgroundColor: 'rgba(0, 0, 0, 0.5)',
								}}
								className='transition-all duration-200 ease-in-out group-hover:visible invisible flex group-hover:opacity-100 opacity-0 h-20 backdrop-blur-md rounded-xl absolute z-10 w-full items-center justify-center'
							>
								{deletePhotoMutation.isLoading ||
								selectedFile.status == 'loading' ? (
									<svg
										className={clsx(
											'h-0 w-0 transition-all ease-out duration-300',
											deletePhotoMutation.isLoading ||
												selectedFile.status === 'loading'
												? 'h-max w-[25px]'
												: '',
										)}
										version='1.1'
										id='L9'
										xmlns='http://www.w3.org/2000/svg'
										xmlnsXlink='http://www.w3.org/1999/xlink'
										x='0px'
										y='0px'
										viewBox='0 0 100 100'
										enableBackground='new 0 0 0 0'
										xmlSpace='preserve'
									>
										<path
											fill='#FFFFFF'
											d='M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50'
										>
											<animateTransform
												attributeName='transform'
												attributeType='XML'
												type='rotate'
												dur='1s'
												from='0 50 50'
												to='360 50 50'
												repeatCount='indefinite'
											/>
										</path>
									</svg>
								) : (
									<Trash
										onClick={() => {
											deletePhotoMutation.mutate(selectedFile.id)
											selectedFiles.splice(index, 1)
										}}
										className='bg-[#FF3030] w-8 p-[6px] h-8 flex items-center justify-center rounded-xl cursor-pointer'
										size='20'
										color='#FFFFFF'
									/>
								)}
							</span>
							<span className='relative block rounded-xl'>
								<img
									className='rounded-xl w-[120px] h-[80px]'
									src={selectedFile.preview}
									alt='Загруженная фотография'
								/>
							</span>
							<span className='flex flex-col'>
								<span className='font-bold text-xs text-dark truncate w-[120px] inline-block mt-2 mb-1'>
									{selectedFile.status !== 'loading'
										? selectedFile.file.name
										: 'Загрузка...'}
								</span>
								<span className='text-xs text-[#5C5C5C]'>
									{formatBytes(selectedFile.file.size)}
								</span>
							</span>
						</div>
					))}
				</div>
			</Modal>
		</div>
	)
}

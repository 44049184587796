import {schemas} from '@/api/types'
import clsx from 'clsx'
import useWindowDimensions from 'hooks/useWindowDimensions'
import {ReactNode} from 'react'
import {getAmountOfDaysInMonth, getDayOfWeek} from 'utils/bookingSystemUtils'

interface Props {
  date: Date
  number?: schemas['ListNumbers']
}

export const Month = ({date, number}: Props) => {
  const Table: ReactNode[] = []
  const {width} = useWindowDimensions()

  Table.push(
    <th
      key={0}
      className={clsx(
        'border-2 w-48 h-12 items-center border-border-color-crm text-[#1A4F6E] font-medium',
      )}
    >
      {number ? number.title : 'Выберите номер'}
    </th>,
  )
  for (let i = 1; i <= getAmountOfDaysInMonth(date); i++) {
    Table.push(
      <th
        style={{
          width: `${(width - 292) / getAmountOfDaysInMonth(date)}px`,
          maxWidth: '40px',
        }}
        key={i}
        className={clsx(
          'border-2 h-12 border-[#00ACDE] text-[#1A4F6E] font-medium',
        )}
      >
        <span className='text-center text-sm w-full inline-block'>{i}</span>
        <br/>
        <span
          className={clsx(
            'text-xs text-center w-full inline-block',
            getDayOfWeek(i) === 'Сб' || getDayOfWeek(i) === 'Вс'
              ? 'text-red'
              : '',
          )}
        >
          {getDayOfWeek(i)}
        </span>
      </th>,
    )
  }
  return (
    <thead>
      <tr>{Table}</tr>
    </thead>
  )
}

import { $authHost, $host } from '..'
import { schemas } from '../types'

export const createGuide = async (
	request: schemas['GuideRequest'],
): Promise<schemas['Guide']> => {
	const { data } = await $authHost.post('/v1/guides/', request)
	return data
}

export const editGuide = async (
	request: schemas['PatchedGuideRequest'] & { id?: number },
): Promise<schemas['Guide']> => {
	const { data } = await $authHost.patch(`/v1/guides/${request.id}`, request)
	return data
}

export const getGuideByTourId = async (
	tourId: number,
): Promise<schemas['GuideList'][]> => {
	const { data } = await $host.get(`/v1/guides/?tour_id=${tourId}`)
	return data
}

export const getGuideByPartnerId = async (
	id: number | undefined,
): Promise<schemas['GuideList'][]> => {
	const { data } = await $host.get(`/v1/guides/partner/?org_id=${id}`)
	return data
}

export const getGuide = async (
	id: number | undefined,
): Promise<schemas['GuideRead']> => {
	const { data } = await $host.get(`/v1/guides/${id}`)
	return data
}

export const deleteGuide = async (id: number) => {
	const { data } = await $authHost.delete(`/v1/guides/${id}`)
	return data
}

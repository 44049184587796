import { schemas } from '@/api/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { changeNumberCabinet, getRoom } from 'api/numberCabinets'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ProfileInput } from 'components/profile/ProfileInput'
import { Spinner } from 'components/ui/Spinner'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { PhoneNumberField } from 'static/interfaces'
import { useUserStore } from 'store/user'
import { Modal } from '../Modal'

interface Props {
	isVisible: boolean
	onClose: () => void
	id?: number
}

export const EditRoom = ({ isVisible, onClose, id }: Props) => {
	const queryClient = useQueryClient()
	const { mutate } = useMutation(changeNumberCabinet)
	const { handleSubmit } = useForm<PhoneNumberField>()
	const { user } = useUserStore()
	const { data, isLoading, isError } = useQuery(['room', id], () => getRoom(id))
	const [name, setName] = useState('')

	const onSubmit = () => {
		if (!user?.partner?.tourId) {
			toast.error('Что-то пошло не так, попробуйте позднее')
			return
		}
		if (name === '') {
			toast.error('Заполните поле')
			return
		}
		mutate(
			{
				id,
				humanize_name: name,
			},
			{
				onSuccess: () => {
					toast.success('Отредактировано')
					queryClient.refetchQueries(['number-cabinets', user?.partner?.tourId])
					const rooms = queryClient.getQueryData<schemas['NumberCabinets'][]>([
						'number-cabinets',
						user?.partner?.tourId,
					])
					if (rooms) {
						queryClient.setQueryData<schemas['NumberCabinets'][]>(
							['number-cabinets', user?.partner?.tourId],
							rooms.map(room => {
								if (room.id === id) {
									return {
										...room,
										humanize_name: name,
									}
								}
								return room
							}),
						)
					}
					onClose()
				},
				onError: () => {
					toast.error('Произошла ошибка, попробуйте позднее')
				},
			},
		)
	}

	if (isError) return <div>Error</div>


	return (
		<Modal
			className='px-8 py-10 bg-white max-w-lg'
			isVisible={isVisible}
			onClose={onClose}
		>
			{isLoading ? (
				<Spinner />
			) : (
				<form onSubmit={handleSubmit(onSubmit)}>
					<ProfileInput
						label='Введите название комнаты'
						placeholder='312'
						type='text'
						className='block mx-auto'
						defaultValue={data?.humanize_name ?? ''}
						onChange={event => setName(event.target.value)}
					/>
					<ProfileButton className='mt-7 block ml-auto' type='submit'>
						Подтвердить
					</ProfileButton>
				</form>
			)}
		</Modal>
	)
}

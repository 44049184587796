import { Link } from '@tanstack/react-location'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteNumber } from 'api/number'
import { schemas } from 'api/types'
import { Confirm } from 'components/modal/delete/Confirm'
import imageNotFound from 'helpers/images/NoImage.svg'
import { useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useUserStore } from 'store/user'
import SwiperCore, { Autoplay, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getImageURL, priceSpacing } from 'utils/utils'
import {useIdsStore} from 'store/idsInsideResort'

interface Props {
	number: schemas['ListNumbers']
	showDetails: (id: number) => void
	edit: (id: number) => void
}

export const Number = ({ showDetails, number, edit }: Props) => {
	const queryClient = useQueryClient()
	const { mutate } = useMutation(deleteNumber)
	const { user } = useUserStore()
	const { setNumberId } = useIdsStore()
	const [isConfirmVisible, setIsConfirmVisible] = useState(false)

	const swiperRef = useRef<SwiperCore>()

	const onInit = (Swiper: SwiperCore): void => {
		swiperRef.current = Swiper
		swiperRef.current.autoplay.stop()
	}
	const handleMouseOver = () => {
		if (swiperRef.current) swiperRef.current.autoplay.start()
	}
	const handleMouseLeave = () => {
		if (swiperRef.current) swiperRef.current.autoplay.stop()
	}

	const _deleteNumber = () => {
		mutate(number.id, {
			onSuccess: () => {
				toast.success('Успешно удалено')
				queryClient.refetchQueries(['place', 'numbers', user?.partner?.tourId])
			},
			onError: () => {
				toast.error('Произошла ошибка попробуйте позднее')
			},
		})
	}

	return (
		<>
			<Confirm
				isVisible={isConfirmVisible}
				onClose={() => setIsConfirmVisible(false)}
				requestFunction={_deleteNumber}
				requestArg={number.id}
			/>
			<div className='flex gap-x-7 p-5 w-full max-w-3xl lg:max-w-none md:max-w-1xl mt-8 lg:w-full md:flex-col md:p-0 md:gap-0 md:flex-wrap shadow-xl rounded-2xl'>
				<div className='w-[240px] md:shrink shrink-0 md:w-full overflow-hidden h-[180px]'>
					<Swiper
						onInit={onInit}
						autoplay={{
							delay: 1200,
							disableOnInteraction: true,
						}}
						mousewheel={{ forceToAxis: false, invert: true }}
						spaceBetween={10}
						className='rounded-xl'
						breakpoints={{
							0: {
								slidesPerView: 1,
							},
							576: {
								slidesPerView: 3,
							},
							768: {
								slidesPerView: 1,
							},
							1024: {
								slidesPerView: 1,
							},
						}}
						pagination={{
							dynamicBullets: true,
						}}
						modules={[Pagination, Autoplay]}
					>
						{number.numbershots_set.length && number.numbershots_set ? (
							number.numbershots_set.map(slide => (
								<SwiperSlide key={slide.id}>
									<img
										onMouseOver={handleMouseOver}
										onMouseLeave={handleMouseLeave}
										className='rounded-md w-full h-[180px] block'
										alt='Изображение курортного места'
										src={getImageURL(slide.thumbnail)}
									/>
								</SwiperSlide>
							))
						) : (
							<SwiperSlide>
								<img
									onMouseOver={handleMouseOver}
									onMouseLeave={handleMouseLeave}
									className='rounded-md w-full h-[180px] block'
									alt=''
									src={imageNotFound}
								/>
							</SwiperSlide>
						)}
					</Swiper>
				</div>
				<div className='flex flex-col items-start w-full md:p-5 sm:p-4'>
					<h6 className='font-semibold text-[#111827] text-xl mb-2'>
						{number.title}
					</h6>
					<div className='flex flex-wrap items-center'>
						{number.comforts.map(benefit => (
							<p
								className='flex basis-1/2 gap-x-2 text-[#6B7280] mb-2.5 font-medium sm:text-sm'
								key={benefit.id}
							>
								<img
									src={getImageURL(benefit.icon)}
									alt='icon'
									className='fill-dark -translate-y-0.5 '
								/>
								{benefit.name}
							</p>
						))}
					</div>
					<button onClick={() => showDetails(number.id)}>
						<span className='mt-1.5 bg-gradient-to-r from-light-blue to-green text-transparent bg-clip-text font-medium text-base sm:text-sm'>
							Подробнее о номере
						</span>
					</button>
					<div className='flex flex-row justify-between w-full mt-auto items-center'>
						<span className='flex flex-row gap-1 items-end text-[#111827]'>
							<span className='uppercase font-medium text-2xl sm:text-xl'>
								₸ {priceSpacing(number.price)}
							</span>
							<span className='lowercase font-normal text-base sm:text-sm -translate-y-[2px]'>
								ночь
							</span>
						</span>
						<div className='flex flex-row gap-2.5 font-medium'>
							<Link
								onClick={() => setNumberId(number.id)}
								className='border-[#21BCDE] rounded-xl text-dark-blue hover:bg-dark-blue/[.1] p-2 transition-all duration-200 ease-in-out'
								to={`/resort/numbers/edit?id=${number.id}`}
							>
								Редактировать
							</Link>
							<button
								className='border-[#21BCDE] rounded-xl text-red hover:bg-dark-blue/[.1] p-2 transition-all duration-200 ease-in-out'
								onClick={() => setIsConfirmVisible(true)}
							>
								Удалить
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

import { useNavigate } from '@tanstack/react-location'
import { useQuery } from '@tanstack/react-query'
import { getProgramsByGuideId } from 'api/guide/program'
import { EditGuide } from 'components/modal/edit/Guide'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ContentHeader } from 'components/ui/Content/ContentHeader'
import { GuideProgramsCard } from 'modules/pages/guide/GuideProgramsCard'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useUserStore } from 'store/user'

export const GuideProgramsPage = () => {
	const { user } = useUserStore()
	const cardTest = [1, 2, 3, 4, 5, 6, 7, 8]
	const navigate = useNavigate()

	const [editGuide, setEditGuide] = useState(false)
	const [id, setId] = useState<number>()

	const { data, isLoading, isError } = useQuery(
		['programs', user?.partner?.guide?.id],
		() => getProgramsByGuideId(user?.partner?.guide?.id),
	)

	return (
		<>
			<Helmet>
				<title>Программы гида | MTour</title>
			</Helmet>

			<EditGuide
				isVisible={editGuide}
				onClose={() => {
					setEditGuide(!editGuide)
					setId(undefined)
				}}
				id={id}
			/>
			<div className='block mx-auto w-3/4'>
				<ContentHeader className='flex w-full shadow-lg items-center justify-between py-5 mt-10'>
					<p className='font-medium text-2xl text-dark'>Программы</p>
					<ProfileButton
						className={'bg-light-blue font-normal'}
						onClick={() => navigate({ to: '/guide/program/add' })}
					>
						Добавить программу
					</ProfileButton>
				</ContentHeader>
				<div
					className='grid grid-cols-4 justify-center gap-y-5 mb-10 px-3 bg-[#FFF] border-solid border-2
            border-[#E4E7EC] border-t-0 rounded-b-xl py-5 shadow-lg'
				>
					{!isError &&
						!isLoading &&
						data.map(program => (
							<GuideProgramsCard
								key={program.id}
								program={program}
								onEdit={id => {
									setEditGuide(prevState => !prevState)
									setId(id)
								}}
							/>
						))}
				</div>
			</div>
		</>
	)
}

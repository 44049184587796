import { useLocation, useNavigate } from '@tanstack/react-location'
import { useQuery } from '@tanstack/react-query'
import { getMe } from 'api/user'
import clsx from 'clsx'
import { SideBarLink } from 'components/partner/sidebar/Link'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ArrowCircleLeft, BookSaved, User } from 'iconsax-react'
import { useState } from 'react'
import { guideSidebarLinks, resortSidebarLinks } from 'static'
import { useUserStore } from 'store/user'
import { SpecialLink } from '../components/partner/sidebar/SpecialLink'

export const SideBar = () => {
  const [open, setOpen] = useState(false)
  const {user} = useUserStore()
  const location = useLocation().current.pathname.split('/')[1]
  const navigate = useNavigate()

  const {data, isLoading, isError} = useQuery(['me'], getMe)

  return (
    <div className={'relative text-[#FFF] min-h-screen'}>
      <div
        className={clsx(
          'h-full p-5 pt-8 duration-300 bg-white shadow-lg text-[black]',
          open ? 'w-64' : 'w-20 ',
        )}
      >
        <ArrowCircleLeft
          size="32"
          color="#2ccce4"
          className={`absolute cursor-pointer -right-3 top-9 w-7 rounded-full  ${
            !open && 'rotate-180'
          }`}
          onClick={() => setOpen(!open)}
          variant={'Bold'}
        />
        <div className="flex">
          {!open && <p className="text-2xl font-bold text-light-blue">M</p>}
          <h1
            className={clsx(
              'text-white origin-left font-medium text-xl duration-200 w-full text-center',
              !open && 'scale-0',
            )}
          >
            <p className="text-2xl font-bold text-light-blue">
              M<span className="text-green">Tour</span>
            </p>
          </h1>
        </div>
        <ul className="pt-6">
          <SpecialLink
            href='/'
            text='Личные данные'
            open={open}
            icon={
              <User
                size="32"
                color={location === '' ? '#21BCDE' : '#697A8D'}
                variant="Outline"
                className='w-6'
              />}
          />
          <div className="mt-4 w-max">
            {isLoading || isError ? null : user?.partner?.pick === 'Guide' ? (
              data.guide ? (
                guideSidebarLinks.map(item => (
                  <SideBarLink key={item.id} link={item} open={open}/>
                ))
              ) : (
                <ProfileButton
                  className={clsx('truncate', !open && 'px-[8px] py-[4px]')}
                  onClick={() => navigate({to: '/add-guide'})}
                >
                  {open ? 'Добавить агенство' : '+'}
                </ProfileButton>
              )
            ) : data.tours.length !== 0 ? (
              resortSidebarLinks.map(item => (
                <SideBarLink key={item.id} link={item} open={open}/>
              ))
            ) : (
              <ProfileButton
                className={clsx('truncate', !open && 'px-[8px] py-[4px]')}
                onClick={() => navigate({to: '/add-organization'})}
              >
                {open ? 'Добавить агенство' : '+'}
              </ProfileButton>
            )}
          </div>
          <SpecialLink
            href='/education'
            text='Образовательные Курсы'
            open={open}
            icon={
              <BookSaved
                size="32"
                color={location === 'education' ? '#21BCDE' : '#697A8D'}
                variant="Outline"
                className='w-6'
              />}
            className='mt-4 text-[14px]'
          />
        </ul>
      </div>
    </div>
  )
}

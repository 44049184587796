import { schemas } from '@/api/types'
import clsx from 'clsx'
import { AdditionalServiceBadgeItem } from './AdditionalServiceBadgeItem'

interface Props {
	serviceBadges: schemas['AdditionalInfoServicesRead'][] | undefined
	onDelete: (id: number) => void
}

export const AdditionalServiceBadgeItems = ({
	serviceBadges,
	onDelete,
}: Props) => {
	return (
		<div
			className={clsx(
				'flex flex-row flex-wrap gap-5 w-full ',
				serviceBadges?.length !== 0 && 'py-4',
			)}
		>
			{serviceBadges &&
				serviceBadges.map(
					(titleBadge: schemas['AdditionalInfoServicesRead']) => (
						<AdditionalServiceBadgeItem
							key={titleBadge.id}
							serviceBadge={titleBadge}
							onDelete={onDelete}
						/>
					),
				)}
		</div>
	)
}

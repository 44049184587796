import { useNavigate } from '@tanstack/react-location'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { deleteGuide, getGuideByPartnerId } from 'api/guide/guide'
import { deleteTour, getAllMyTours } from 'api/tour'
import { AddGuide } from 'components/modal/create/Guide'
import { Confirm } from 'components/modal/delete/Confirm'
import { ContentBody } from 'components/ui/Content/ContentBody'
import { ContentHeader } from 'components/ui/Content/ContentHeader'
import { Spinner } from 'components/ui/Spinner'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useUserStore } from 'store/user'
import { Resort } from 'components/partner/settings/Resort'
import { Guide } from 'components/partner/settings/Guide'

export const Settings = () => {
  const queryClient = useQueryClient()
  const {mutate: removeTour} = useMutation(deleteTour)
  const {mutate: removeGuide} = useMutation(deleteGuide)
  const {editUser, user} = useUserStore()

  const [isAddGuide, setIsAddGuide] = useState(false)
  const [isConfirmVisible, setIsConfirmVisible] = useState(false)
  const navigate = useNavigate()

  const {data, isLoading, isError} = useQuery(
    ['my-tours', user?.partner?.id],
    getAllMyTours,
  )

  const {
    data: guide,
    isLoading: isGuideLoading,
    isError: isGuideError,
  } = useQuery(['guides', user?.partner?.id], () =>
    getGuideByPartnerId(user?.partner?.id),
  )

  useEffect(() => {
    if (!user || !guide || guide?.length === 0) return
    editUser({
      ...user,
      partner: {
        ...user.partner,
        guide: {
          id: guide[0].id,
        },
      },
    })
  }, [user?.partner?.pick, data])

  if (isLoading || isGuideLoading) return <Spinner/>
  if (isError || isGuideError) return <>Error</>

  return (
    <>
      <Confirm
        isVisible={isConfirmVisible}
        onClose={() => setIsConfirmVisible(false)}
        requestFunction={removeTour}
        requestArg={user?.partner?.tourId}
        onSuccess={() => {
          queryClient.refetchQueries(['my-tours', user?.partner?.tourId])
          toast.success('Успешно удалено')
        }}
        onError={() => {
          toast.error('Что-то пошло не так, попробуйте позднее')
        }}
      />
      {guide[0] &&
        <Confirm
          isVisible={isConfirmVisible}
          onClose={() => setIsConfirmVisible(false)}
          requestFunction={removeGuide}
          requestArg={guide[0].id}
          onSuccess={() => {
            toast.success('Успешно удалено')
            queryClient.setQueryData(['me'], [null])
            navigate({to: '/add-guide', replace: true})
          }}
          onError={() => {
            toast.error('Что-то пошло не так, попробуйте позднее')
          }}
        />
      }
      <AddGuide isVisible={isAddGuide} onClose={() => setIsAddGuide(false)}/>
      <div className='block mx-auto mt-7 w-3/4 max-w-5xl'>
        <ContentHeader className='flex w-full items-center justify-between py-5 mt-10'>
          <p className='font-bold text-xl text-[#1A4F6E]'>Настройки</p>
        </ContentHeader>
        <ContentBody className='flex flex-row flex-wrap py-16 px-16'>
          {user?.partner?.pick === 'Resort'
            ?
              <Resort
                user={user}
                data={data}
                setIsConfirmVisible={(on) => setIsConfirmVisible(on)}
                editUser={editUser}
              />
            :
              <Guide
                guide={guide}
                setIsConfirmVisible={(on) => setIsConfirmVisible(on)}
              />
            }
        </ContentBody>
      </div>
    </>
  )
}

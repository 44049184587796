import clsx from 'clsx'
import { ComponentPropsWithoutRef } from 'react'

interface Props extends ComponentPropsWithoutRef<'button'> {
	isLoading?: boolean
}

export const ProfileButton = ({
	className,
	children,
	isLoading,
	...rest
}: Props) => {
	return (
		<button
			className={clsx(
				'font-medium text-white py-2.5 px-4 bg-light-blue rounded-xl flex justify-center',
				className,
			)}
			{...rest}
		>
			<svg
				className={clsx(
					'h-0 w-0 transition-all ease-out duration-300',
					isLoading ? 'h-max w-[25px]' : '',
				)}
				version='1.1'
				id='L9'
				xmlns='http://www.w3.org/2000/svg'
				xmlnsXlink='http://www.w3.org/1999/xlink'
				x='0px'
				y='0px'
				viewBox='0 0 100 100'
				enableBackground='new 0 0 0 0'
				xmlSpace='preserve'
			>
				<path
					fill='#fff'
					d='M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50'
				>
					<animateTransform
						attributeName='transform'
						attributeType='XML'
						type='rotate'
						dur='1s'
						from='0 50 50'
						to='360 50 50'
						repeatCount='indefinite'
					/>
				</path>
			</svg>
			{children}
		</button>
	)
}

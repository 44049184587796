import { daysOfWeek } from '../static'
import { BookingDetailedInfo } from '../static/interfaces'

export const getAmountOfDaysInMonth = (date: Date) => {
	return (
		33 -
		new Date(
			new Date(date).getFullYear(),
			new Date(date).getMonth(),
			33,
		).getDate()
	)
}

export const getReservationInfo = (start: string, end: string) => {
	return (new Date(end).getTime() - new Date(start).getTime()) / (1000 * 86400)
}
export const getDateRange = (dateToGetMonth: Date) => {
	return {
		firstDate: new Date(
			dateToGetMonth.getFullYear(),
			dateToGetMonth.getMonth(),
			1,
		)
			.toLocaleDateString('en-CA')
			.split('T')[0],
		lastDate: new Date(
			dateToGetMonth.getFullYear(),
			dateToGetMonth.getMonth() + 1,
			0,
		)
			.toLocaleDateString('en-CA')
			.split('T')[0],
	}
}

export function groupBy<K, V>(
	list: Array<V>,
	keyGetter: (input: V) => K,
): Map<K, Array<V>> {
	const map = new Map<K, Array<V>>()
	list.forEach(item => {
		const key = keyGetter(item)
		const collection = map.get(key)
		if (!collection) {
			map.set(key, [item])
		} else {
			collection.push(item)
		}
	})
	return map
}

export const getDayOfWeek = (number: number) => {
	return daysOfWeek[
		new Date(new Date().getFullYear(), new Date().getMonth(), number).getDay()
	]
}

export const getBookingAmountOfDays = (
	date: Date,
	start: string,
	end: string,
) => {
	const reservationInfo = getReservationInfo(start, end)
	const dateRange = getDateRange(date)

	const result: BookingDetailedInfo = {
		amountOfDays: reservationInfo,
		startedInPreviousMonth: false,
		endsInNextMonth: false,
		startDate: start,
		endDate: end,
	}
	if (new Date(start).getMonth() < date.getMonth()) {
		result.startedInPreviousMonth = true
		result.startDate = dateRange.firstDate
		result.amountOfDays = getReservationInfo(dateRange.firstDate, end)
	}

	if (new Date(end).getMonth() > date.getMonth()) {
		result.endsInNextMonth = true
		result.endDate = dateRange.lastDate
		result.amountOfDays = getReservationInfo(start, dateRange.lastDate)
	}
	return result
}

export const calcDayDifference = (startDate: Date, endDate: Date) => {
	const oneDay = 1000 * 60 * 60 * 24

	const start = Date.UTC(
		endDate.getFullYear(),
		endDate.getMonth(),
		endDate.getDate(),
	)
	const end = Date.UTC(
		startDate.getFullYear(),
		startDate.getMonth(),
		startDate.getDate(),
	)
	return (start - end) / oneDay
}

export const switchMonth = (date: Date, direction: -1 | 1): Date =>
	new Date(date.getFullYear(), date.getMonth() + direction, date.getDay())

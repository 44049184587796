import { schemas } from '@/api/types'
import { Link } from '@tanstack/react-location'
import { ProfilePackage } from 'components/partner/packages/Package'
import { ContentBody } from 'components/ui/Content/ContentBody'
import { ContentHeader } from 'components/ui/Content/ContentHeader'
import { FreeMode, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/keyboard'
import 'swiper/css/mousewheel'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import { Swiper, SwiperSlide } from 'swiper/react'

interface Props {
	packages: schemas['ListPackage'][]
}

export const ProfilePackages = ({ packages }: Props) => {
	return (
		<>
			<div className='block mx-auto w-3/4 max-w-5xl mb-10'>
				<ContentHeader className='flex w-full items-center justify-between py-5 mt-10'>
					<p className='font-bold text-2xl text-dark'>Пакеты</p>
					<Link
						to='add'
						className='border-[#21BCDE] rounded-xl font-medium bg-light-blue text-white py-2.5 px-4'
					>
						Добавить пакет
					</Link>
				</ContentHeader>
				<ContentBody>
					{packages?.length == 0 ? (
						<p className='px-16'>У вас нету еще пакетов</p>
					) : (
						<Swiper
							mousewheel={{ forceToAxis: false, invert: true }}
							modules={[FreeMode, Navigation]}
							navigation={true}
							slidesPerView={3}
							grabCursor={true}
							freeMode={true}
							className='noselect'
						>
							{packages?.map((_package, index) => (
								<SwiperSlide key={index} className='grow-0 !mr-4 block'>
									<ProfilePackage _package={_package} />
								</SwiperSlide>
							))}
						</Swiper>
					)}
				</ContentBody>
			</div>
		</>
	)
}

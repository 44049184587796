import { useNavigate } from '@tanstack/react-location'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getComforts } from 'api/comforts'
import { createNumber } from 'api/number'
import { addPhoto, deletePhoto } from 'api/number-photos'
import { getAllNumberTypes } from 'api/numberType'
import clsx from 'clsx'
import { AddService } from 'components/partner/numbers/AddService'
import { ServiceBadge } from 'components/partner/numbers/ServiceBadge'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ProfileInput } from 'components/profile/ProfileInput'
import { ProfileListBox } from 'components/profile/ProfileListBox'
import { ProfileTextarea } from 'components/profile/ProfileTextarea'
import Checkbox from 'components/ui/Checkbox'
import { ContentBody } from 'components/ui/Content/ContentBody'
import { ContentHeader } from 'components/ui/Content/ContentHeader'
import { Spinner } from 'components/ui/Spinner'
import upload from 'helpers/images/uploadMini.svg'
import uploadPhoto from 'helpers/images/uploadPhoto.svg'
import { ArrowDown2, ArrowUp2, Trash } from 'iconsax-react'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { ListItem, NumberFields, UploadImages } from 'static/interfaces'
import { useUserStore } from 'store/user'
import { formatBytes, removeSpaces } from 'utils/utils'

export const AddNumber = () => {

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm<NumberFields>()

  const queryClient = useQueryClient()
  const {mutate, isLoading: adding} = useMutation(createNumber)
  const navigate = useNavigate()
  const {user} = useUserStore()
  const {mutateAsync: add} = useMutation(addPhoto)
  const {mutate: _delete} = useMutation(deletePhoto)

  const {data: types, isLoading} = useQuery(['number', 'types'], () =>
    getAllNumberTypes(user?.partner?.tourId),
  )
  const {data: comforts} = useQuery(['comforts'], () => getComforts())

  const [_class, setClass] = useState<ListItem>()
  const [selectedFiles, setSelectedFiles] = useState<UploadImages[]>([])
  const [services, setServices] = useState<string[]>([])
  const [_comforts, setComforts] = useState<number[]>([])
  const [comfortsError, setComfortsError] = useState<string | null>(null)
  const [showCapacity, setShowCapacity] = useState(false)

  const addCapacity = () => setShowCapacity(true)
  const hideCapacity = () => setShowCapacity(false)

  const listboxFormat = types?.map(item => ({
    id: item.id,
    value: item.name,
  }))

  const onSubmit = (data: NumberFields) => {

    if (_comforts.length < 3) {
      setComfortsError('Выберите минимум 3 услуги')
      return
    }

    mutate(
      {
        tour: user?.partner?.tourId as number,
        title: data.title,
        price: data.price,
        number_services: services,
        place_count: data.place_count,
        type: _class?.id as number,
        remarks: data.remarks,
        ...(showCapacity ? {
          capacity: data.capacity,
          max_capacity: data.max_capacity,
          extra_capacity_price: data.extra_capacity_price,
          holiday_price: data.holiday_price
        } : {}),
        comforts: _comforts,
      },
      {
        onSuccess: data => {
          toast.success('Номер успешно создан')
          queryClient.refetchQueries([
            'place',
            'numbers',
            user?.partner?.tourId,
            user?.partner?.tourId,
          ])
          selectedFiles.forEach(file => {
            const formData = new FormData()
            formData.append('photo', file.file)
            formData.append('tour_number', `${data.id}`)
            add({formData})
              .then(result => {
                queryClient.refetchQueries([
                  'place',
                  'numbers',
                  user?.partner?.tourId,
                ])
                setSelectedFiles(prevFiles =>
                  prevFiles.map(file => {
                    if (
                      result.photo
                        .toLocaleLowerCase()
                        .includes(
                          file.file.name
                            .toLocaleLowerCase()
                            .replace(/\.[^/.]+$/, ''),
                        )
                    ) {
                      return {
                        ...file,
                        id: result.id,
                        status: 'uploaded',
                      }
                    }
                    return file
                  }),
                )
              })
              .catch(error => {
                toast.error('Что-то пошло не так, попробуйте еще раз')
              })
              .finally(() => {
                setSelectedFiles([])
              })
          })
          navigate({to: '/resort/numbers'})
          reset()
          setServices([])
        },
        onError: () => {
          toast.error('Ошибка при создании номера')
        },
      },
    )
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.map(file => {
      const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png']
      if (!acceptedImageTypes.includes(file['type'])) {
        toast.error('Выберите соответствующий тип файла')
        return
      } else if (file.size > 1048576 * 5) {
        toast.error('Файл слишком большой')
        return
      }
      setSelectedFiles(prevFiles => [
        ...prevFiles,
        {
          file,
          preview: URL.createObjectURL(file),
          status: 'uploaded',
          id: prevFiles.length + 1,
        },
      ])
    })
  }, [])

  const changeComfortError = (id: number) => {
    setComforts(prevComforts => {
      if (prevComforts.find(_comfort => _comfort === id)) {
        return prevComforts.filter(_comfort => id !== _comfort)
      } else {
        return [...prevComforts, id]
      }
    })
    setComfortsError(null)
  }

  const {getRootProps, getInputProps} = useDropzone({onDrop})
  useEffect(() => {
    return () =>
      selectedFiles.forEach(file => URL.revokeObjectURL(file.preview))
  }, [])

  if (isLoading) return <Spinner/>

  return (
    <div className="mx-auto w-5/12 my-10">
      <ContentHeader className="flex w-full items-center justify-between py-5">
        <p className="font-semibold text-2xl text-dark">
          Добавление номера
          <span className="text-gray text-sm font-normal inline-block ml-2">
						Для отмены перейдите к предыдущей вкладке
					</span>
        </p>
      </ContentHeader>
      <ContentBody>
        <div
          {...getRootProps({className: 'dropzone'})}
          className="flex flex-col items-center justify-center border-dashed	border-2 rounded-lg border-[#D7D7D7] py-10 mb-5 cursor-pointer"
        >
          <img src={uploadPhoto} width={'64'} height={'64'} alt=""/>
          <p className="text-center mt-5">
            Перетащите изображения или нажмите загрузить <br/>
            <span>JPG, PNG, JPEG</span>
          </p>
          <input
            id="file"
            type="file"
            className="input-zone"
            name="theFile"
            {...getInputProps()}
          />
        </div>
        <div className="flex gap-5 flex-wrap">
          <div className="">
            <label
              htmlFor="file"
              className="border-2 border-light-green rounded-xl w-[120px] h-20 flex flex-col items-center justify-center"
            >
              <img alt="" src={upload} width="24" height="24"/>
              <span className="text-sm text-dark">Загрузить</span>
            </label>
            <span className="flex flex-col">
							<span className="font-bold text-xs text-dark mt-2 mb-1">
								Jpg, Png, Gif
							</span>
							<span className="text-xs text-[#5C5C5C]">Макс. размер 5Мб. </span>
						</span>
          </div>
          {selectedFiles?.map((selectedFile, index) => (
            <div
              className="relative w-[120px] group"
              key={selectedFile.file.name}
            >
							<span
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}
                className="transition-all duration-200 ease-in-out group-hover:visible invisible flex group-hover:opacity-100 opacity-0 h-20 backdrop-blur-md rounded-xl absolute z-10 w-full items-center justify-center"
              >
								{selectedFile.status == 'loading' ? (
                  <svg
                    className={clsx(
                      'h-0 w-0 transition-all ease-out duration-300',
                      selectedFile.status === 'loading' ? 'h-max w-[25px]' : '',
                    )}
                    version="1.1"
                    id="L9"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 100 100"
                    enableBackground="new 0 0 0 0"
                    xmlSpace="preserve"
                  >
                    <path
                      fill="#FFFFFF"
                      d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                    >
                      <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        dur="1s"
                        from="0 50 50"
                        to="360 50 50"
                        repeatCount="indefinite"
                      />
                    </path>
                  </svg>
                ) : (
                  <Trash
                    onClick={() => {
                      setSelectedFiles(prev =>
                        prev.filter((_, i) => i !== index),
                      )
                    }}
                    className="bg-[#FF3030] w-8 p-[6px] h-8 flex items-center justify-center rounded-xl cursor-pointer"
                    size="20"
                    color="#FFFFFF"
                  />
                )}
							</span>
              <span className="relative w-[120px] h-[80px] block rounded-xl">
								<img
                  className="rounded-xl w-[120px] h-[80px]"
                  width="120"
                  height="80"
                  src={selectedFile.preview}
                  alt="Загруженная фотография"
                />
							</span>
              <span className="flex flex-col">
								<span className="font-bold text-xs text-dark truncate w-[120px] inline-block mt-2 mb-1">
									{selectedFile.status !== 'loading'
                    ? selectedFile.file.name
                    : 'Загрузка...'}
								</span>
								<span className="text-xs text-[#5C5C5C]">
									{formatBytes(selectedFile.file.size)}
								</span>
							</span>
            </div>
          ))}
        </div>
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap justify-between gap-y-4 mt-5">
            <ProfileInput
              label="Введите название номера"
              placeholder="Душ Шарко"
              type="text"
              className="block basis-[calc(50%_-_16px)]"
              error={errors.title?.message}
              {...register('title', {
                required: 'Заполните поле',
                validate: {
                  noSpaces: value =>
                    removeSpaces(value) ||
                    'Поле не должно начинаться или заканчиваться пробелами',
                },
              })}
            />

            <ProfileInput
              {...register('price', {
                required: 'Заполните поле',
                min: {value: 1, message: 'Минимальная цена 1 тг'},
                max: {
                  value: 1000000,
                  message: 'Максимальная цена 1000000 тг',
                },
                validate: {
                  noSpaces: value =>
                    removeSpaces(value.toString()) ||
                    'Поле не должно начинаться или заканчиваться пробелами',
                  onlyNumbers: value =>
                    /^\d+$/.test(value.toString()) ||
                    'Поле должно содержать только числа',
                },
              })}
              label="Укажите цену номера"
              error={errors.price?.message}
              placeholder="5000 тг"
              type="number"
              className="block basis-[calc(50%_-_16px)]"
            />
            <ProfileListBox
              defaultChosen={true}
              label="Тип номера"
              list={listboxFormat as ListItem[]}
              width="w-full"
              onChange={option => {
                setClass(option)
              }}
              className="basis-[calc(50%_-_16px)]"
            />
            <ProfileInput
              {...register('place_count', {
                required: 'Заполните поле',
                validate: {
                  noSpaces: value =>
                    removeSpaces(value.toString()) ||
                    'Поле не должно начинаться или заканчиваться пробелами',
                  onlyNumbers: value =>
                    /^\d+$/.test(value.toString()) ||
                    'Поле должно содержать только числа',
                },
              })}
              error={errors.place_count?.message}
              label="Количество комнат"
              placeholder="12"
              type="number"
              className="basis-[calc(50%_-_16px)]"
            />
            {showCapacity &&
              <>
                <ProfileInput
                  {...register('capacity', {
                    required: 'Заполните поле',
                    max: {value: '20', message: 'Макс. вместимость 20'},
                    validate: {
                      noSpaces: value =>
                        removeSpaces(value.toString()) ||
                        'Поле не должно начинаться или заканчиваться пробелами',
                      onlyNumbers: value =>
                        /^\d+$/.test(value.toString()) ||
                        'Поле должно содержать только числа',
                    },
                  })}
                  error={errors.capacity?.message}
                  label="Вместимость"
                  placeholder="до 20"
                  type="number"
                  className="basis-[calc(50%_-_16px)]"
                />
                <ProfileInput
                  {...register('max_capacity', {
                    required: 'Заполните поле',
                    max: {value: '20', message: 'Макс. вместимость 20'},
                    validate: {
                      noSpaces: value =>
                        removeSpaces(value.toString()) ||
                        'Поле не должно начинаться или заканчиваться пробелами',
                      onlyNumbers: value =>
                        /^\d+$/.test(value.toString()) ||
                        'Поле должно содержать только числа',
                    },
                  })}
                  error={errors.max_capacity?.message}
                  label="Макс. вместимость"
                  placeholder="до 20"
                  type="number"
                  className="basis-[calc(50%_-_16px)]"
                />
                <ProfileInput
                  {...register('extra_capacity_price', {
                    min: {value: '1000', message: 'Минимальная цена 1000 тг'},
                    required: 'Заполните поле',
                    validate: {
                      noSpaces: value =>
                        removeSpaces(value.toString()) ||
                        'Поле не должно начинаться или заканчиваться пробелами',
                      onlyNumbers: value =>
                        /^\d+$/.test(value.toString()) ||
                        'Поле должно содержать только числа',
                    },
                  })}
                  error={errors.extra_capacity_price?.message}
                  label="Цена за доп. место"
                  placeholder="8000 тг"
                  type="number"
                  className="basis-[calc(50%_-_16px)]"
                />
                <ProfileInput
                  {...register('holiday_price', {
                    min: {value: '1000', message: 'Минимальная цена 1000 тг'},
                    required: 'Заполните поле',
                    validate: {
                      noSpaces: value =>
                        removeSpaces(value.toString()) ||
                        'Поле не должно начинаться или заканчиваться пробелами',
                      onlyNumbers: value =>
                        /^\d+$/.test(value.toString()) ||
                        'Поле должно содержать только числа',
                    },
                  })}
                  error={errors.holiday_price?.message}
                  label="Цена за выходной день"
                  placeholder="10000 тг"
                  type="number"
                  className="basis-[calc(50%_-_16px)]"
                />
              </>
            }
            {showCapacity
              ?
              <div className="flex flex-col w-full items-center sm:hidden hover:cursor-pointer" onClick={hideCapacity}>
                <ArrowUp2 size={22} color="#6b7280" className="mt-0.5"/>
                <span className="text-[#6B7280] text-base font-medium">Скрыть вместимость</span>
              </div>
              :
              <div className="flex flex-col w-full items-center sm:hidden hover:cursor-pointer" onClick={addCapacity}>
                <span className="text-[#6B7280] text-base font-medium">Добавить вместимость</span>
                <ArrowDown2 size={22} color="#6b7280" className="mt-0.5"/>
              </div>
            }
          </div>
          <AddService
            title="Доп. услуги"
            onAdd={(title, service) => {
              setServices(prevServices => [...prevServices, service])
            }}
          />
          <ServiceBadge
            badges={services}
            onDelete={chosenIndex => {
              setServices(prevServices =>
                prevServices.filter((service, index) => index !== chosenIndex),
              )
            }}
          />
          <div className="flex flex-wrap flex-row">
            {comforts?.map(comfort => (
              <div
                key={comfort.id}
                className="flex gap-x-2 items-center cursor-pointer basis-1/2 noselect"
                onClick={() =>
                  setComforts(prevComforts => {
                    if (
                      prevComforts.find(_comfort => _comfort === comfort.id)
                    ) {
                      return prevComforts.filter(
                        _comfort => comfort.id !== _comfort,
                      )
                    } else {
                      return [...prevComforts, comfort.id]
                    }
                  })
                }
              >
                <Checkbox
                  checked={
                    !!_comforts.find(_comfort => comfort.id === _comfort)
                  }
                  onChange={() => changeComfortError(comfort.id)}
                />
                {comfort.name}
              </div>
            ))}
            {comfortsError && (
              <div className="mt-2 text-red text-base font-normal">
                {comfortsError}
              </div>
            )}
          </div>

          <ProfileTextarea
            {...register('remarks')}
            label="Примечания"
            placeholder="1 место с подселением (1 кровать)"
            className="mb-5 mt-3"
          />
          <ProfileButton
            type="submit"
            className={clsx('mt-7 block mx-auto w-full', adding && 'bg-gray')}
            disabled={adding}
          >
            Добавить
          </ProfileButton>
        </form>
      </ContentBody>
    </div>
  )
}

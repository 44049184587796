import { useMutation } from '@tanstack/react-query'
import { addEmail, editEmail } from 'api/email'
import { partnerPatch } from 'api/partner'
import { addPhone, updatePhone } from 'api/phone'
import { schemas } from 'api/types'
import { EditPassword } from 'components/modal/edit/Password'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ProfileInput } from 'components/profile/ProfileInput'
import { ContentBody } from 'components/ui/Content/ContentBody'
import { ContentHeader } from 'components/ui/Content/ContentHeader'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useUserStore } from 'store/user'

interface Props {
	partner: schemas['Organization']
	user: schemas['User']
}

export const PartnerProfile = ({ partner }: Props) => {
	const { user, editUser } = useUserStore()
	// TODO: отрефакторить
	const [isChangePassword, setIsChangePassword] = useState(false)
	const editEmailMutation = useMutation(editEmail)
	const editPhoneMutation = useMutation(updatePhone)
	const createPhoneMutation = useMutation(addPhone)
	const createEmailMutation = useMutation(addEmail)
	const editOrgDetails = useMutation(partnerPatch)

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		getValues,
		setValue,
		watch,
	} = useForm<{ org_name: string; email: string; phone: string }>()

	const onSubmit = (data: {
		org_name: string
		email: string
		phone: string
	}) => {
		if (!user) return
		if (user?.email === undefined && data.email !== '') {
			createEmailMutation.mutate(
				{ email: data.email, user: partner.user },
				{
					onSuccess: response => {
						editUser({
							...user,
							email: { id: response.id, value: data.email },
						})
						toast.success('Email успешно добавлен')
					},
					onError: (error: any) => {
						for (const key in error.response.data) {
							toast.error(error.response.data[key])
						}
					},
				},
			)
		} else if (
			user.email !== undefined ||
			(user.email !== null && data.email !== '')
		) {
			if (data.phone === '') return
			editEmailMutation.mutate(
				{
					email: data.email,
					user: partner.user,
					emailId: user.email?.id as number,
				},
				{
					onSuccess: response => {
						if (!user.email) {
							return toast.error('Что-то пошло не так, попробуйте позднее')
						}
						editUser({
							...user,
							email: {
								id: user.email?.id,
								value: response.email as string,
							},
						})
						toast.success('Email успешно изменен')
					},
					onError: () => {
						toast.error('Ошибка при изменении email')
					},
				},
			)
		}
		if (
			(user.phone === undefined || user.phone === null) &&
			data.phone !== ''
		) {
			if (data.phone === '') return
			createPhoneMutation.mutate(
				{ phone: data.phone, user: partner.user },
				{
					onSuccess: response => {
						editUser({
							...user,
							phone: { id: response.id, value: data.email },
						})
						toast.success('Номер телефона успешно добавлен')
					},
					onError: () => {
						toast.error('Ошибка при добавлении номера телефона')
					},
				},
			)
		} else if (
			(user.phone !== undefined || user.phone !== null) &&
			data.phone !== ''
		) {
			editPhoneMutation.mutate(
				{
					phone: data.phone,
					user: partner.user,
					phoneId: user.phone?.id as number,
				},
				{
					onSuccess: response => {
						if (!user.phone) {
							return toast.error('Что-то пошло не так, попробуйте позднее')
						}
						editUser({
							...user,
							phone: {
								id: user.phone.id,
								value: response.phone,
							},
						})
						toast.success('Номер телефона успешно изменен')
					},
					onError: () => {
						toast.error('Ошибка при изменении номера телефона')
					},
				},
			)
		}

		if (data.org_name !== '' && user.partner?.id) {
			editOrgDetails.mutate(
				{ org_name: data.org_name, user: partner.user, id: partner.id },
				{
					onSuccess: response => {
						editUser({
							...user,
							partner: {
								...user.partner,
								orgName: data.org_name,
							},
						})
						toast.success('Название организации успешно изменено')
					},
					onError: () => {
						toast.error('Ошибка при изменении названия организации')
					},
				},
			)
		}
	}

	useEffect(() => {
		setValue('org_name', partner.org_name ?? '')
		setValue('email', user?.email?.value ?? '')
		setValue('phone', user?.phone?.value ?? '')
	}, [partner])

	return (
		<>
			<EditPassword
				isVisible={isChangePassword}
				onClose={() => setIsChangePassword(!isChangePassword)}
			/>
			<div className='block mx-auto w-7/12'>
				<ContentHeader className='flex w-full shadow-lg items-center justify-between py-5 mt-10'>
					<p className='font-bold text-2xl text-dark'>Личные данные</p>
					<ProfileButton onClick={() => setIsChangePassword(!isChangePassword)}>
						Изменить пароль
					</ProfileButton>
				</ContentHeader>
				<ContentBody className='py-16'>
					<form
						className='flex justify-around'
						onSubmit={handleSubmit(onSubmit)}
					>
						<div className='basis-3/12 flex justify-center'>
							<img
								width={220}
								className='rounded-full shadow-xl'
								height={220}
								style={{ maxHeight: '220px' }}
								src={`https://ui-avatars.com/api/?name=${partner.org_name}&color=21BCDE&background=fff&rounded=true&bold=true&font-size=0.6&size=128&format=svg`}
								alt={`${partner.org_name}}`}
							/>
						</div>
						<div className='basis-6/12'>
							<div className='flex gap-3 relative'>
								<ProfileInput
									label='Название организации'
									placeholder='ТОО Ptendec'
									className='w-full'
									type='text'
									{...register('org_name', {
										minLength: 1,
										maxLength: 50,
									})}
									error={errors.org_name?.message}
								/>
							</div>
							<div className='flex gap-3 my-6 relative'>
								<ProfileInput
									error={errors.email?.message}
									{...register('email', {
										pattern: {
											value:
												/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
											message: 'Неверный формат email',
										},
									})}
									label='Email'
									placeholder='info@example.com'
									className='w-full'
									type='text'
								/>
							</div>
							<div className='flex gap-3 my-6 relative'>
								<ProfileInput
									label='Телефон'
									placeholder='+77082827207'
									className='w-full'
									type='text'
									error={errors.phone?.message}
									{...register('phone', {
										pattern: {
											value:
												/^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
											message: 'Неверный формат номера',
										},
									})}
								/>
							</div>
							<div
								className={`flex justify-end md:justify-center gap-3 ${
									user?.email?.value !== watch().email ||
									user?.phone?.value !== watch().phone ||
									partner.org_name !== watch().org_name
										? 'block'
										: 'hidden'
								}`}
							>
								<ProfileButton
									type='button'
									className='mt-6 mr-4 '
									onClick={() => {
										setValue('org_name', partner.org_name ?? '')
										setValue('email', user?.email?.value ?? '')
										setValue('phone', user?.phone?.value ?? '')
									}}
								>
									Отменить
								</ProfileButton>
								<ProfileButton type='submit' className='mt-6 !bg-green '>
									Сохранить
								</ProfileButton>
							</div>
						</div>
					</form>
				</ContentBody>
			</div>
		</>
	)
}

import { ServiceFields } from '@/static/interfaces'
import { useNavigate } from '@tanstack/react-location'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createService } from 'api/paidServices'
import { schemas } from 'api/types'
import clsx from 'clsx'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ProfileInput } from 'components/profile/ProfileInput'
import { ProfileTextarea } from 'components/profile/ProfileTextarea'
import { ContentBody } from 'components/ui/Content/ContentBody'
import { ContentHeader } from 'components/ui/Content/ContentHeader'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useUserStore } from 'store/user'
import { removeSpaces } from 'utils/utils'

export const AddService = () => {
	const queryClient = useQueryClient()
	const { mutate: createServiceMutation, isLoading } =
		useMutation(createService)
	const navigate = useNavigate()
	const { user } = useUserStore()
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<ServiceFields>()

	const onSubmit = (data: ServiceFields) => {
		if (!user?.partner?.tourId) {
			toast.error('Что-то пошло не так, попробуйте позднее')
		}
		createServiceMutation(
			{
				tour: user?.partner?.tourId as number,
				name: data.title,
				price: Number(data.price),
				description: data.description,
			},
			{
				onSuccess: data => {
					toast.success('Услуга успешно создана')
					queryClient.cancelQueries(['organization-services'])

					const prevProfileServices = queryClient.getQueryData<
						schemas['TourPaidServices'][]
					>(['organization-services', user?.partner?.tourId])
					if (prevProfileServices) {
						queryClient.setQueryData(
							['organization-services', user?.partner?.tourId],
							[...prevProfileServices, data],
						)
					}
					reset()
				},
				onError: () => {
					toast.error('Не получилось внести изменения')
				},
				onSettled: () => {
					navigate({ to: '/resort/service' })
				},
			},
		)
	}

	return (
		<div className=' mx-auto w-5/12 my-10'>
			<ContentHeader className='flex w-full items-center justify-between py-5'>
				<p className='font-semibold text-2xl text-dark'>
					Добавить услуги
					<span className='text-gray text-sm font-normal inline-block ml-2'>
						Для отмены перейдите к предыдущей вкладке
					</span>
				</p>
			</ContentHeader>
			<ContentBody>
				<form onSubmit={handleSubmit(onSubmit)}>
					<ProfileInput
						label='Введите заголовок услуги'
						placeholder='Душ Шарко'
						type='text'
						className='block mx-auto mt-7 mb-5'
						error={errors.title?.message}
						{...register('title', {
							required: 'Заполните поле',
							validate: {
								noSpaces: value =>
									removeSpaces(value) ||
									'Поле не должно начинаться или заканчиваться пробелами',
							},
						})}
					/>
					<ProfileInput
						{...register('price', {
							required: 'Заполните поле',
							min: { value: 1, message: 'Минимальная цена 1 тг' },
							max: {
								value: 1000000,
								message: 'Максимальная цена 1000000 тг',
							},
							validate: {
								noSpaces: value =>
									removeSpaces(value) ||
									'Поле не должно начинаться или заканчиваться пробелами',
								onlyNumbers: value =>
									/^\d+$/.test(value.toString()) ||
									'Поле должно содержать только числа',
							},
						})}
						error={errors.price?.message}
						label='Укажите цену услуги'
						placeholder='5000 тг.'
						type='number'
						className='block mx-auto mb-5'
					/>
					<ProfileTextarea
						error={errors.description?.message}
						{...register('description', {
							required: 'Заполните поле',
							validate: {
								noSpaces: value =>
									removeSpaces(value) ||
									'Поле не должно начинаться или заканчиваться пробелами',
							},
						})}
						label='Введите описание услуги'
						placeholder='Душ Шарко – это метод гидротерапии, успешно применяемый в косметологических, лечебных и реабилитационных целях. '
						className='block mx-auto mb-5'
					/>
					<ProfileButton
						type='submit'
						className={clsx(
							'mt-7 block mx-auto w-full',
							isLoading && 'bg-gray',
						)}
						disabled={isLoading}
					>
						Сохранить
					</ProfileButton>
				</form>
			</ContentBody>
		</div>
	)
}

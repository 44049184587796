import { schemas } from 'api/types'
import clsx from 'clsx'
import { EditRoom } from 'components/modal/edit/Room'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { Edit2 } from 'iconsax-react'
import { memo, ReactNode, useState } from 'react'
import {
	getAmountOfDaysInMonth,
	getBookingAmountOfDays,
} from 'utils/bookingSystemUtils'
import { ReservationItem } from './ReservationItem'

interface Props {
	numberRooms?: schemas['NumberCabinets'][]
	date: Date
	bookings: schemas['Reservations'][]
	showInfoModal: (info: schemas['Reservations']) => void
	onDoubleClick: (
		numberRoom: schemas['NumberCabinets'],
		startDate: Date,
	) => void
}

export const BookingTable = memo(
	({ numberRooms, date, bookings, showInfoModal, onDoubleClick }: Props) => {
		const { width } = useWindowDimensions()
		const Table: ReactNode[][][] = []
		let i = 0

		const [isEdit, setIsEdit] = useState(false)
		const [room, setRoom] = useState(numberRooms?.[0].id)

		Table.push([])
		numberRooms?.forEach((numberRoom, numberIndex) => {
			Table[i].push([])
			for (let k = 0; k <= getAmountOfDaysInMonth(date); k++) {
				const result = bookings.find(booking => {

					const check = getBookingAmountOfDays(
						date,
						booking.reservations_start,
						booking.reservations_end,
					)

					if (
						booking.number == numberRoom.tour_number &&
						booking.number_cabinets == numberRoom.id
					) {
						if (k === 1 && check.startedInPreviousMonth) {
							return true
						} else if (
							new Date(check.startDate).getDate() + 1 == k &&
							!check.startedInPreviousMonth
						) {
							return true
						} else {
							return false
						}
					}
				})
				if (k == 0) {
					Table[i][numberIndex].push(
						<td
							key={`${k}${numberRoom.id}`}
							className={clsx(
								'border-2 w-48 h-12 text-center border-[#00ACDE] text-[#1A4F6E] font-medium relative',
							)}
						>
							<button
								className='absolute right-0 bottom-0'
								onClick={() => {
									setRoom(numberRoom.id)
									setIsEdit(true)
								}}
							>
								<Edit2 size='16' color='#114869' />
							</button>
							{numberRoom.humanize_name}
						</td>,
					)
				} else {
					Table[i][numberIndex].push(
						<td
							onDoubleClickCapture={() => {
								onDoubleClick(
									numberRoom,
									new Date(date.getFullYear(), date.getMonth(), k),
								)
							}}
							style={{
								width: `${
									(width - 208 - 100) / getAmountOfDaysInMonth(date)
								}px`,
								maxWidth: '40px',
							}}
							key={`${k}${numberRoom.id}`}
							className={clsx(
								'relative h-12 items-center border-[#00ACDE] text-[#1A4F6E] font-medium border-2',
							)}
						>
							{result && !result.is_deleted && (
								<ReservationItem
									date={date}
									key={result.id}
									info={result}
									onClick={info => {
										showInfoModal(info)
									}}
								/>
							)}
						</td>,
					)
				}
			}
		})
		i++
		return (
			<>
				<EditRoom
					isVisible={isEdit}
					onClose={() => setIsEdit(false)}
					id={room}
				/>
				{Table.map((type, index) => {
					return (
						<tbody key={index}>
							{type.map((rooms, index) => {
								return (
									<tr key={index}>
										{rooms.map((reservations, index) => {
											return <>{reservations}</>
										})}
									</tr>
								)
							})}
						</tbody>
					)
				})}
			</>
		)
	},
)

BookingTable.displayName = 'BookingTable'
/*
(prevProps, nextProps) => {
		if (
			JSON.stringify(prevProps.bookings) !== JSON.stringify(nextProps.bookings)
		) {
			return false
		} else if (prevProps.date !== nextProps.date) {
			return false
		} else if (
			JSON.stringify(prevProps.numberRooms) !==
			JSON.stringify(nextProps.numberRooms)
		) {
			return false
		} else {
			return true
		}
	},
*/

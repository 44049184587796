import { useLocation, useNavigate } from '@tanstack/react-location'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getAllMyNumbers } from 'api/ProfileService'
import { changePackage, getPackage } from 'api/package'
import { AddService } from 'components/partner/numbers/AddService'
import { ServiceBadge } from 'components/partner/numbers/ServiceBadge'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ProfileInput } from 'components/profile/ProfileInput'
import { ProfileListBox } from 'components/profile/ProfileListBox'
import { ProfileTextarea } from 'components/profile/ProfileTextarea'
import { ContentBody } from 'components/ui/Content/ContentBody'
import { ContentHeader } from 'components/ui/Content/ContentHeader'
import { Spinner } from 'components/ui/Spinner'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { ListItem, NumberFields } from 'static/interfaces'
import { useUserStore } from 'store/user'
import {useIdsStore} from 'store/idsInsideResort'

export const Package = () => {
	const { current } = useLocation()
	const id = current.search.id as string
	const { packageId } = useIdsStore()
	const queryClient = useQueryClient()
	const changePackageMutation = useMutation(changePackage)
	const { user } = useUserStore()
	const navigate = useNavigate()

	const {
		data: _package,
		isLoading,
		isError,
		refetch,
	} = useQuery(['package', packageId], () => getPackage(packageId))

	const {
		data: numbers,
		isLoading: numberLoading,
		isError: numberError,
	} = useQuery(['numbers', user?.partner?.tourId], () =>
		getAllMyNumbers(user?.partner?.tourId),
	)

	const [services, setServices] = useState<string[]>([])
	const [numberType, setPackageType] = useState<ListItem>()

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm<NumberFields>()

	const listboxFormat = numbers?.map(item => ({
		id: item.id,
		value: `${item.title}`,
	}))

	const onSubmit = (data: NumberFields) => {
		if (!_package) {
			toast.error('Произошла ошибка, попробуйте позднее')
			return
		}
		changePackageMutation.mutate(
			{
				id: _package?.id,
				tour: user?.partner?.tourId as number,
				title: data.title,
				price: data.price,
				package_services: services,
				remarks: data.remarks,
				number: numberType?.id as number,
			},
			{
				onSuccess: () => {
					toast.success('Пакет успешно отредактирован')
					queryClient.refetchQueries([
						'organization-packages',
						user?.partner?.tourId,
					])
					navigate({ to: '/resort/packages' })
				},
				onError: () => {
					toast.error('Не получилось внести изменения')
				},
			},
		)
	}

	useEffect(() => {
		refetch()
	}, [packageId])

	useEffect(() => {
		if (!_package) return
		setServices(_package?.package_services.map(item => item.title) ?? [])
		setValue('title', _package?.title)
		setValue('price', _package?.price)
		setValue('remarks', _package?.remarks ?? '')
	}, [_package])

	if (isLoading || numberLoading) return <Spinner />
	if (isError || numberError) return <>Error</>

	return (
		<div className='mx-auto w-5/12 my-10'>
			<ContentHeader className='flex w-full items-center justify-between py-5'>
				<p className='text-2xl  font-semibold text-dark text-center'>
					Редактировать пакет
				</p>
			</ContentHeader>
			<ContentBody>
				<form className='flex flex-col' onSubmit={handleSubmit(onSubmit)}>
					<ProfileInput
						label='Введите название пакета'
						placeholder='Душ Шарко'
						type='text'
						className='block w-full mx-auto mt-1 mb-5'
						error={errors.title?.message}
						{...register('title', {
							required: 'Заполните поле',
						})}
					/>

					<ProfileInput
						{...register('price', {
							required: 'Заполните поле',
							min: { value: 1, message: 'Минимальная цена 1 тг' },
							max: {
								value: 1000000,
								message: 'Максимальная цена 1000000 тг',
							},
						})}
						error={errors.price?.message}
						label='Укажите цену пакета'
						placeholder='5000 .'
						type='number'
						className='block w-full mx-auto mb-5'
					/>
					<ProfileListBox
						label='Тип номера'
						list={listboxFormat as ListItem[]}
						width='w-full'
						defaultChosen={true}
						chosenItem={listboxFormat?.find(
							item => item.id == _package.number.id,
						)}
						onChange={option => {
							setPackageType(option)
						}}
						className='mb-5'
					/>
					<ProfileTextarea
						{...register('remarks')}
						label='Примечания'
						placeholder='1 место с подселением (1 кровать)'
						className='mb-5'
					/>
					<AddService
						title='Доп. услуги'
						onAdd={(title, service) => {
							setServices(prevServices => [...prevServices, service])
						}}
					/>
					<ServiceBadge
						badges={services}
						onDelete={chosenIndex => {
							setServices(prevServices =>
								prevServices.filter((service, index) => index !== chosenIndex),
							)
						}}
					/>
					<ProfileButton type='submit' className=' block mx-auto w-full'>
						Подтвердить
					</ProfileButton>
				</form>
			</ContentBody>
		</div>
	)
}

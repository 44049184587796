import { $authHost } from '.'
import { schemas } from './types'

export const getAllTourAdditionalInfoTitles = async (
	requestData: number | undefined,
): Promise<schemas['AdditionalTitles'][]> => {
	const { data } = await $authHost.get(
		`/v1/additionalInfo/title/?tour_id=${requestData}`,
	)
	return data
}

export const getTourTitles = async (
	requestData: number | undefined,
): Promise<schemas['AdditonalInfoTitle'][]> => {
	const { data } = await $authHost.get(
		`/v1/additionalInfo/tourTitle/?tour_id=${requestData}`,
	)
	return data
}

export const addForTourAdditionalInfoTitle = async (requestData: {
	tour: number | undefined
	title: number | undefined
}) => {
	const { data } = await $authHost.post('/v1/additionalInfo/tourTitle/', {
		tour: requestData.tour,
		title: requestData.title,
	})
	return data
}

export const deleteForTourAdditionalInfoTitle = async (requestData: {
	title: number | undefined
}) => {
	const { data } = await $authHost.delete(
		`/v1/additionalInfo/tourTitle/${requestData.title}/`,
	)
	return data
}

export const getTitleAdditionalServices = async (requestData: number) => {
	const { data } = await $authHost.get(
		`/v1/additionalInfo/Services/?title_id=${requestData}`,
	)

	return data
}

export const getTitleById = async (
	tourId: number | undefined,
	titleId: number | undefined,
) => {
	const { data } = await $authHost.get(
		`/v1/additionalInfo/tourTitle/${titleId}`,
	)
	return data
}

export const createTitleAdditionalService = async (
	requestData: schemas['AdditionalInfoServicesRequest'],
) => {
	const { data } = await $authHost.post(
		'/v1/additionalInfo/Services/',
		requestData,
	)
	return data
}

export const deleteTitleAdditionalService = async (
	requestData: schemas['AdditionalInfoServices']['id'],
) => {
	const { data } = await $authHost.delete(
		`/v1/additionalInfo/Services/${requestData}`,
	)
	return data
}

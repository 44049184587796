import { $authHost } from '.'
import { schemas } from './types'

export const addEmail = async (
	requestData: schemas['EmailAddressRequest'],
): Promise<schemas['EmailAddress']> => {
	const { data } = await $authHost.post('/v1/email/', requestData)
	return data
}

export const editEmail = async (
	requestData: schemas['EmailAddressRequest'] & { emailId: number },
): Promise<schemas['PatchedEmailAddressRequest']> => {
	const { data } = await $authHost.put(
		`/v1/email/${requestData.emailId}/`,
		requestData,
	)
	return data
}

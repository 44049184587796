import { AxiosResponse } from 'axios'
import { $authHost } from '.'
import { schemas } from './types'

export const getNumberRoomsByTourId = async (
	tourId: number | string | undefined,
): Promise<schemas['NumberCabinets'][]> => {
	const { data } =
		await $authHost.get(`/v1/number-cabinets/?tour_number__tour__id=
${tourId}`)
	return data
}

export const getNumberRoomsByNumberId = async (
	tourId: number | string | undefined,
): Promise<schemas['NumberCabinets'][]> => {
	const { data } = await $authHost.get(`/v1/number-cabinets/?tour_number_id=
${tourId}`)
	return data
}

export const getRoom = async (
	id: number | string | undefined,
): Promise<schemas['NumberCabinets']> => {
	const { data } = await $authHost.get(`/v1/number-cabinets/${id}/`)
	return data
}

export const changeNumberCabinet = async (
	request: schemas['PatchedNumberCabinetsRequest'] & { id?: number | string },
): Promise<AxiosResponse<schemas['NumberCabinets']>> => {
	return await $authHost.patch(`/v1/number-cabinets/${request.id}/`, request)
}

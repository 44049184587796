import { ReactLocation, Router } from '@tanstack/react-location'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import { routes } from './router'

const client = new QueryClient()
const location = new ReactLocation()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
	<Router location={location} routes={routes}>
		<QueryClientProvider client={client}>
			<App />
		</QueryClientProvider>
	</Router>,
)

import {Navigate, Route} from '@tanstack/react-location'
import {AddService} from 'modules/pages/services/Add'
import {EditService} from 'modules/pages/services/Edit'
import {AddAdditionalServiceContentPage} from 'pages/additionalService/AddAdditionalServiceContentPage'
import {BookingPage} from 'pages/booking/BookingPage'
import {ChangePhoto} from 'pages/changePhoto/changePhotoPage'
import {EditGuide} from 'pages/guide/Edit'
import {GuideProgramsPage} from 'pages/guide/GuideProgramsPage'
import {Add} from 'pages/guide/Program/Add'
import {Edit} from 'pages/guide/Program/Edit'
import {AddNumber} from 'pages/numbers/Add'
import {EditNumber} from 'pages/numbers/Edit'
import {RoomsList} from 'pages/numbers/RoomListPage'
import {OrganizationPage} from 'pages/organization/OrganizationPage'
import {PackagePage} from 'pages/package/PackagePage'
import {SettingsPage} from 'pages/settings/SettingsPage'
import {SubscriptionCardListPage} from 'pages/subscriptionCardList/SubscriptionCardListPage'
import {AddGuide} from './pages/addGuide/Guide'
import {AddTour} from './pages/addOrganization/Tour'
import {ApplicationPage} from './pages/application/ApplicationPage'
import {LoginPage} from './pages/authorization/LoginPage'
import PartnerPage from './pages/partner/PartnerPage'
import {RegistrationPage} from './pages/registration/RegistrationPage'
import {ServicesPage} from './pages/services/ServicesPage'
import {AddPackage} from './pages/package/Add'
import {Package} from './pages/package/Edit'
import {EducationPage} from './pages/education/Education'
import ErrorPage from './pages/404'

export const routes: Route[] = [
    {
        path: '/resort',
        children: [
            {
                path: '/organization',
                children: [
                    {
                        path: '/',
                        element: <OrganizationPage/>
                    },
                    // {
                    //     path: '*',
                    //     element: <ErrorPage/>,
                    // }
                ]
            },
            {
                path: '/service',
                children: [
                    // {
                    //     path: '*',
                    //     element: <ErrorPage/>,
                    // },
                    {
                        path: '/',
                        element: <ServicesPage/>,
                    },
                    {
                        path: '/edit',
                        element: <EditService/>,
                    },
                    {
                        path: '/add',
                        element: <AddService/>,
                    },
                ],
            },
            {
                path: '/photos',
                children: [
                    {
                        path: '/',
                        element: <ChangePhoto/>
                    },
                    // {
                    //     path: '*',
                    //     element: <ErrorPage/>,
                    // }
                ]
            },
            {
                path: '/numbers',
                children: [
                    // {
                    //     path: '*',
                    //     element: <ErrorPage/>,
                    // },
                    {
                        path: '/',
                        element: <RoomsList/>,
                    },
                    {
                        path: '/add',
                        element: <AddNumber/>,
                    },
                    {
                        path: '/edit',
                        element: <EditNumber/>,
                    },
                ],
            },
            {
                path: '/packages',
                children: [
                    // {
                    //     path: '*',
                    //     element: <ErrorPage/>,
                    // },
                    {
                        path: '/',
                        element: <PackagePage/>
                    },
                    {
                        path: '/add',
                        element: <AddPackage/>
                    },
                    {
                        path: '/edit',
                        element: <Package/>
                    }
                ]
            },
            {
                path: '/booking',
                children: [
                    {
                        path: '/',
                        element: <BookingPage/>
                    },
                    // {
                    //     path: '*',
                    //     element: <ErrorPage/>,
                    // }
                ]
            },
            {
                path: '/applications',
                children: [
                    {
                        path: '/',
                        element: <ApplicationPage/>
                    },
                    // {
                    //     path: '*',
                    //     element: <ErrorPage/>,
                    // }
                ]
            },
            {
                path: '/additional-services',
                children: [
                    {
                        path: '/',
                        element: <AddAdditionalServiceContentPage/>
                    },
                    // {
                    //     path: '*',
                    //     element: <ErrorPage/>,
                    // }
                ]
            },
            // {
            // 	path: '/subscriptions',
            // 	element: <SubscriptionCardListPage />,
            // },
        ],
    },
    {
        path: '/registration',
        children: [
            {
                path: '/',
                element: <RegistrationPage/>
            },
            // {
            //     path: '*',
            //     element: <ErrorPage/>,
            // }
        ]
    },
    {
        path: '/authorization',
        children: [
            {
                path: '/',
                element: <LoginPage/>
            },
            // {
            //     path: '*',
            //     element: <ErrorPage/>,
            // }
        ]
    },
    {
        path: '/add-organization',
        children: [
            {
                path: '/',
                element: <AddTour/>
            },
            // {
            //     path: '*',
            //     element: <ErrorPage/>,
            // }
        ]
    },
    {
        path: '/add-guide',
        children: [
            {
                path: '/',
                element: <AddGuide/>
            },
            // {
            //     path: '*',
            //     element: <ErrorPage/>,
            // }
        ]
    },
    {
        path: '/',
        element: <PartnerPage/>,
    },
    {
        path: '/education',
        children: [
            {
                path: '/',
                element: <EducationPage/>
            },
            // {
            //     path: '*',
            //     element: <ErrorPage/>,
            // }
        ]
    },
    {
        path: '/guide',
        children: [
            {
                path: '/program',
                children: [
                    // {
                    //     path: '*',
                    //     element: <ErrorPage/>,
                    // },
                    {
                        path: '/',
                        element: <GuideProgramsPage/>,
                    },
                    {
                        path: '/add',
                        element: <Add/>,
                    },
                    {
                        path: '/edit',
                        element: <Edit/>,
                    },
                ],
            },
            {
                path: '/organization',
                children: [
                    {
                        path: '/',
                        element: <EditGuide/>
                    },
                    // {
                    //     path: '*',
                    //     element: <ErrorPage/>,
                    // }
                ]
            },
        ],
    },
    {
        path: '/settings',
        children: [
            {
                path: '/',
                element: <SettingsPage/>,
            },
            // {
            //     path: '*',
            //     element: <ErrorPage/>,
            // },
        ]
    },
]

import React, { FC, useState } from 'react'
import { Helmet } from 'react-helmet'
import { ContentHeader } from 'components/ui/Content/ContentHeader'
import { ContentBody } from 'components/ui/Content/ContentBody'
import { Course } from '../../modules/pages/education/Course'
import kzTourism from 'helpers/images/kzTourism.svg'
import kta from 'helpers/images/kta.svg'
import { Choice } from '../../components/modal/other/Choice'

export const EducationPage: FC = () => {

  const [isAddChoiceVisible, setIsAddChoiceVisible] = useState(false)

  return (
    <React.Fragment>
      <Helmet>
        <title>Страница образования | MTour</title>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <Choice isVisible={isAddChoiceVisible} onClose={() => setIsAddChoiceVisible(false)}/>
      <div className="block mx-auto w-3/4 max-w-5xl">
        <ContentHeader className="flex w-full shadow-lg items-center justify-between py-5 mt-10">
          <p className="font-medium text-2xl text-dark">Образовательные курсы</p>
        </ContentHeader>
        <ContentBody>
          <div className="flex flex-col gap-y-5">
            <Course
              description="АО “НК Kazakh Tourism”"
              icon={kzTourism}
              bgColor="bg-[#FFF5E9]"
              hint="Переход на сайт"
              button="Перейти"
              onClick={() => window.open('https://academy.tourismonline.kz', '_blank')}
            />
            <Course
              description='Казахстанская туристская ассоциация Республики Казахстан'
              icon={kta}
              bgColor="bg-[#EFFFF3]"
              hint="Скачивание файла"
              button="Скачать"
              onClick={() => setIsAddChoiceVisible(true)}
            />
          </div>
        </ContentBody>
      </div>
    </React.Fragment>
  )
}
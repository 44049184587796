import { Link } from '@tanstack/react-location'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteService } from 'api/paidServices'
import { schemas } from 'api/types'
import { Confirm } from 'components/modal/delete/Confirm'
import { InfoCircle } from 'iconsax-react'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useUserStore } from 'store/user'
import {useIdsStore} from 'store/idsInsideResort'
interface Props {
	service: schemas['TourPaidServices']
	onInfo: (service: schemas['TourPaidServices']) => void
}

export const Service = ({ service, onInfo }: Props) => {
	const queryClient = useQueryClient()
	const { user } = useUserStore()
	const { setServiceId } = useIdsStore()
	const { mutate } = useMutation(deleteService)
	const _deleteService = () => {
		mutate(service.id, {
			onSuccess: () => {
				toast.success('Услуга успешно удалена')
				queryClient.cancelQueries(['organization-services'])
				const prevProfileServices = queryClient.getQueryData<
					schemas['TourPaidServices'][]
					>(['organization-services', user?.partner?.tourId])
				if (prevProfileServices) {
					queryClient.setQueryData(
						['organization-services', user?.partner?.tourId],
						[...prevProfileServices.filter(t => t.id !== service.id)],
					)
				}
				queryClient.refetchQueries(['place', 'services', user?.partner?.tourId])
			},
			onError: () => {
				toast.error('Произошла ошибка попробуйте позднее')
			},
		})
	}

	const [isConfirmVisible, setIsConfirmVisible] = useState(false)

	return (
		<>
			<Confirm
				isVisible={isConfirmVisible}
				onClose={() => setIsConfirmVisible(false)}
				requestFunction={_deleteService}
				requestArg={service.id}
			/>
			<div className='flex flex-col gap-5 items-center rounded-xl shadow-xl py-5 px-5 relative'>
				<button
					className='absolute right-3 top-5 '
					onClick={() => onInfo(service)}
				>
					<InfoCircle size='24' color='#008CAB' />
				</button>
				<h6 className='text-[#444444] font-semibold text-lg text-center text-ellipsis overflow-hidden max-w-full'>
					{service.name}
				</h6>
				<p className='bg-gradient-to-r from-start-gradient to-end-gradient text-dark-blue bg-clip-text text-xl font-semibold'>
					{service.price} ₸
				</p>
				<div className='flex flex-row mt-auto font-medium'>
					<Link
						onClick={() => setServiceId(service.id)}
						className='border-[#21BCDE] rounded-xl text-dark-blue hover:bg-dark-blue/[.1] p-2 transition-all duration-200 ease-in-out'
						to={`/resort/service/edit?id=${service.id}`}
					>
						Редактировать
					</Link>
					<button
						onClick={() => setIsConfirmVisible(true)}
						className='border-[#21BCDE] rounded-xl text-red hover:bg-dark-blue/[.1] p-2 transition-all duration-200 ease-in-out'
					>
						Удалить
					</button>
				</div>
			</div>
		</>
	)
}

import { ProfileButton } from 'components/profile/ProfileButton'
import { ProfileInput } from 'components/profile/ProfileInput'
import { useState } from 'react'

interface Props {
	title: string
	onAdd: (title: string, service: string) => void
}

interface Service {
	service: string
}

export const AddService = ({ title, onAdd }: Props) => {
	const [value, setValue] = useState('')

	return (
		<div className='flex gap-x-4 w-full'>
			<ProfileInput
				onSubmit={() => {
					console.log('onSubmit')
				}}
				label='Услуги'
				value={value}
				onChange={event => setValue(event.currentTarget.value)}
				onKeyDown={event => {
					event.stopPropagation()
					if (event.key === 'Enter') {
						if (value !== '') {
							event.preventDefault()
							onAdd(title, value)
							setValue('')
						}
					}
				}}
				type='text'
				placeholder='Введите название услуги'
				className=' w-full'
			/>

			<ProfileButton
				className='basis-3/12 self-end'
				type='button'
				onClick={() => {
					if (value.trim()) {
						onAdd(title, value)
						setValue('')
					}
				}}
			>
				Добавить
			</ProfileButton>
		</div>
	)
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteBooking, sendBookingCode } from 'api/crm'
import { schemas } from 'api/types'
import { CustomPopover } from 'components/popover/CustomPopover'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ArrowDown2 } from 'iconsax-react'
import { toast } from 'react-hot-toast'
import { useUserStore } from 'store/user'
import { Modal } from '../Modal'

interface Props {
  isVisible: boolean
  onClose: () => void
  openSubmitModal: () => void
  info?: schemas['Reservations']
}

export const ReservationDetail = ({
  isVisible,
  onClose,
  info,
  openSubmitModal
}: Props) => {

  const queryClient = useQueryClient()
  const {mutate: send} = useMutation(sendBookingCode)
  const {mutate} = useMutation(deleteBooking)
  const {user} = useUserStore()
  const eventDelete = () => {
    mutate(info?.id, {
      onSuccess: () => {
        toast.success('Успешно удалено')
        onClose()
        queryClient.refetchQueries(['bookings', user?.partner?.tourId])
      },
    })
  }

  const confirmBooking = (id: number | undefined) => {
    send(id, {
      onSuccess: () => {
        toast.success('Доставлено клиенту')
        queryClient.refetchQueries(['bookings', user?.partner?.tourId])
        onClose()
      },
      onError: () => {
        toast.error('Произошла ошибка попробуйте позднее')
      }
    })
  }

  return (
    <Modal
      className="px-10 py-8 bg-white max-w-xl flex flex-col"
      isVisible={isVisible}
      onClose={onClose}
    >
      <div className="py-2 font-semibold text-[#1A4F6E] flex flex-row gap-4 text-lg items-center">
        <h4>Клиент</h4>
        <p>№{info?.id}</p>
      </div>
      <div className="border-b-2 border-b-[#1A4F6E26] pb-4 my-4">
        <div className="flex flex-col gap-3">
          <div className="flex flex-row">
            <p className="font-normal text-[#1A4F6EB2] w-28">Заказчик</p>
            <p className="font-medium	text-[#1A4F6E]">
              {info?.reservator
                ? info.reservator.partner
                  ? info.reservator.partner?.org_name
                  : `${info.reservator.client?.first_name} ${info.reservator.client?.last_name}`
                : info?.fullName}
            </p>
          </div>
          <div className="flex flex-row ">
            <p className="font-normal text-[#1A4F6EB2] w-28">Заезд</p>
            <p className="font-medium	text-[#1A4F6E]">
              {info?.reservations_start} <span className="ml-3">12:00:00</span>
            </p>
          </div>
          <div className="flex flex-row ">
            <p className="font-normal text-[#1A4F6EB2] w-28">Выезд</p>
            <p className="font-medium	text-[#1A4F6E]">
              {info?.reservations_end} <span className="ml-3">14:00:00</span>
            </p>
          </div>
          <div className="flex flex-row ">
            <p className="font-normal text-[#1A4F6EB2] w-28">Услуги</p>
            <p className="font-medium	text-[#1A4F6E]">
              <CustomPopover
                button={
                  <div className="w-20 flex justify-between items-center">
                    {info?.cart_services
                      ? info?.cart_services?.length
                      : info?.reservations_services?.length}{' '}
                    услуг
                    <ArrowDown2 size="16" color="#002C50"/>
                  </div>
                }
                panel={
                  <div>
                    {!info?.cart_services
                      ? info?.reservations_services?.map(service => (
                        <div key={service.service}>
                          {service.service_name}
                        </div>
                      ))
                      : info?.cart_services?.map(service => (
                        <div key={service.id}>{service.service.name}</div>
                      ))}
                  </div>
                }
              />
            </p>
          </div>
          <div className="flex flex-row ">
            <p className="font-normal text-[#1A4F6EB2] w-28">Email</p>
            <p className="font-medium	text-[#1A4F6E]">{info?.email}</p>
          </div>

          <div className="flex flex-row items-center">
            <p className="font-normal text-[#1A4F6EB2] w-28">Номер телефона</p>
            <p className="font-medium	text-[#1A4F6E]">{info?.phoneNumber}</p>
          </div>
          <div className="flex flex-row items-center">
            <p className="font-normal text-[#1A4F6EB2] w-28">Источник</p>
            <p className="font-medium	text-[#1A4F6E]">
              {info?.reservator ? 'mtour.kz' : 'Оффлайн'}
            </p>
          </div>
          <div className="flex flex-row items-center">
            <p className="font-normal text-[#1A4F6EB2] w-28">Примечания</p>
            <p className="font-medium	text-[#1A4F6E]">{info?.remarks}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-row text-base gap-6 mt-2">
        <div className="flex flex-col gap-3">
          <div className="flex flex-row">
            <p className="font-normal text-[#1A4F6EB2] w-28">Оплачено</p>
            <p className="font-medium	text-[#1A4F6E]">
              {info?.payment_amount ? info.payment_amount : info?.amount} ₸
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-2">
        {info?.approved_status === -1 &&
          <ProfileButton className="mt-4 self-end" onClick={() => confirmBooking(info?.id)}>
            Отправить код клиенту
          </ProfileButton>
        }
        {info?.approved_status === 0 &&
          <ProfileButton className="mt-4 self-end" onClick={() => openSubmitModal()}>
            Подтвердить бронирование
          </ProfileButton>
        }
        {info?.approved_status === 1 &&
          <ProfileButton className="mt-4 self-end bg-light-green">
            Бронь подтверждена
          </ProfileButton>
        }
        <ProfileButton className="mt-4 w-40 self-end" onClick={eventDelete}>
          Удалить
        </ProfileButton>
      </div>
    </Modal>
  )
}

import { schemas } from '@/api/types'
import { Link } from '@tanstack/react-location'
import { Service as Info } from 'components/modal/display/Service'
import { Service } from 'components/partner/services/Service'
import { ContentBody } from 'components/ui/Content/ContentBody'
import { ContentHeader } from 'components/ui/Content/ContentHeader'
import { useState } from 'react'

interface Props {
	services: schemas['TourPaidServices'][]
}

export const Services = ({ services }: Props) => {

	const [service, setService] = useState<
		schemas['TourPaidServices'] | undefined
	>(services[0])
	const [isDetail, setIsDetail] = useState(false)

	return (
		<>
			<Info
				isVisible={isDetail}
				onClose={() => {
					setIsDetail(false)
					setService(undefined)
				}}
				service={service}
			/>
			<div className='block mx-auto w-3/4 max-w-5xl mb-10'>
				<ContentHeader className='flex w-full items-center justify-between py-5 mt-10 '>
					<p className='font-bold text-2xl text-dark'>Услуги</p>
					<Link
						to='add'
						className='border-[#21BCDE] rounded-xl font-medium bg-light-blue text-white py-2.5 px-4'
					>
						Добавить услугу
					</Link>
				</ContentHeader>
				<ContentBody className='grid grid-cols-3 gap-y-8 gap-x-7'>
					{services?.length != 0 ? (
						services?.map(service => (
							<Service
								onInfo={service => {
									setService(service)
									setIsDetail(true)
								}}
								key={service.id}
								service={service}
							/>
						))
					) : (
						<p className='font-medium text-lg'>У вас еще нет услуг </p>
					)}
				</ContentBody>
			</div>
		</>
	)
}

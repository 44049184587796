import ru from 'date-fns/locale/ru'
import icon from 'helpers/images/Icon.svg'
import { ComponentPropsWithRef, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
registerLocale('ru', ru)

interface Props extends Omit<ComponentPropsWithRef<'div'>, 'onChange'> {
	onChange: (date: Date) => void
	className?: string
	label: string
}

export const PartnerDatePicker = ({ onChange, className }: Props) => {
	const [selectedDate, setSelectedDate] = useState(new Date())
	return (
		<div
			className={`h-full border-r-2 flex items-center border-[#21BCDE] , ${className}`}
		>
			<label className='flex px-6 shrink-0 items-center cursor-pointer'>
				<img alt='' src={icon} width='25' height='25' />
				<DatePicker
					className='hidden'
					dateFormat='d MMMM, yyyy'
					locale='ru'
					selected={selectedDate}
					onChange={(date: Date) => {
						onChange(date)
						setSelectedDate(date)
					}}
				/>
			</label>
		</div>
	)
}

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { editGuide, getGuide } from 'api/guide/guide'
import { addPhoto, deletePhoto, editPhoto, getMyPhotos } from 'api/guide/photos'
import { schemas } from 'api/types'
import clsx from 'clsx'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ProfileInput } from 'components/profile/ProfileInput'
import { ProfileTextarea } from 'components/profile/ProfileTextarea'
import { ContentBody } from 'components/ui/Content/ContentBody'
import { ContentHeader } from 'components/ui/Content/ContentHeader'
import { Spinner } from 'components/ui/Spinner'
import { Trash } from 'iconsax-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Map, Placemark, Polygon, YMaps } from 'react-yandex-maps'
import { GuideFields, UploadImages } from 'static'
import { useUserStore } from 'store/user'
import { getImageURL, removeSpaces } from 'utils/utils'
import { GuidePhotos } from '../../components/modal/edit/GuidePhotos'

export const EditGuide = () => {
  const queryClient = useQueryClient()
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<GuideFields>()
  const {user} = useUserStore()
  const {mutate} = useMutation(editGuide)
  const addPhotoMutation = useMutation(addPhoto)
  const {mutate: _delete, isLoading: isDeleting} = useMutation(deletePhoto)
  const {mutate: edit} = useMutation(editPhoto)

  const [selectedFiles, setSelectedFiles] = useState<UploadImages[]>([])
  const [draggedPhoto, setDraggedPhoto] = useState<schemas['GuideShots']>()
  const [coordinates, setCoordinates] = useState<number[]>([51.18, 71.45])
  const [isEditPhotos, setIsEditPhotos] = useState(false)

  const {data} = useQuery(['guide', user?.partner?.guide?.id], () =>
    getGuide(user?.partner?.guide?.id),
  )
  const {
    data: photos,
    isLoading,
    isError,
  } = useQuery(['guide-photos', user?.partner?.guide?.id], () =>
    getMyPhotos(user?.partner?.guide?.id),
  )

  useEffect(() => {
    if (data) {
      setValue('title', data.title)
      setValue('description', data.description ?? '')
      setValue('email', data.email ?? '')
      setValue('bik', data.BIK ?? '')
      setValue('iik', data.IIK ?? '')
      setValue('bin', data.BIN ?? '')
      setValue('workingTime', data.working_time ?? '')
      setValue('address', data.address ?? '')
      setCoordinates(
        data.lat && data.lon ? [data.lat, data.lon] : [51.18, 71.45],
      )
    }
  }, [data])

  const onSubmit = (data: GuideFields) => {
    if (!user?.partner?.id) {
      toast.error('Ошибка при создании гида')
      return
    }
    mutate(
      {
        id: user?.partner?.guide?.id,
        description: data.description,
        title: data.title,
        email: data.email,
        BIK: data.bik,
        IIK: data.iik,
        BIN: data.bin,
        currency: 1,
        org: user?.partner?.id,
        lat: coordinates[0],
        lon: coordinates[1],
        working_time: data.workingTime,
        address: data.address,
      },
      {
        onSuccess: response => {
          toast.success('Сохранено')
        },
        onError: (error: any) => {
          for (const key in error.response.data) {
            toast.error(error.response.data[key])
          }
        },
      },
    )
  }

  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    photo: schemas['GuideShots'],
  ) => {
    setDraggedPhoto(photo)
  }
  const onDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    event.currentTarget.style.transform = 'translateX(0px)'
  }
  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.currentTarget.style.transform = 'translateX(0px)'
  }
  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.currentTarget.style.transform = 'translateX(10px)'
  }

  const onDrop = (
    event: React.DragEvent<HTMLDivElement>,
    droppedOnPhoto: schemas['GuideShots'],
  ) => {
    if (!draggedPhoto || !photos) return
    event.preventDefault()
    queryClient.cancelQueries(['guide-photos', user?.partner?.guide?.id])
    queryClient.setQueryData(
      ['guide', user?.partner?.guide?.id],
      photos.map(photo => {
        if (photo.id === droppedOnPhoto?.id) {
          edit({id: droppedOnPhoto?.id, order: draggedPhoto?.order})
          return {...photo, order: draggedPhoto?.order}
        }

        if (photo.id === draggedPhoto?.id) {
          edit({id: draggedPhoto?.id, order: droppedOnPhoto?.order})
          return {...photo, order: droppedOnPhoto.order}
        }

        return photo
      }),
    )
    event.currentTarget.style.transform = 'translateX(0px)'
  }

  const removePhoto = (id: number | string | undefined) => {
    _delete(id, {
      onSuccess: () => {
        toast.success('Успешно удалено')
        queryClient.refetchQueries([
          'guide-photos',
          user?.partner?.tourId,
        ])
      },
      onError: () => {
        toast.error(
          'Произошла ошибка при удалении, попробуйте позднее',
        )
      },
    })
  }


  if (isLoading) return <Spinner/>
  if (isError) return <>Error</>

  return (
    <div className="mx-auto my-10">
      <GuidePhotos
        isVisible={isEditPhotos}
        onClose={() => {
          setIsEditPhotos(false)
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentHeader className="flex w-full items-center justify-between py-5">
          <p className="font-medium text-xl text-dark">Организация</p>
        </ContentHeader>
        <ContentBody className="flex flex-wrap flex-row gap-3 mb-10">
          <ProfileInput
            className="basis-[calc(50%-16px)]"
            error={errors.title?.message}
            label="Название Организации"
            placeholder="Ақ–Тілек"
            type="text"
            {...register('title', {
              required: 'Заполните поле',
              minLength: 3,
              validate: {
                noSpaces: value =>
                  removeSpaces(value) ||
                  'Поле не должно начинаться или заканчиваться пробелами',
              },
            })}
          />
          <ProfileInput
            className="basis-[calc(50%-16px)]"
            error={errors.bin?.message}
            {...register('bin', {
              required: 'Заполните поле',
              // pattern: {
              // 	value: /^[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}$/,
              // 	message: 'Введите бин в формате 0000-0000-0000-0000',
              // }
            })}
            label="БИН"
            placeholder="4444-4444-4444-4444"
            type="text"
          />
          <ProfileInput
            className="basis-[calc(50%-16px)]"
            error={errors.iik?.message}
            {...register('iik', {
              required: 'Заполните поле',
              pattern: {
                value:
                  /^([A-Z]{2}[-]?[0-9]{2})(?=(?:[-]?[A-Z0-9]){9,30}$)((?:[-]?[A-Z0-9]{3,5}){2,7})([-]?[A-Z0-9]{1,3})?$/,
                message: 'Введите ИИК в в верном формате',
              },
            })}
            label="ИИК (IBAN)"
            placeholder="KZ106010291000194146"
            type="text"
          />
          <ProfileInput
            className="basis-[calc(50%-16px)]"
            error={errors.bik?.message}
            {...register('bik', {
              required: 'Заполните поле',
            })}
            label="БИК"
            placeholder="HSBKKZKX"
            type="text"
            pattern="[A-Z0-9]{8}"
          />
        </ContentBody>
        <ContentHeader className="flex w-full items-center justify-between py-5">
          <p className="font-medium text-xl text-dark">Фотографии</p>
          <div className="flex flex-row gap-5">
            <ProfileButton
              type="button"
              onClick={() => {
                setIsEditPhotos(true)
              }}
            >
              Загрузить фотографию
            </ProfileButton>
          </div>
        </ContentHeader>
        <ContentBody className="flex flex-wrap flex-row gap-3 mb-10">
          {photos
            .sort((a, b) => a.order - b.order)
            .map(photo => (
              <div
                onDragStart={event => {
                  onDragStart(event, photo)
                }}
                onDragEnd={event => {
                  onDragEnd(event)
                }}
                onDragLeave={event => {
                  onDragLeave(event)
                }}
                onDragOver={event => {
                  onDragOver(event)
                }}
                onDrop={event => {
                  onDrop(event, photo)
                }}
                draggable
                key={photo.id}
                className="relative image-wrapper group transition-all duration-200 ease-in-out cursor-grab"
              >
								<span
                  style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  }}
                  className="transition-all duration-200 ease-in-out group-hover:visible invisible flex group-hover:opacity-100 opacity-0 backdrop-blur-md rounded-xl absolute z-10 w-full items-center justify-center h-full"
                >
									{isDeleting ? (
                    <svg
                      className={clsx(
                        'h-0 w-0 transition-all ease-out duration-300',
                        isDeleting ? 'h-max w-[25px]' : '',
                      )}
                      version="1.1"
                      id="L9"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 100 100"
                      enableBackground="new 0 0 0 0"
                      xmlSpace="preserve"
                    >
                      <path
                        fill="#FFFFFF"
                        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                      >
                        <animateTransform
                          attributeName="transform"
                          attributeType="XML"
                          type="rotate"
                          dur="1s"
                          from="0 50 50"
                          to="360 50 50"
                          repeatCount="indefinite"
                        />
                      </path>
                    </svg>
                  ) : (
                    <Trash
                      onClick={() => {
                        _delete(photo.id, {
                          onSuccess: () => {
                            toast.success('Успешно удалено')
                            queryClient.refetchQueries([
                              'guide-photos',
                              user?.partner?.guide?.id,
                            ])
                          },
                          onError: () => {
                            toast.error(
                              'Произошла ошибка при удалении, попробуйте позднее',
                            )
                          },
                        })
                      }}
                      className="bg-[#FF3030] w-10 p-[6px] h-10 flex items-center justify-center rounded-xl cursor-pointer"
                      size="20"
                      color="#FFFFFF"
                    />
                  )}
								</span>
                <span className="relative block rounded-xl">
									<img
                    className="rounded-xl w-[200px] h-[150px]"
                    src={getImageURL(photo.photo)}
                    alt="Загруженная фотография"
                  />
								</span>
              </div>
            ))}
        </ContentBody>
        <ContentHeader className="flex w-full items-center justify-between py-5">
          <p className="font-medium text-xl text-dark">Контактная информация</p>
        </ContentHeader>
        <ContentBody className="flex flex-wrap flex-row gap-3 mb-10">
          <ProfileInput
            className="basis-[calc(50%-16px)]"
            label="Адрес"
            placeholder="Казахстан, Алмата, Есиль, Туркестан, 10"
            {...register('address', {
              required: 'Заполните поле',
              minLength: 3,
              validate: {
                noSpaces: value =>
                  removeSpaces(value) ||
                  'Поле не должно начинаться или заканчиваться пробелами',
              },
            })}
            error={errors.address?.message}
          />
          <ProfileInput
            className="basis-[calc(50%-16px)]"
            label="График работы (Пишите через запятую)"
            placeholder="Пн - Вт: 9:00 - 18:00, Сб - Вс: Выходной"
            {...register('workingTime', {
              required: 'Заполните поле',
              minLength: 3,
              validate: {
                noSpaces: value =>
                  removeSpaces(value) ||
                  'Поле не должно начинаться или заканчиваться пробелами',
              },
            })}
            error={errors.workingTime?.message}
          />
          <ProfileInput
            className="basis-[calc(50%-16px)]"
            placeholder="info@example.com"
            label="Email"
            {...register('email', {
              required: 'Заполните поле',
              minLength: 3,
              validate: {
                noSpaces: value =>
                  removeSpaces(value) ||
                  'Поле не должно начинаться или заканчиваться пробелами',
              },
            })}
            error={errors.email?.message}
          />
        </ContentBody>
        <ContentHeader className="flex w-full items-center justify-between py-5">
          <p className="font-medium text-xl text-dark">Описание</p>
        </ContentHeader>
        <ContentBody className="flex flex-wrap flex-row gap-3 mb-10">
          <ProfileTextarea
            rows={5}
            className="w-full resize-none"
            placeholder="Лечебно-оздоровительный комплекс или просто санаторий Ок-Жетпес расположен на берегу озера Боровое, немного в стороне от шумного курортного посёлка Бурабай. Здесь в тишине и покое его гости могут не только прекрасно отдохнуть, но и поправить своё здоровье."
            {...register('description', {
              required: 'Заполните поле',
              validate: {
                noSpaces: value =>
                  removeSpaces(value) ||
                  'Поле не должно начинаться или заканчиваться пробелами',
              },
            })}
            error={errors.description?.message}
          />
          <span>Напишите 500 символов</span>
        </ContentBody>
        <ContentHeader className="flex w-full items-center justify-between py-5">
          <p className="font-medium text-xl text-dark">
            Карта
            <span className="text-gray text-sm inline-block ml-2">
							(Укажите на карте организацию)
						</span>
          </p>
        </ContentHeader>
        <ContentBody className="mb-10">
          <YMaps query={{apikey: 'f80bf96b-8fa3-4478-ac9a-39e597b9757a'}}>
            {/* <ReactNotification /> */}
            <Map
              // className='my-10'
              modules={['geocode']}
              defaultState={{center: [51.18, 71.45], zoom: 9}}
              width={'100%'}
              height={'250px'}
              onClick={(event: any) => {
                setCoordinates(event.get('coords'))
              }}
            >
              <Polygon options={{visible: false}}/>
              {coordinates && <Placemark geometry={coordinates}/>}
            </Map>
          </YMaps>
        </ContentBody>
        <div className="flex justify-end">
          <ProfileButton type="submit" className="mt-6">
            Сохранить
          </ProfileButton>
        </div>
      </form>
    </div>
  )
}

import { $authHost } from '.'
import { schemas } from './types'
import { AxiosResponse } from 'axios'

type OrgEdit = schemas['PatchedOrganizationRequest'] & { id: number }

export const getPartner = async (
	id: string | number | undefined,
): Promise<AxiosResponse<schemas['Organization']>> => {
	const response = await $authHost.get(`/v1/partners/${id}/`)
	return response
}

export const partnerPatch = async (
	request: OrgEdit,
): Promise<schemas['Organization']> => {
	const { data } = await $authHost.patch(`/v1/partners/${request.id}/`, request)
	return data
}

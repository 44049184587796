import React from 'react'
import { Modal } from '../Modal'
import { ProfileButton } from '../../profile/ProfileButton'

interface CourseProps {
  isVisible: boolean
  onClose: () => void
}

export const Choice: React.FC<CourseProps> = ({isVisible, onClose}: CourseProps) => {

  const donwloadRU = () => {
    window.open('https://cloud.mail.ru/public/NDmt/HFyViek1N', '_blank')
    onClose()
  }

  const donwloadKZ = () => {
    window.open('https://cloud.mail.ru/public/DGaQ/prUsYUcYj', '_blank')
    onClose()
  }

  return (
    <Modal isVisible={isVisible} onClose={onClose} className='max-w-xl p-8'>
      <div>
        <p className='text-dark-blue font-medium text-2xl text-center'>
          Выберите язык для скачивания
        </p>
        <div className='flex justify-center gap-x-20'>
          <ProfileButton
            type='button'
            onClick={donwloadRU}
            className='mt-6 mr-4 bg-blue'
          >
            Русский
          </ProfileButton>
          <ProfileButton
            type='button'
            className='mt-6 mr-4 bg-blue'
            onClick={donwloadKZ}
          >
            Казахский
          </ProfileButton>
        </div>
      </div>
    </Modal>
  )
}

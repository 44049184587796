import { schemas } from '@/api/types'
import { ProfileButton } from 'components/profile/ProfileButton'
import { useState } from 'react'

interface Props {
	title: schemas['AdditionalTitles']
	onAdd: (title: number, service: string) => void
}

export const AddAdditionalServiceRow = ({ title, onAdd }: Props) => {
	const [service, setService] = useState('')

	const buttonHandler = () => {
		if (service.trim()) {
			onAdd(title.id, service)
		}
	}

	const labelToShow = ['Персонал говорит', 'Принимаемые кредитные карты', 'Удобства']

	return (
		<div className='flex flex-col gap-4'>
			{labelToShow.includes(title.name) && <label className='text-sm text-[#002C50] font-medium'>{title.name}</label>}
			<div className='flex flex-row gap-4'>
				<input
					onKeyDown={event => {
						if (event.key === 'Enter') {
							event.preventDefault()
							onAdd(title.id, service)
							setService('')
						}
					}}
					placeholder={title.name}
					value={service}
					onChange={event => setService(event.target.value)}
					className='border-2 border-[#D0D5DD] p-2.5 outline-0 rounded-lg w-1/2'
				/>
				<ProfileButton onClick={buttonHandler}>Добавить</ProfileButton>
			</div>
		</div>
	)
}

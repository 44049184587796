import { Popover, Transition } from '@headlessui/react'
import { Fragment, ReactNode } from 'react'

interface Props {
    button: ReactNode
    panel: ReactNode
}

export const CustomPopover = ({ button, panel }: Props) => {
    return (
        <div className='w-full px-4 bg-white'>
            <Popover className='relative'>
                {({ open }) => (
                    <>
                        <Popover.Button
                            className={`
                ${open ? '' : ''}
                group items-center rounded-md font-medium focus:outline-none`}
                        >
                            {button}
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter='transition ease-out duration-200'
                            enterFrom='opacity-0 translate-y-1'
                            enterTo='opacity-100 translate-y-0'
                            leave='transition ease-in duration-150'
                            leaveFrom='opacity-100 translate-y-0'
                            leaveTo='opacity-0 translate-y-1'
                        >
                            <Popover.Panel className='shadow-lg absolute bg-white w-52 p-4 rounded-xl z-10 mt-3 max-w-sm lg:max-w-3xl'>
                                {panel}
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
        </div>
    )
}

import { Link } from '@tanstack/react-location'
import { useQuery } from '@tanstack/react-query'
import { getAllMyNumbers } from 'api/ProfileService'
import { NumberDetails } from 'components/modal/display/Number'
import { ContentBody } from 'components/ui/Content/ContentBody'
import { ContentHeader } from 'components/ui/Content/ContentHeader'
import { Spinner } from 'components/ui/Spinner'
import { Number } from 'modules/pages/numberDetails/Number'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useUserStore } from 'store/user'
import {useIdsStore} from 'store/idsInsideResort'

export const RoomsList = () => {
	const [isAdd, setIsAdd] = useState(false)
	const [isEdit, setIsEdit] = useState(false)
	const [isDetail, setIsDetail] = useState(false)
	const { user } = useUserStore()
	const { numberId } = useIdsStore()
	const {
		data: numbers,
		isLoading: isNumbersLoading,
		isError: isNumbersError,
	} = useQuery(['place', 'numbers', user?.partner?.tourId], () =>
		getAllMyNumbers(user?.partner?.tourId),
	)
	const [number, setNumber] = useState<number | undefined>()
	if (isNumbersLoading) return <Spinner />
	if (isNumbersError) return <>Error</>


	return (
		<>
			<Helmet>
				<title>Номера | MTour</title>
			</Helmet>
			<div>
				<NumberDetails
					_number={numberId}
					isVisible={isDetail}
					onClose={() => setIsDetail(false)}
				/>
			</div>
			<div className='block mx-auto w-3/4 max-w-5xl my-10'>
				<ContentHeader className='flex w-full items-center justify-between py-5'>
					<p className='font-bold text-2xl text-dark'>Номера</p>
					<Link
						to='add'
						className='border-[#21BCDE] rounded-xl font-medium bg-light-blue text-white py-2.5 px-4'
					>
						Добавить номер
					</Link>
				</ContentHeader>
				<ContentBody className='flex flex-col justify-around py-16 px-16'>
					{numbers?.map(
						number =>
							!number.hide &&
							!number.is_deleted && (
								<Number
									edit={id => {
										setNumber(id)
										setIsEdit(prevState => !prevState)
									}}
									key={number.id}
									number={number}
									showDetails={id => {
										setNumber(id)
										setIsDetail(true)
									}}
								/>
							),
					)}
				</ContentBody>
			</div>
		</>
	)
}

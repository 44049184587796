import { useQuery } from '@tanstack/react-query'
import { getAllMyPackages } from 'api/package'
import { Spinner } from 'components/ui/Spinner'
import { Helmet } from 'react-helmet'
import { useUserStore } from 'store/user'
import { ProfilePackages } from '../../modules/pages/package/ProfilePackages'

export const PackagePage = () => {
	const { user } = useUserStore()
	const myPackagesQuery = useQuery(
		['organization-packages', user?.partner?.tourId],
		() => {
			return getAllMyPackages(user?.partner?.tourId)
		},
	)

	if (!myPackagesQuery.isSuccess) return <Spinner />

	return (
		<>
			<Helmet>
				<title>Мои пакеты | MTour</title>
				<meta name='robots' content='noindex' />
			</Helmet>
			<ProfilePackages packages={myPackagesQuery.data} />
		</>
	)
}

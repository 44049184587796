import { Link, useNavigate } from '@tanstack/react-location'
import { useMutation } from '@tanstack/react-query'
import { loginUser } from 'api/auth'
import { initializePartnerProfile } from 'api/partners'
import { registerPartner } from 'api/user'
import clsx from 'clsx'
import { ProfileListBox } from 'components/profile/ProfileListBox'
import { Button } from 'components/ui/Button'
import { Input } from 'components/ui/Input'
import emailPhoto from 'helpers/images/email.png'
import passwordPhoto from 'helpers/images/password.png'
import userPhoto from 'helpers/images/user.png'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import toast, { Toaster } from 'react-hot-toast'
import { PartnerRegistrationFields } from 'static/interfaces'
import { useUserStore } from 'store/user'
import { removeSpaces } from 'utils/utils'

// TODO: Настроить выбор категории

export const RegisterPartner = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		watch,
	} = useForm<PartnerRegistrationFields>()
	const { addUser } = useUserStore()
	const navigate = useNavigate()

	const [showPassword, setShowPassword] = useState(false)

	const { mutate: registrate } = useMutation(registerPartner)
	const { mutate: login } = useMutation(loginUser)
	const { mutate: initializeProfile } = useMutation(initializePartnerProfile)

	const [isloading, setIsLoading] = useState(false)
	const [method, setMethod] = useState('email')
	const [pick, setPick] = useState<'Resort' | 'Guide'>('Resort')

	const onSubmit = (data: PartnerRegistrationFields) => {
		setIsLoading(true)
		if (isloading) {
			toast.error('Пожалуйста, подождите...')
			return
		}
		setIsLoading(true)
		if (method === 'email' && data.email) {
			data.username = data.email
		}
		if (method === 'phone' && data.phone) {
			data.username = data.phone
		}

		registrate(
			{
				...(method === 'email' && { email: data.email }),
				...(method === 'phone' && { phone: data.phone }),
				password: data.password,
				username: data.username,
				is_organization: true,
				pick: pick === 'Guide' ? 'guide' : 'resort',
				country: 'kz',
			},
			{
				onSuccess: registrationResponse => {
					login(
						{
							username: registrationResponse.username,
							password: data.password,
						},
						{
							onSuccess: loginResponse => {
								localStorage.setItem('accessToken', loginResponse.access)
								initializeProfile(
									{
										user: loginResponse.userId,
										org_name: data.orgName,
									},
									{
										onSuccess: profileResponse => {
											if (!profileResponse) {
												toast.error('Вы не являетесь партнером')
											}
											addUser({
												id: profileResponse.user,
												email: loginResponse.email
													? {
															id: loginResponse.email.id,
															value: loginResponse.email.email,
													  }
													: undefined,
												phone: loginResponse.phone
													? {
															id: loginResponse.phone.id,
															value: loginResponse.phone.phone,
													  }
													: undefined,
												partner: {
													pick,
													id: profileResponse.id,
													orgName: profileResponse.org_name ?? '',
													...(loginResponse.guide
														? { guide: { id: loginResponse.guide } }
														: null),
													tourId:
														loginResponse.tours !== undefined &&
														loginResponse.tours.length !== 0
															? loginResponse.tours[0].id
															: undefined,
												},
											})
											toast.success('Вы успешно зарегистрировались')
											if (registrationResponse.pick === 'guide') {
												navigate({ to: '/add-guide' })
											} else if (registrationResponse.pick === 'resort') {
												navigate({ to: '/add-organization' })
											}
											reset()
										},
										onError: (error: any) => {
											if (Array.isArray(error.response.data)) {
												for (const key in error.response.data) {
													toast.error(error.response.data[key])
												}
											} else {
												toast.error(error.response.data.detail)
											}
										},
										onSettled: () => {
											setIsLoading(false)
										},
									},
								)
							},
							onError: (error: any) => {
								if (Array.isArray(error.response.data)) {
									for (const key in error.response.data) {
										toast.error(error.response.data[key])
									}
								} else {
									toast.error(error.response.data.detail)
								}
							},
						},
					)
				},
				onError: (error: any) => {
					if (Array.isArray(error.response.data)) {
						for (const key in error.response.data) {
							toast.error(error.response.data[key])
						}
					} else {
						toast.error(error.response.data.detail)
					}
				},
				onSettled: () => {
					setIsLoading(false)
				},
			},
		)
	}
	return (
		<>
			<Toaster />
			<div className='px-10 py-10 bg-bg-light max-w-md shadow-2xl p-10 rounded-2xl'>
				<Helmet>
					<title>Регистрация | MTour</title>
					<meta name='robots' content='noindex' />
				</Helmet>
				<p className='text-2xl font-semibold text-dark text-center mb-3'>
					Регистрация
				</p>
				<p className='flex text-dark text-sm mb-5 font-medium justify-center'>
					У вас имеется учетная запись?
					<Link className='text-green font-medium' to='/authorization'>
						&nbsp; Войти
					</Link>
				</p>
				<div className='flex w-full font-semibold rounded-full bg-white mb-5 '>
					<button
						className={clsx(
							'py-2.5 basis-1/2 rounded-l-full transition-all duration-200 ease-in-out',
							method === 'email'
								? 'bg-[#21BCDE] text-white'
								: 'text-[#21BCDE] bg-white',
						)}
						onClick={() => {
							setMethod('email')
							reset()
						}}
					>
						Email
					</button>
					<button
						className={clsx(
							'py-2.5 basis-1/2 rounded-r-full transition-all duration-200 ease-in-out',
							method === 'phone'
								? 'bg-[#21BCDE] text-white'
								: 'text-[#21BCDE] bg-white',
						)}
						onClick={() => {
							setMethod('phone')
							reset()
						}}
					>
						Телефон
					</button>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					{method == 'email' ? (
						<Input
							error={errors.email?.message}
							{...register('email', {
								required: 'Заполните поле',
								pattern: {
									value:
										/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: 'Неверный формат email',
								},
							})}
							icon={emailPhoto}
							placeholder='E-mail'
							className='mb-4'
						/>
					) : (
						<Input
							error={errors.phone?.message}
							{...register('phone', {
								required: 'Заполните поле',
								pattern: {
									value:
										/^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
									message: 'Неверный формат телефона',
								},
							})}
							icon={emailPhoto}
							placeholder='Номер телефона'
							className='mb-4'
						/>
					)}
					<Input
						error={errors.orgName?.message}
						{...register('orgName', {
							required: 'Заполните поле',
							validate: {
								noSpaces: value =>
									removeSpaces(value) ||
									'Поле не должно начинаться или заканчиваться пробелами',
							},
						})}
						icon={userPhoto}
						placeholder='Название организации'
						className='mb-4'
					/>
					<div className='wrap'>
						<div className='flex flex-col'>
							<Input
								error={errors.password?.message}
								{...register('password', {
									required: 'Заполните поле',
									validate: value => {
										if (value.length < 8) {
											return 'Пароль должен быть не менее 8 символов'
										}
										if (value === value.toLowerCase()) {
											return 'Пароль должен содержать хотя бы одну заглавную букву'
										}
										if (value === value.toUpperCase()) {
											return 'Пароль должен содержать хотя бы одну строчную букву'
										}
										if (!/\d/.test(value)) {
											return 'Пароль должен содержать хотя бы одну цифру'
										}
									},
								})}
								icon={passwordPhoto}
								className='mb-4'
								placeholder='Пароль'
								type={showPassword ? 'text' : 'password'}
								showPassword={showPassword}
								setShowPassword={() => setShowPassword(!showPassword)}
							/>
							<Input
								error={errors.confirmPassword?.message}
								{...register('confirmPassword', {
									required: 'Заполните поле',
									validate: value => {
										if (value !== watch('password')) {
											return 'Пароли не совпадают'
										}
									},
								})}
								icon={passwordPhoto}
								className='mb-4'
								placeholder='Еще раз'
								type={showPassword ? 'text' : 'password'}
								showPassword={showPassword}
								setShowPassword={() => setShowPassword(!showPassword)}
							/>
						</div>
						<ProfileListBox
							className='bg-bg-input rounded-xl border-none'
							width='w-full'
							list={[
								{ id: 1, value: 'Гид' },
								{ id: 2, value: 'Зона отдыха' },
								{ id: 3, value: 'Глэмпинг' },
								{ id: 4, value: 'Санаторий' },
							]}
							onChange={item => {
								setPick(item.id === 1 ? 'Guide' : 'Resort')
							}}
						/>

						<Button
							disabled={isloading}
							isLoading={isloading}
							type='submit'
							className='w-full font-medium h-11 my-5'
							variant='inline'
						>
							Зарегистрироваться
						</Button>
						<p className='text-center font-medium'>
							При входе вы соглашаетесь с нашими{' '}
						</p>
						<p className='justify-center  font-semibold mx-auto block text-center'>
							<a
								className=' text-light-green'
								href='https://mtour.kz/docs/user_rules.html'
								rel='noopener noreferrer'
								target='_blank'
							>
								Условия использования
							</a>{' '}
							&nbsp;и&nbsp;
							<a
								className=' text-light-green'
								href='https://mtour.kz/docs/public_offer_of_legal_entities.html'
								rel='noopener noreferrer'
								target='_blank'
							>
								Публичная оферта для организации
							</a>
						</p>
					</div>
				</form>
			</div>
		</>
	)
}

import {forgotPassword} from 'api/auth'
import {Input} from 'components/ui/Input'
import {Button} from 'components/ui/Button'
import {ForgotPasswordFields} from 'static/interfaces'
import {useMutation} from '@tanstack/react-query'
import clsx from 'clsx'
import {useState} from 'react'
import {useForm} from 'react-hook-form'
import toast from 'react-hot-toast'
import {Modal} from 'components/modal/Modal'
import emailPhoto from 'helpers/images/email.png'

interface Props {
  isVisible: boolean
  onClose: () => void
}

export const ForgotPassword = ({isVisible, onClose}: Props) => {
  const {mutate} = useMutation(forgotPassword)
  const [method, setMethod] = useState('email')

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm<ForgotPasswordFields>({
    criteriaMode: 'all',
  })

  const onSubmit = (data: ForgotPasswordFields) => {
    mutate(
      {
        email: data.email,
        phone: data.phone,
      },
      {
        onSuccess: () => {
          toast.success('Вам отправлена ссылка, для восстановления')
        },
        onError: () => {
          toast.error('Что-то пошло не так, попробуйте позднее')
        },
      },
    )
  }
  return (
    <>
      <Modal
        className='px-10 py-10 bg-bg-light max-w-md'
        isVisible={isVisible}
        onClose={onClose}
      >
        <p className='text-3xl font-semibold text-dark text-center mb-11'>
          Забыли пароль
        </p>
        <div className='flex w-full font-semibold rounded-full bg-white mb-5 '>
          <button
            className={clsx(
              'py-2.5 basis-1/2 rounded-l-full',
              method === 'email'
                ? 'bg-[#21BCDE] text-white'
                : 'text-[#21BCDE] bg-white',
            )}
            onClick={() => {
              setMethod('email')
              reset()
            }}
          >
            Email
          </button>
          <button
            className={clsx(
              'py-2.5 basis-1/2 rounded-r-full',
              method === 'phone'
                ? 'bg-[#21BCDE] text-white'
                : 'text-[#21BCDE] bg-white',
            )}
            onClick={() => {
              setMethod('phone')
              reset()
            }}
          >
            Телефон
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {method == 'email' ? (
            <Input
              error={errors.email?.message}
              {...register('email', {
                required: 'Заполните поле',
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Неверный формат email',
                },
              })}
              icon={emailPhoto}
              placeholder='E-mail'
              type='text'
              className='mb-4'
            />
          ) : (
            <Input
              error={errors.phone?.message}
              mask='+9 (999) 999 99 99'
              {...register('phone', {
                required: 'Заполните поле',
                // pattern: {
                // 	value: new RegExp(
                // 		'/^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$/g'
                // 	),
                // 	message: 'Неверный формат телефона',
                // },
              })}
              icon='/images/email.png'
              placeholder='+7(708) 282 87 54'
              className='mb-4'
              type='text'
            />
          )}
          <Button
            type='submit'
            className='w-full font-medium h-11'
            variant='inline'
          >
            Восстановить пароль
          </Button>
        </form>
      </Modal>
    </>
  )
}

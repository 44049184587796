import React, {useState} from 'react'
import {Applications} from 'modules/pages/applications/Applications'
import {useNavigate} from '@tanstack/react-location'
import {getAllPartnerApplications} from 'api/applications'
import {useQuery} from '@tanstack/react-query'
import {Helmet} from 'react-helmet'

export const ApplicationPage = () => {

  const [pageParam, setPageParam] = useState('')
  const {data} = useQuery({
    queryKey: ['applications', pageParam],
    queryFn: () => getAllPartnerApplications(pageParam),
    keepPreviousData: true,
  })

  const navigate = useNavigate()

  const handleClick = (page: string) => {
    if (data?.next || data?.previous) {
      setPageParam(page ? new URL(page).search : '')
      navigate({
        to: '/applications' + (page || ''),
        replace: false,
        search: {
          page: page ? new URL(page).searchParams.get('page') : '1',
        },
      })
    }
  }

  return (
    <div className={'w-full'}>
      <Helmet>
        <title>Страница заявок</title>
        <meta name='robots' content='noindex'/>
      </Helmet>
      {data && (
        <Applications
          paginatedApplications={data}
          handleClick={handleClick}
        />
      )}
    </div>
  )
}



import {Call, Instagram, Youtube} from 'iconsax-react'

export const Footer = () => {

  return (
    <div className='bg-[#21BCDE] py-24 md:py-14 sm:py-12'>
      <footer
        className='mx-16 flex flex-row justify-between text-white lg:mx-10 md:flex-wrap md:gap-y-7 sm:flex-col sm:mx-7 sm:gap-6'>
        <div className='flex flex-col gap-4 w-3/12 md:w-full'>
          <a href={'/'}>
            <h2 className='font-bold text-5xl'>MTour</h2>
          </a>
          <p className='text-base font-normal'>
            Первый уникальный маркетплейс по поиску места лечения и отдыха
          </p>
          <div className='flex flex-row gap-3'>
            <Call size={16}/>
            <address className='not-italic font-medium text-sm'>
              +7 (707) 558 51-54
            </address>
          </div>
        </div>
        <div className='flex flex-col gap-7 sm:w-full'>
          <h6 className='font-semibold text-lg'>Компания</h6>
          <ul className='flex flex-col gap-4'>
            <li>
              <a href={'/about-us'}>О проекте</a>
            </li>
            <li>
              <a
                href='https://mtour.kz/docs/public_offer_of_legal_entities.html'
                rel='noopener noreferrer'
                target='_blank'
              >
                Публичная оферта
              </a>
            </li>
            <li>
              <a
                href='https://mtour.kz/docs/refunds.html'
                rel='noopener noreferrer'
                target='_blank'
              >
                Правила возврата
              </a>
            </li>
            <li>
              <a href={'/our-contacts'}>Контакты</a>
            </li>
          </ul>
        </div>
        <div className='flex flex-col gap-7 w-2/12 md:w-1/3 sm:w-full'>
          <h5 className='font-semibold text-lg'>Быстрые ссылки</h5>
          <ul className='flex flex-col gap-4'>
            <li>
              <a
                href='https://mtour.kz/docs/USER_PERSONAL_DATA_PROTECTION_AGREEMENT.html'
                rel='noopener noreferrer'
                target='_blank'
              >
                Политика конфиденциальности
              </a>
            </li>
            <li>
              <a
                href='https://mtour.kz/docs/order_rules.html'
                rel='noopener noreferrer'
                target='_blank'
              >
                Порядок оформления заказа
              </a>
            </li>
          </ul>
        </div>
        <div className='flex flex-col gap-7 sm:w-full'>
          <h5 className='font-semibold text-lg'>Подпишитесь на:</h5>
          <div className='flex flex-row gap-4'>
            <a
              href={'https://instagram.com/mtour.kz?igshid=YmMyMTA2M2Y='}
              className='bg-transparent border rounded-full border-white p-1'
            >
              <Instagram size={16} variant='Bold'/>
            </a>
            <a
              href={'https://youtube.com/@mtourkz'}
              className='bg-transparent border rounded-full border-white p-1'
            >
              <Youtube size={16} variant='Bold'/>
            </a>
          </div>
          <h6 className='font-normal text-base'>© 2023 MTour</h6>
        </div>
      </footer>
    </div>
  )

}

import { ChangeAddressRequest } from '../static/interfaces'
import { $authHost } from '.'
import { schemas } from './types'

export const addPhoneNumber = async (request: schemas['TourPhonesRequest']) => {
	const { data } = await $authHost.post('/v1/phones/', request)
	return data
}

export const phoneNumberDelete = async (
	phoneId: number | string | undefined,
) => {
	const { data } = await $authHost.delete(`/v1/phones/${phoneId}/`)
	return data
}

export const changeAddress = async (
	request: ChangeAddressRequest,
): Promise<schemas['Tour']> => {
	const { data } = await $authHost.patch(
		`/v1/tours/${request.tour}`,
		request.address,
	)
	return data
}

export const getAllMyNumbers = async (
	tourId: string | number | undefined,
): Promise<schemas['ListNumbers'][]> => {
	const { data } = await $authHost.get(`/v1/numbers/?tour_id=${tourId}`)
	return data
}


export const getMyNumberTypes = async (
	tourId: number | undefined,
): Promise<schemas['TourNumberType']> => {
	const { data } = await $authHost.get(`/v1/number-type/?tour_id=${tourId}`)
	return data
}

import { useMutation } from '@tanstack/react-query'
import { changePassword } from 'api/user'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ProfileInput } from 'components/profile/ProfileInput'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Modal } from '../Modal'

interface Props {
	isVisible: boolean
	onClose: () => void
}

export const EditPassword = ({ isVisible, onClose }: Props) => {
	// const [oldPassword, setOldPassword] = useState('')
	// const [newPassword, setNewPassword] = useState('')
	// const [repeatPassword, setRepeatPassword] = useState('')
	const [showPassword, setShowPassword] = useState(false)

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		watch,
	} = useForm<{
		old_password: string
		new_password1: string
		new_password2: string
	}>()
	// const changePasswordPatchMutation = useMutation(changePasswordPatch, {})

	const { mutate } = useMutation(changePassword)

	const onSubmit = (data: {
		old_password: string
		new_password1: string
		new_password2: string
	}) => {
		mutate(
			{
				old_password: data.old_password,
				new_password1: data.new_password1,
				new_password2: data.new_password2,
			},
			{
				onSuccess: () => {
					toast.success('Пароль успешно изменен')
					onClose()
				},
				onError: (error: any) => {
					for (const key in error.response.data) {
						toast.error(error.response.data[key])
					}
				},
			},
		)
	}

	return (
		<Modal
			className='px-16 py-8 bg-white max-w-lg'
			isVisible={isVisible}
			onClose={() => {
				onClose()
				reset()
			}}
		>
			<p className='text-2xl  font-semibold text-dark text-center mb-5'>
				Изменить пароль
			</p>
			{/* {(newPassword.length === 0 || repeatPassword.length === 0) && (
					<span className='font-normal block text-center text-[#818C99]'>
						Заполните все поля чтобы изменить пароль
					</span>
				)} */}

			<form className='flex flex-col gap-3.5' onSubmit={handleSubmit(onSubmit)}>
				<ProfileInput
					{...register('old_password', {
						required: 'Поле обязательно для заполнения',
					})}
					error={errors.old_password?.message}
					label='Текущий пароль'
					placeholder='******'
					type={showPassword ? 'text' : 'password'}
					showPassword={showPassword}
					setShowPassword={() => setShowPassword(!showPassword)}
					className='block mx-auto w-full'
					// onChange={(e) => setOldPassword(e.target.value)}
				/>
				<ProfileInput
					{...register('new_password1', {
						required: 'Поле обязательно для заполнения',
						validate: value => {
							if (value.length < 8) {
								return 'Пароль должен быть не менее 8 символов'
							}
							if (value === value.toLowerCase()) {
								return 'Пароль должен содержать хотя бы одну заглавную букву'
							}
							if (value === value.toUpperCase()) {
								return 'Пароль должен содержать хотя бы одну строчную букву'
							}
							if (!/\d/.test(value)) {
								return 'Пароль должен содержать хотя бы одну цифру'
							}
						},
					})}
					error={errors.new_password1?.message}
					label='Новый пароль'
					placeholder='******'
					type={showPassword ? 'text' : 'password'}
					showPassword={showPassword}
					setShowPassword={() => setShowPassword(!showPassword)}
					className='block mx-auto w-full'
				/>
				<ProfileInput
					{...register('new_password2', {
						required: 'Поле обязательно для заполнения',
						validate: value =>
							value === watch('new_password1') || 'Пароли не совпадают',
					})}
					error={errors.new_password2?.message}
					label='Повторите новый пароль'
					placeholder='******'
					type={showPassword ? 'text' : 'password'}
					showPassword={showPassword}
					setShowPassword={() => setShowPassword(!showPassword)}
					className='block mx-auto w-full'
				/>
				<ProfileButton
					type='submit'
					className='mt-7 block mx-auto'
					// disabled={newPassword.length < 8 && newPassword !== repeatPassword}
				>
					Подтвердить
				</ProfileButton>
			</form>
		</Modal>
	)
}

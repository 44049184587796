import { Link } from '@tanstack/react-location'
import { schemas } from 'api/types'
import notImage from 'helpers/images/NoImage.svg'
import { getImageURL } from 'utils/utils'
import clsx from 'clsx'

interface Props {
	program: schemas['GuideProgramList']
	onEdit: (id: number) => void
}

export const GuideProgramsCard = ({ program, onEdit }: Props) => {
	console.log(program)
	const buttonStylesText = {
		easy: 'text-[#027A48]',
		medium: 'text-[#6941C6]',
		hard: 'text-[#C01048]',
		notSpecified: 'bg-[#F2F4F7]'
	}
	const buttonStylesBg = {
		easy: 'bg-[#ECFDF3]',
		medium: 'bg-[#F9F5FF]',
		hard: 'bg-[#FFF1F3]',
		notSpecified: 'bg-[#F2F4F7]'
	}

	const complexityTranslation = {
		easy: 'Легко',
		medium: 'Средний',
		hard: 'Сложный',
		notSpecified: 'Не указано'
	}
	const buttonStyle = buttonStylesText[program.complexity ?? 'notSpecified']
	const buttonStyleBg = buttonStylesBg[program.complexity ?? 'notSpecified']
	const complexityText = complexityTranslation[program.complexity ?? 'notSpecified']

	const durationType = {
		minutes: 'Минут',
		hours: 'Часов',
		days: 'Дней',
		weeks: 'Недель',
		notSpecified: 'Не указано'
	}
    const durationTypeText = durationType[program.duration_type ?? 'notSpecified']
	return (
		<div className='sm:basis-full md:basis-full lg:basis-1/2 flex flex-col pb-6 px-2 md:px-0 overflow-hidden text-dark-blue'>
			<div>
				<Link>
					<span className='w-full bg-[#F2F2F2] flex items-center h-48 rounded-t-xl'>
						{program.thumbnail ? (
							<img
								style={{ objectFit: 'cover' }}
								className={'w-full h-full mx-auto rounded-t-xl'}
								src={getImageURL(program.thumbnail)}
								alt={''}
							/>
						) : (
							<img
								className={'w-max mx-auto  rounded-t-xl'}
								src={notImage}
								alt={''}
							/>
						)}
					</span>
				</Link>
			</div>
			<div className='flex h-full flex-col rounded-b-3xl shadow-lg py-4 px-3'>
				<div className='flex flex-row justify-between'>
					<p className='text-[17px] font-bold text-dark-blue mb-2'>
						<Link className='font-semibold text-dark-blue'>{program.name}</Link>
					</p>
				</div>
				<div className=''>
				    <div className='my-[6px] flex justify-start items-center h-[30px] rounded-[16px] px-[8px] py-[2px] bg-[#EEF4FF]'>
				        <p className='text-[#3056D3] not-italic font-medium text-[13px] h-[18px]'>Общих мест: {program.seats_count}</p>
				    </div>
					<div className='my-[6px] flex justify-start items-center h-[30px] rounded-[16px] px-[8px] py-[2px] bg-[#ECFDF3]'>
						<p className='text-[#027A48] not-italic font-medium text-[13px] h-[18px]'>Длительность: {program.duration + ' ' + durationTypeText}</p>
					</div>
					<div className={clsx('my-[6px] flex justify-start items-center h-[30px] rounded-[16px] px-[8px] py-[2px]', buttonStyleBg)}>
						<p className={clsx('not-italic font-medium text-[13px] h-[18px]', buttonStyle)}>Сложность: {complexityText}</p>
					</div>
				</div>
				<div className='flex flex-col justify-between h-full'>
					<div className='flex flex-row justify-between'>
						<p className={'flex flex-col justify-between'}>
							От <br />
							<span className='font-bold text-xl'>{program.price}тг</span>
						</p>
						<div className={'flex items-end'}>
							<button
								className='rounded-3xl text-[15px] px-3 py-1 text-white bg-light-blue text-lg justify-center flex items-center border-light-blue border-2'
								onClick={() => onEdit(program.id)}
							>
								Редактировать
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}


// Original one, оставил на случай
// export const GuideProgramsCard = ({ program, onEdit }: Props) => {
// 	return (
// 		<div className='sm:basis-full md:basis-full lg:basis-1/2 flex flex-col pb-6 px-2 md:px-0 overflow-hidden text-dark-blue'>
// 			<div>
// 				<Link>
// 					<span className='w-full bg-[#F2F2F2] flex items-center h-48 rounded-t-xl'>
// 						{program.thumbnail ? (
// 							<img
// 								style={{ objectFit: 'cover' }}
// 								className={'w-full h-full mx-auto rounded-t-xl'}
// 								src={getImageURL(program.thumbnail)}
// 								alt={''}
// 							/>
// 						) : (
// 							<img
// 								className={'w-max mx-auto  rounded-t-xl'}
// 								src={notImage}
// 								alt={''}
// 							/>
// 						)}
// 					</span>
// 				</Link>
// 			</div>
// 			<div className='flex h-full flex-col rounded-b-3xl shadow-lg py-4 px-3'>
// 				<div className='flex flex-row justify-between'>
// 					<p className='text-[17px] font-bold text-dark-blue mb-2'>
// 						<Link className='font-semibold text-dark-blue'>{program.name}</Link>
// 					</p>
// 				</div>
// 				<div className='flex flex-col justify-between h-full'>
// 					<div className='flex flex-row justify-between'>
// 						<p className={'flex flex-col justify-between'}>
// 							От <br />
// 							<span className='font-bold text-xl'>{program.price}тг</span>
// 						</p>
// 						<div className={'flex items-end'}>
// 							<button
// 								className='rounded-3xl text-[15px] px-3 py-1 text-white bg-light-blue text-lg justify-center flex items-center border-light-blue border-2'
// 								onClick={() => onEdit(program.id)}
// 							>
// 								Редактировать
// 							</button>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	)
// }
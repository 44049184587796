import { schemas } from '@/api/types'
import { AddAdditionalServiceRowItems } from '../../../components/partner/additionalServices/AddAdditionalServiceRowItems'

interface Props {
	titles: schemas['AdditonalInfoTitle'][] | undefined
	additionalInfo: schemas['AdditionalTitles'][] | undefined
	handleAdd: (title: number, service: string) => void
	handleDelete: (id: number) => void
}

export const AdditionalServicesContent = ({
	titles,
	additionalInfo,
	handleAdd,
	handleDelete,
}: Props) => {
	return (
		<AddAdditionalServiceRowItems
			titles={titles}
			additionalInfo={additionalInfo}
			handleAdd={handleAdd}
			handleDelete={handleDelete}
		/>
	)
}

import { schemas } from '@/api/types'
import { ApplicationTableRow } from 'components/partner/application/ApplicationTableRow'

interface Props {
    application: schemas['ListTourApplication']
}

export const ApplicationTableRowItems = ({ application }: Props) => {
    return (
        <ApplicationTableRow
            key={application.id}
            tourApplication={application.application}
            status={application.status}
        />
    )
}

import { $host } from '.'
import { schemas } from './types'

export type Region = schemas['Region']
export type Regions = schemas['Region'][]

export type Country = schemas['Country']
export type Countries = schemas['Country'][]

export const getAllRegions = async (): Promise<Regions> => {
	const { data } = await $host.get('/v1/address/regions/')
	return data
}

export const getCountries = async (): Promise<schemas['Country'][]> => {
	const { data } = await $host.get('/v1/address/country/')
	return data
}

export const getRegions = async (
	country: number | undefined | string,
): Promise<schemas['Region'][] | []> => {
	if (!country) return []
	const { data } = await $host.get(`/v1/address/regions/?country_id=${country}`)
	return data
}

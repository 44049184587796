import { $authHost, $host } from '.'
import { schemas } from './types'

export const createPackage = async (
	request: schemas['WriteTourPackageRequest'],
): Promise<schemas['WriteTourPackage']> => {
	const { data } = await $authHost.post('/v1/packages/', request)
	return data
}

export const changePackage = async (
	request: schemas['WriteTourPackageRequest'] & { id: number },
): Promise<schemas['WriteTourPackage']> => {
	const { data } = await $authHost.patch(`/v1/packages/${request.id}/`, request)

	return data
}

export const deletePackage = async (id: string | undefined | number) => {
	const { data } = await $authHost.delete(`/v1/packages/${id}/`)
	return data
}

export const patchPackage = async (
	request: schemas['PatchedWriteTourPackageRequest'] & { id: number },
): Promise<schemas['WriteTourPackage']> => {
	const { data } = await $authHost.patch(`/v1/packages/${request.id}/`, request)
	return data
}

export const getAllMyPackages = async (
	tourId: string | number | undefined,
): Promise<schemas['ListPackage'][]> => {
	const { data } = await $authHost.get(`/v1/packages/?tour_id=${tourId}`)
	return data
}

export const getPackage = async (
	id: string | number | undefined,
): Promise<schemas['ListPackage']> => {
	const { data } = await $host.get(`/v1/packages/${id}/`)
	return data
}

import React, { useState } from 'react'
import { Modal } from '../Modal'
import { ProfileButton } from '../../profile/ProfileButton'
import { ProfileInput } from '../../profile/ProfileInput'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { validateConfirmCode } from 'api/crm'
import toast from 'react-hot-toast'

interface CourseProps {
  isVisible: boolean
  onClose: () => void
  id: string | undefined
}

export const Submit: React.FC<CourseProps> = ({isVisible, onClose, id}: CourseProps) => {

  const queryClient = useQueryClient()
  const {mutate} = useMutation(validateConfirmCode)

  const [inputValue, setInputValue] = useState('')

  const send = () => {
    if (inputValue.trim()) {
      mutate(
        {validateId: inputValue, bookingId: id},
        {
          onSuccess: () => {
            toast.success('Бронь подтверждена')
            queryClient.refetchQueries(['bookings'])
            onClose()
          },
          onError: () => {
            toast.error('Произошла ошибка попробуйте позднее')
          }
        })
    }
  }



  return (
    <Modal isVisible={isVisible} onClose={onClose} className="max-w-xl p-8">
      <div>
        <p className="text-dark-blue font-medium text-2xl text-center">
          Введите код подтверждения
        </p>
        <div className="flex justify-center gap-x-5 items-end mt-1">
          <ProfileInput onChange={(event) => setInputValue(event.target.value)}></ProfileInput>
          <ProfileButton
            type="button"
            className="mt-6 mr-4 bg-blue"
            onClick={send}
          >
            Отправить
          </ProfileButton>
        </div>
      </div>
    </Modal>
  )
}

import { Button } from '../../ui/Button'
import clsx from 'clsx'

interface Props {
	title: string
	textColor: string
	onClick: () => void
	titleButton: string
	className?: string
}

export const HeaderButton = ({
	title,
	textColor,
	onClick,
	titleButton,
	className
}: Props) => {
	return (
		<div className={clsx('flex justify-between items-center w-full', className)}>
			<span className='font-medium'>{title}</span>
			<Button className={`text-${textColor}`} onClick={onClick}>
				{titleButton}
			</Button>
		</div>
	)
}

import { schemas } from '@/api/types'
import { useEffect } from 'react'
import { Modal } from '../Modal'

interface Props {
	isVisible: boolean
	onClose: () => void
	service?: schemas['TourPaidServices']
}

export const Service = ({ isVisible, onClose, service }: Props) => {
	useEffect(() => {
		if (!service) {
			onClose()
		}
	}, [service])

	if (!service) {
		return <></>
	}

	return (
		<Modal
			className='flex flex-col bg-white px-10 py-7  max-w-2xl'
			isVisible={isVisible}
			onClose={onClose}
		>
			<div className='flex flex-col gap-7'>
				<div className='flex flex-col gap-3'>
					<h6 className='text-xl font-semibold text-[#111827]'>
						{service.name}
					</h6>
					<p className='font-medium text-lg text-[#6B7280]'>
						{service.description
							? service.description
							: 'Описание услуги отсутсвует'}
					</p>
				</div>
				<div className='flex flex-row justify-between items-center sm:flex-col sm:justify-start sm:gap-7'>
					<div className='flex flex-row gap-3 text-xl'>
						<h6 className='text-[#111827] font-medium'>Стоимость услуги</h6>
						<span className='text-[#31D9BD] font-semibold'>
							₸ {service.price}
						</span>
					</div>
				</div>
			</div>
		</Modal>
	)
}

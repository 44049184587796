import clsx from 'clsx'
import { ComponentPropsWithoutRef, ReactNode } from 'react'

interface Props extends ComponentPropsWithoutRef<'div'> {
	children?: ReactNode
}

export const ContentHeader = ({ children, className }: Props) => {
	return (
		<div
			className={clsx(
				'flex bg-[#FFF] border-solid border-2 border-[#E4E7EC] rounded-t-xl items-center px-8 shadow-lg',
				className,
			)}
		>
			{children}
		</div>
	)
}

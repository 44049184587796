import { schemas } from '@/api/types'
import { ApplicationTableRowItems } from 'components/partner/application/ApplicationRowItems'

interface Props {
	paginatedApplications: schemas['PaginatedListTourApplicationList']
	handleClick: (page: string) => void
}

export const Applications = ({ paginatedApplications, handleClick }: Props) => {
	return (
		<div className=' w-full'>
			<div className='block mx-auto w-11/12 max-w-7xl pb-20 overflow-x-auto'>
				{paginatedApplications.results?.length === 0 && (
					<div className='text-center mt-10'>
						<p className='text-[#111827] font-semibold text-2xl'>Нет заявок</p>
						<p className='text-[#637381] text-sm mt-2'>
							Здесь будут отображаться заявки, которые вы получили
						</p>
					</div>
				)}
				<table
					className={`table-fixed w-full mt-10 ${
						paginatedApplications.results?.length === 0 && 'hidden'
					}`}
				>
					<thead className='uppercase text-[#111827] font-semibold text-base w-full text-left py-5  bg-[#F9FAFB] rounded-t-lg'>
						<tr className='w-full'>
							<th className='py-5 pl-10 w-3/12'>Информация</th>
							<th className='py-5 w-3/12'>Пожелания</th>
							<th className='py-5 w-2/12'>Статус</th>
							<th className='py-5 w-2/12'>Регион</th>
							<th className='pr-10 py-5 w-2/12'></th>
						</tr>
					</thead>
					<tbody className='bg-white'>
						{paginatedApplications.results?.map(application => (
							<ApplicationTableRowItems
								key={application.id}
								application={application}
							/>
						))}
					</tbody>
					<tfoot className='text-[#637381] font-semibold text-base w-full py-5 bg-[#F9FAFB] rounded-t-lg justify-between'>
						<tr className='w-full'>
							<td className='py-5 pl-10'>
								<button
									className={`bg-white py-2.5 px-5 rounded-lg ${
										paginatedApplications.previous
											? ''
											: 'opacity-50 cursor-not-allowed'
									}`}
									onClick={() => {
										if (paginatedApplications.previous) {
											handleClick(paginatedApplications.previous)
										}
									}}
								>
									Предыдущая
								</button>
							</td>
							<td className='text-right py-5 pr-1 text-[#111827]'>
								{/*Страница {router.query.page || '1'} ПОФИКСИТЬ И ДАТЬ РЕШЕНИЕ БЕЗ NEXT*/}
							</td>
							<td className='py-5 text-[#111827]'>
								из{' '}
								{paginatedApplications.count &&
									Math.ceil(paginatedApplications.count / 10)}
							</td>
							<td className='py-5'></td>
							<td className='pr-10 py-5 text-right '>
								<button
									className={`bg-white py-2.5 px-5 rounded-lg ${
										paginatedApplications.next
											? ''
											: 'opacity-50 cursor-not-allowed'
									}`}
									onClick={() => {
										if (paginatedApplications.next) {
											handleClick(paginatedApplications.next)
										}
									}}
								>
									Следующая
								</button>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	)
}

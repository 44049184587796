import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createGuide } from 'api/guide/guide'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ProfileInput } from 'components/profile/ProfileInput'
import { ProfileTextarea } from 'components/profile/ProfileTextarea'
import { ContentBody } from 'components/ui/Content/ContentBody'
import { ContentHeader } from 'components/ui/Content/ContentHeader'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Map, Placemark, Polygon, YMaps } from 'react-yandex-maps'
import { GuideFields } from 'static'
import { useUserStore } from 'store/user'
import { Modal } from '../Modal'

interface Props {
	isVisible: boolean
	onClose: () => void
}

export const AddGuide = ({ isVisible, onClose }: Props) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<GuideFields>()
	const queryClient = useQueryClient()
	const { user, editUser } = useUserStore()
	const { mutate } = useMutation(createGuide)
	const [coordinates, setCoordinates] = useState<number[]>([51.18, 71.45])

	const onSubmit = (data: GuideFields) => {
		if (!user?.partner?.id) {
			toast.error('Ошибка при создании гида')
			return
		}
		mutate(
			{
				description: data.description,
				title: data.title,
				email: data.email,
				BIK: data.bik,
				IIK: data.iik,
				BIN: data.bin,
				currency: 1,
				org: user?.partner?.id,
				lat: coordinates[0],
				lon: coordinates[1],
			},
			{
				onSuccess: response => {
					editUser({
						...user,
						partner: {
							...user.partner,
							pick: 'Guide',
							guide: {
								id: response.id,
							},
						},
					})
					onClose()
					toast.success('Создано')
				},
				onError: (error: any) => {
					for (const key in error.response.data) {
						toast.error(error.response.data[key])
					}
				},
			},
		)
	}
	return (
		<Modal
			className='px-8 py-8 bg-white max-w-4xl'
			isVisible={isVisible}
			onClose={() => onClose()}
		>
			<p className='text-2xl font-semibold text-dark text-center mb-10'>
				Добавление агентства
			</p>
			<form onSubmit={handleSubmit(onSubmit)}>
				<ContentHeader className='flex w-full items-center justify-between py-5'>
					<p className='font-medium text-xl text-dark'>Организация</p>
				</ContentHeader>
				<ContentBody className='flex flex-wrap flex-row gap-3 mb-10'>
					<ProfileInput
						className='basis-[calc(50%-16px)]'
						error={errors.title?.message}
						label='Название Организации'
						placeholder='Ақ–Тілек'
						type='text'
						{...register('title', {
							required: 'Заполните поле',
							minLength: 3,
						})}
					/>
					<ProfileInput
						className='basis-[calc(50%-16px)]'
						error={errors.bin?.message}
						{...register('bin', {
							required: 'Заполните поле',
							// pattern: {
							// 	value: /^[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}$/,
							// 	message: 'Введите бин в формате 0000-0000-0000-0000',
							// }
						})}
						label='БИН'
						placeholder='4444444444444444'
						type='text'
					/>
					<ProfileInput
						className='basis-[calc(50%-16px)]'
						error={errors.iik?.message}
						{...register('iik', {
							required: 'Заполните поле',
							pattern: {
								value:
									/^([A-Z]{2}[-]?[0-9]{2})(?=(?:[-]?[A-Z0-9]){9,30}$)((?:[-]?[A-Z0-9]{3,5}){2,7})([-]?[A-Z0-9]{1,3})?$/,
								message: 'Введите ИИК в в верном формате',
							},
						})}
						label='ИИК (IBAN)'
						placeholder='KZ106010291000194146'
						type='text'
					/>
					<ProfileInput
						className='basis-[calc(50%-16px)]'
						error={errors.bik?.message}
						{...register('bik', {
							required: 'Заполните поле',
						})}
						label='БИК'
						placeholder='HSBKKZKX'
						type='text'
						pattern='[A-Z0-9]{8}'
					/>
				</ContentBody>
				<ContentHeader className='flex w-full items-center justify-between py-5'>
					<p className='font-medium text-xl text-dark'>Контактная информация</p>
				</ContentHeader>
				<ContentBody className='flex flex-wrap flex-row gap-3 mb-10'>
					<ProfileInput
						className='basis-[calc(50%-16px)]'
						label='Адрес'
						placeholder='Казахстан, Алмата, Есиль, Туркестан, 10'
						{...register('address', {
							required: 'Заполните поле',
							minLength: 3,
						})}
						error={errors.address?.message}
					/>
					<ProfileInput
						className='basis-[calc(50%-16px)]'
						label='График работы (Пишите через запятую)'
						placeholder='Пн - Вт: 9:00 - 18:00, Сб - Вс: Выходной'
						{...register('workingTime', {
							required: 'Заполните поле',
							minLength: 3,
						})}
						error={errors.workingTime?.message}
					/>
					<ProfileInput
						className='basis-[calc(50%-16px)]'
						placeholder='info@example.com'
						label='Email'
						{...register('email', {
							required: 'Заполните поле',
							minLength: 3,
						})}
						error={errors.email?.message}
					/>
				</ContentBody>
				<ContentHeader className='flex w-full items-center justify-between py-5'>
					<p className='font-medium text-xl text-dark'>Описание</p>
				</ContentHeader>
				<ContentBody className='flex flex-wrap flex-row gap-3 mb-10'>
					<ProfileTextarea
						rows={5}
						className='w-full resize-none'
						placeholder='Лечебно-оздоровительный комплекс или просто санаторий Ок-Жетпес расположен на берегу озера Боровое, немного в стороне от шумного курортного посёлка Бурабай. Здесь в тишине и покое его гости могут не только прекрасно отдохнуть, но и поправить своё здоровье.'
						{...register('description', {
							required: 'Заполните поле',
						})}
						error={errors.description?.message}
					/>
					<span>Напишите 500 символов</span>
				</ContentBody>
				<ContentHeader className='flex w-full items-center justify-between py-5'>
					<p className='font-medium text-xl text-dark'>
						Карта
						<span className='text-gray text-sm inline-block ml-2'>
							(Укажите на карте организацию)
						</span>
					</p>
				</ContentHeader>
				<ContentBody className='mb-10'>
					<YMaps query={{ apikey: 'f80bf96b-8fa3-4478-ac9a-39e597b9757a' }}>
						{/* <ReactNotification /> */}
						<Map
							// className='my-10'
							modules={['geocode']}
							defaultState={{ center: [51.18, 71.45], zoom: 9 }}
							width={'100%'}
							height={'250px'}
							onClick={(event: any) => {
								setCoordinates(event.get('coords'))
							}}
						>
							<Polygon options={{ visible: false }} />
							{coordinates && <Placemark geometry={coordinates} />}
						</Map>
					</YMaps>
				</ContentBody>
				<div className='flex justify-end'>
					<ProfileButton type='submit' className='mt-6'>
						Сохранить
					</ProfileButton>
				</div>
			</form>
		</Modal>
	)
}

// TODO: Засунуть API ключ YMaps'а в .env

import { $authHost } from '..'
import { schemas } from '../types'

export const addProgram = async (
	request: schemas['GuideProgramRequest'],
): Promise<schemas['GuideProgram']> => {
	const { data } = await $authHost.post('/v1/guide-programs/', request)
	return data
}

export const editProgram = async (
	request: schemas['PatchedGuideProgramRequest'] & { id: string | undefined },
): Promise<schemas['GuideProgram']> => {
	const { data } = await $authHost.patch(`/v1/guide-programs/${request.id}`, request)
	return data
}

export const editPhoto = async ({
	id,
	request,
}: {
	request: FormData
	id: number
}): Promise<schemas['GuideProgram']> => {
	$authHost.defaults.headers.common['Content-Type'] = 'multipart/form-data'
	const { data } = await $authHost.patch(`/v1/guide-programs/${id}/`, request)
	return data
}

export const getProgram = async (
	id: number | undefined | string,
): Promise<schemas['GuideProgramDetail']> => {
	const { data } = await $authHost.get(`/v1/guide-programs/${id}/`)
	return data
}

export const getProgramsByGuideId = async (
	id: number | undefined,
): Promise<schemas['GuideProgramList'][]> => {
	const { data } = await $authHost.get(`/v1/guide-programs/?guide_id=${id}`)
	return data
}

export const deleteProgram = async (id: number | undefined) => {
	const { data } = await $authHost.delete(`/v1/guide-programs/${id}/`)
	return data
}

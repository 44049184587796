import { $authHost } from '..'
import { schemas } from '../types'

export const getGuideServices = async (
	id: string | number | undefined,
): Promise<schemas['GuideServices'][]> => {
	const { data } = await $authHost.get(`/v1/guide-services/?guide_id=${id}`)
	return data
}

export const createGuideService = async (
	request: schemas['GuideServicesRequest'],
): Promise<schemas['GuideServices']> => {
	const { data } = await $authHost.post('/v1/guide-services/', request)
	return data
}

export const removeGuideService = async (id: number) => {
	const { data } = await $authHost.delete(`/v1/guide-services/${id}`)
	return data
}



import { Link, useLocation } from '@tanstack/react-location'
import clsx from 'clsx'

interface Props {
	link: {
		href: string
		title: string
		id: number
	}
	open: boolean
}

export const SideBarLink = ({ link: { href, id, title }, open }: Props) => {
	const location =
		useLocation().current.pathname.split('/').length > 2
			? useLocation().current.pathname.split('/')[2]
			: useLocation().current.pathname.split('/')[1]
	const customHref =
		href.split('/').length > 2 ? href.split('/')[2] : href.split('/')[1]
	const notActiveClass = 'w-2 h-2 bg-[#B4BDC6] rounded-2xl'
	const activeClass =
		'w-4 h-4 rounded-full bg-[#21BCDE] border-4 border-[#E7E7FF]'

	return (
		<Link
			className={
				'flex rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 items-center gap-x-4 mt-3 hover:bg-[#E9F8FC] truncate overflow-hidden'
			}
			to={href}
		>
			{location === customHref ? (
				<div className={activeClass}></div>
			) : (
				<div className={notActiveClass}></div>
			)}
			<span
				className={clsx(
					'origin-left duration-200 truncate overflow-hidden',
					!open && 'hidden',
				)}
			>
				{title}
			</span>
		</Link>
	)
}

import { schemas } from '@/api/types'
import close from 'helpers/images/closeX.svg'

interface Props {
	serviceBadge: schemas['AdditionalInfoServicesRead'] | undefined
	onDelete: (id: number) => void
}

export const AdditionalServiceBadgeItem = ({
	serviceBadge,
	onDelete,
}: Props) => {
	return (
		<div className='flex flex-row gap-1.5 text-[#002C50] font-normal text-base rounded-2xl text-center py-2 px-4 bg-[#F5F3F7]'>
			<p>{serviceBadge?.service}</p>
			<button
				className='text-[#4B3A5A] font-bold'
				onClick={() => serviceBadge?.id && onDelete(serviceBadge.id)}
			>
				<img src={close} width={10} height={10} alt='close' />
			</button>
		</div>
	)
}

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import react, { ComponentPropsWithoutRef, ReactNode } from 'react'

interface Props extends ComponentPropsWithoutRef<'input'> {
	icon?: ReactNode | string
	label?: string
	placeholder: string
	error?: string
	className?: string
	showPassword?: boolean
	setShowPassword?: () => void
	mask?: string
}

export const Input = react.forwardRef<HTMLInputElement, Props>(
	(
		{
			className,
			placeholder,
			label,
			icon,
			error,
			showPassword,
			type,
			setShowPassword,
			mask = '*',
			...rest
		}: Props,
		ref,
	) => {
		return (
			<div className={className}>
				{label && (
					<label className='font-medium text-base mb-2 inline-block'>
						{label}
					</label>
				)}
				<div className={clsx('flex flex-row w-full relative')}>
					<div className='absolute h-full flex items-center pl-4'>
						{typeof icon === 'string' ? (
							<img src={icon as string} alt='Icon' width={15} height={15} />
						) : (
							icon
						)}
					</div>
					<input
						ref={ref}
						type={type}
						{...rest}
						placeholder={placeholder}
						className='block text-dark font-medium bg-bg-input py-3 px-4 pl-10 w-full self-center rounded-xl placeholder:text-light-blue outline-0'
					/>
					{showPassword != undefined && (
						<button
							type='button'
							className='absolute right-2.5 h-full flex items-center text-light-blue'
							onClick={setShowPassword}
						>
							{showPassword ? (
								<EyeIcon width={15} height={15} />
							) : (
								<EyeSlashIcon width={15} height={15} />
							)}
						</button>
					)}
				</div>
				{error ? (
					<span className='text-red font-medium text-sm'>{error}</span>
				) : (
					''
				)}
			</div>
		)
	},
)

import { useNavigate } from '@tanstack/react-location'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
	addProgram as _addProgram,
	editPhoto as _editPhoto,
	editProgram as _editProgram,
} from 'api/guide/program'
import {
	createGuideService,
	getGuideServices,
	removeGuideService,
} from 'api/guide/services'
import { schemas } from 'api/types'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ProfileInput } from 'components/profile/ProfileInput'
import { ProfileListBox } from 'components/profile/ProfileListBox'
import { ProfileTextarea } from 'components/profile/ProfileTextarea'
import Checkbox from 'components/ui/Checkbox'
import { Container } from 'components/ui/Container'
import { ContentBody } from 'components/ui/Content/ContentBody'
import { ContentHeader } from 'components/ui/Content/ContentHeader'
import { Spinner } from 'components/ui/Spinner'
import { EditorState, convertToRaw } from 'draft-js'
import close from 'helpers/images/closeX.svg'
import { Trash } from 'iconsax-react'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { ProgramFields, UploadImages } from 'static/interfaces'
import { programComplexity } from 'static/staticLists'
import { useUserStore } from 'store/user'
import { removeSpaces } from 'utils/utils'

export const Add = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ProgramFields>()
	const queryClient = useQueryClient()
	const { user } = useUserStore()
	const navigate = useNavigate()

	const { mutate: addService } = useMutation(createGuideService)
	const { mutate: removeService } = useMutation(removeGuideService)
	const { mutate: addProgram } = useMutation(_addProgram)
	const { mutateAsync: editPhoto } = useMutation(_editPhoto)
	const [services, setServices] = useState<number[]>([])
	const { mutateAsync: editProgram } = useMutation(_editProgram)
	const [selectedFile, setSelectedFile] = useState<UploadImages | null>(null)
	const [coords, setCoords] = useState<number[]>([51.18, 71.45])
	const [complexity, setComplexity] = useState<number>()

	const [service, setService] = useState('')
	const [error, setError] = useState('')
	const [editor, setEditor] = useState(EditorState.createEmpty())

	const _addService = () => {
		if (service.trim() && user?.partner?.guide?.id) {
			addService(
				{
					guide: user?.partner?.guide?.id,
					title: service,
				},
				{
					onSuccess: response => {
						const prevProfileServices = queryClient.getQueryData<
							schemas['GuideServices'][]
						>(['services', user?.partner?.guide?.id])
						if (prevProfileServices) {
							queryClient.setQueryData(
								['services', user?.partner?.guide?.id],
								[...prevProfileServices, response],
							)
						}
					},
				},
			)
			setService('')
		} else {
			setError('Введите что нибудь')
		}
	}

	const eventDelete = (id: number) => {
		removeService(id, {
			onSuccess: () => {
				toast.success('Успешно удалено')
				const prevProfileServices = queryClient.getQueryData<
					schemas['GuideServices'][]
				>(['services', user?.partner?.guide?.id])
				if (prevProfileServices) {
					const updatedProfileServices = prevProfileServices.filter(
						service => service.id !== id,
					)
					queryClient.setQueryData(
						['services', user?.partner?.guide?.id],
						updatedProfileServices,
					)
				}
			},
			onError: () => {
				toast.error('Ошибка при удалении, попробуйте позднее')
			},
		})
	}

	const addServiceHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setService(event.currentTarget.value)
		setError('')
	}

	const { data, isLoading, isError } = useQuery(
		['services', user?.partner?.guide?.id],
		() => getGuideServices(user?.partner?.guide?.id),
	)

	const onDrop = useCallback((acceptedFiles: File[]) => {
		acceptedFiles.map(file => {
			const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png']
			if (!acceptedImageTypes.includes(file['type'])) {
				toast.error('Выберите соответствующий тип файла')
				return
			} else if (file.size > 1048576 * 5) {
				toast.error('Файл слишком большой')
				return
			}
			setSelectedFile({
				file,
				preview: URL.createObjectURL(file),
				status: 'loading',
			})
		})
	}, [])

	const { getRootProps, getInputProps } = useDropzone({ onDrop })
	const saveOrganizationData = (data: ProgramFields) => {
		if (!user || !user?.partner?.guide?.id) return
		addProgram(
			{
				name: data.name,
				description: data.description,
				price: data.price,
				venue_lat: coords[0],
				venue_lon: coords[1],
				venue_address: data.address,
				guide: user?.partner?.guide?.id,
				program: JSON.stringify(convertToRaw(editor.getCurrentContent())),
				services,

				complexity:
					programComplexity.find(item => item.id === complexity)?.name ??
					'medium',
			},
			{
				onSuccess: () => {
					toast.success('Сохранено')
					navigate({ to: '/guide/program' })
				},
				onError: () => {
					toast.error('Произошла ошибка, попробуйте позднее')
				},
				onSettled: data => {
					if (!selectedFile?.file || !data?.id) {
						return
					}
					const formData = new FormData()
					formData.append('photo', selectedFile.file)
					editPhoto({ request: formData, id: data?.id })
						.then(result => {
							if (selectedFile)
								setSelectedFile({
									...selectedFile,
									status: 'uploaded',
								})
						})
						.catch(error => {
							toast.error('Фотография не была добавлена, попробуйте позднее')
						})
						.finally(() => {
							setSelectedFile(null)
						})
				},
			},
		)
	}

	useEffect(() => {
		if (selectedFile?.preview)
			return () => URL.revokeObjectURL(selectedFile.preview)
	}, [])

	if (isLoading) return <Spinner />
	if (isError) return <>Error</>

	return (
		<>
			<Container className='block w-3/4 max-w-6xl my-10 text-dark'>
				<form onSubmit={handleSubmit(saveOrganizationData)}>
					<ContentHeader className='flex w-full items-center justify-between py-5'>
						<p className='font-medium text-xl text-dark'>
							Основная информация о программе
						</p>
					</ContentHeader>
					<ContentBody className='dropzone flex flex-wrap flex-row gap-3 mb-10'>
						<div className='flex flex-row gap-4 flex-wrap w-full'>
							<ProfileInput
								className='basis-[calc(50%-16px)]'
								placeholder='Тур по БАО'
								label='Название программы'
								{...register('name', {
									required: 'Заполните поле',
									minLength: 2,
									validate: {
										noSpaces: value =>
											removeSpaces(value) ||
											'Поле не должно начинаться или заканчиваться пробелами',
									},
								})}
								error={errors.name?.message}
							/>
							<ProfileListBox
								label='Сложность'
								className='basis-[calc(50%-16px)]'
								list={programComplexity}
								width='w-full'
								onChange={item => setComplexity(item.id)}
							/>
							<ProfileInput
								className='basis-[calc(50%-16px)]'
								placeholder='21300'
								type='number'
								label='Цена'
								{...register('price', {
									required: 'Заполните поле',
									min: { value: 1, message: 'Минимальная цена 1 тг' },
									max: {
										value: 1000000,
										message: 'Максимальная цена 1000000 тг',
									},
								})}
								error={errors.price?.message}
							/>
						</div>
					</ContentBody>
					<ContentHeader className='flex w-full items-center justify-between py-5'>
						<p className='font-medium text-xl text-dark'>Фотография</p>
						<label
							htmlFor='file'
							className='bg-blue py-2 px-3 text-white font-medium rounded-lg cursor-pointer'
						>
							Загрузить фотографию
						</label>
					</ContentHeader>
					<ContentBody
						{...getRootProps({
							className: 'dropzone flex flex-wrap flex-row gap-3 mb-10',
						})}
					>
						<input
							id='file'
							type='file'
							className='input-zone'
							{...getInputProps()}
							name='theFile'
							accept='image/jpeg,image/png'
						/>
						{selectedFile?.file && (
							<div className='relative image-wrapper group transition-all duration-200 ease-in-out cursor-grab'>
								<span
									style={{
										backgroundColor: 'rgba(0, 0, 0, 0.3)',
									}}
									className='transition-all duration-200 ease-in-out group-hover:visible invisible flex group-hover:opacity-100 opacity-0 backdrop-blur-md rounded-xl absolute z-10 w-full items-center justify-center h-full'
								>
									<Trash
										onClick={() => {
											setSelectedFile(null)
										}}
										className='bg-[#FF3030] w-10 p-[6px] h-10 flex items-center justify-center rounded-xl cursor-pointer'
										size='20'
										color='#FFFFFF'
									/>
								</span>
								<span className='relative block rounded-xl'>
									<img
										className='rounded-xl w-[200px] h-[150px]'
										src={selectedFile?.preview}
										alt='Загруженная фотография'
									/>
								</span>
							</div>
						)}
					</ContentBody>
					<ContentHeader className='flex w-full items-center justify-between py-5'>
						<p className='font-medium text-xl text-dark'>Описание</p>
					</ContentHeader>
					<ContentBody className='flex flex-wrap flex-row gap-3 mb-10'>
						<ProfileTextarea
							rows={5}
							className='w-full resize-none'
							placeholder='В сфере внутреннего туризма компания работает с 2009 года. За это время мы организовали тысячи экскурсий, туров, обеспечили увлекательный досуг сотен тысяч туристов и проложили немало новых туристических маршрутов.'
							{...register('description', {
								required: 'Заполните поле',
								validate: {
									noSpaces: value =>
										removeSpaces(value) ||
										'Поле не должно начинаться или заканчиваться пробелами',
								},
							})}
							error={errors.description?.message}
						/>
						<span>Напишите 500 символов</span>
					</ContentBody>
					<ContentHeader className='flex w-full items-center justify-between py-5'>
						<p className='font-medium text-xl text-dark'>Программа тура</p>
					</ContentHeader>
					<ContentBody className='flex flex-wrap flex-row gap-3 mb-10 overflow-hidden'>
						<div className='w-full rounded-lg p-2 border-gray border-2'>
							<Editor
								toolbar={{
									options: [
										'inline',
										'blockType',
										'fontSize',
										'list',
										'textAlign',
										'link',
										'embedded',
										'emoji',
										'image',
										'remove',
										'history',
									],
								}}
								onEditorStateChange={state => setEditor(state)}
							/>
						</div>
					</ContentBody>
					<ContentHeader className='flex w-full items-center justify-between py-5'>
						<p className='font-medium text-xl text-dark'>Удобства и услуги</p>
					</ContentHeader>
					<ContentBody className='flex flex-col gap-y-3 mb-10'>
						<div className='flex flex-row gap-x-4 items-end'>
							<ProfileInput
								value={service}
								onKeyUp={event => {
									event.stopPropagation()
									if (event.key === 'Enter') {
										event.preventDefault()
										_addService()
									}
								}}
								className='w-80'
								label='Услуга'
								placeholder='Бесплатные кошки'
								onChange={addServiceHandler}
							/>
							<ProfileButton
								className='py-3'
								type='button'
								onClick={_addService}
							>
								Добавить
							</ProfileButton>
						</div>
						{error && <div className='text-red font-medium'>{error}</div>}
						<div className='flex flex-row flex-wrap gap-y-2'>
							{data?.map(service => (
								<div
									key={service.id}
									className='flex gap-x-2 items-center cursor-pointer basis-1/2 noselect'
								>
									<div
										onClick={() =>
											setServices(prevServices => {
												if (
													prevServices.find(_service => _service === service.id)
												) {
													return prevServices.filter(
														_service => service.id !== _service,
													)
												} else {
													return [...prevServices, service.id]
												}
											})
										}
										className='flex items-center gap-x-2'
									>
										<Checkbox
											checked={
												!!services.find(_service => service.id === _service)
											}
											onChange={() =>
												setServices(prevServices => [
													...prevServices,
													service.id,
												])
											}
										/>
										{service.title}
									</div>
									<div>
										<button
											className='text-[#4B3A5A] font-bold ml-1'
											type='button'
											onClick={() => eventDelete(service.id)}
										>
											<img src={close} width={10} height={10} alt='close' />
										</button>
									</div>
								</div>
							))}
						</div>
					</ContentBody>
					<ContentHeader className='flex w-full items-center justify-between py-5'>
						<p className='font-medium text-xl text-dark'>
							Контактная информация
						</p>
					</ContentHeader>
					<ContentBody className='flex flex-wrap flex-row gap-3 mb-10'>
						<div className='flex flex-row gap-4 w-full'>
							<ProfileInput
								className='basis-[calc(50%-16px)]'
								placeholder='Место сбора'
								label='Адрес'
								{...register('address', {
									required: 'Заполните поле',
									validate: {
										noSpaces: value =>
											removeSpaces(value) ||
											'Поле не должно начинаться или заканчиваться пробелами',
									},
								})}
								error={errors.address?.message}
							/>
						</div>
					</ContentBody>
					<div className='flex ml-auto w-max'>
						<ProfileButton type='submit'>Сохранить</ProfileButton>
					</div>
				</form>
			</Container>
		</>
	)
}

import { schemas } from '@/api/types'

interface Props {
	tourApplication: schemas['ListApplication']
	status: schemas['ListTourApplication']['status']
}

export const ApplicationTableRow = ({ tourApplication, status }: Props) => {
	return (
		<tr className='text-[#637381] text-sm'>
			<td className='pl-10 py-5'>
				<p className='font-medium	text-[#212B36]'>{tourApplication.fullName}</p>
				<p className='font-normal'>{tourApplication.phoneNumber}</p>
			</td>
			<td className='py-5'>{tourApplication.description}</td>
			<td className='py-5'>
				<div
					className={`px-4 py-1 w-fit  font-semibold text-xs rounded-3xl ${
						status === true
							? 'bg-[#E1F8F0] text-[#18A16F]'
							: 'bg-[#FADEDE] text-[#DC2626]'
					}`}
				>
					{status === true ? 'Активная' : 'Неактивная'}
				</div>
			</td>
			<td className='py-5'>{tourApplication.region}</td>
			<td className='py-5 text-right pr-5 '>
				{/* Temporrary action button is hidden */}
				<button className='text-[#3056D3] rounded-3xl border border-[#3056D3] py-2 px-5 hover:bg-[#3056D3]/5 text-sm md:px-3 hidden'>
					Действие
				</button>
			</td>
		</tr>
	)
}

import {AddPhoto} from '../static'
import {$authHost} from '.'
import {schemas} from './types'

export const getMyPhotos = async (
  tourId: string | number | undefined,
): Promise<schemas['TourShots'][]> => {
  const {data} = await $authHost.get(`/v1/shots/?tour_id=${tourId}`)
  return data
}

export const addPhoto = async ({
  formData,
}: AddPhoto): Promise<schemas['TourShots']> => {
  $authHost.defaults.headers.common['Content-Type'] = 'multipart/form-data'
  const {data} = await $authHost.post('/v1/shots/', formData)
  return data
}

export const deletePhoto = async (id: number | string | undefined) => {
  const {data} = await $authHost.delete(`/v1/shots/${id}/`)
  return data
}

export const editPhoto = async (
  request: schemas['PatchedTourShotsRequest'] & {
    id: number | string | undefined
  },
) => {
  const {data} = await $authHost.patch(`/v1/shots/${request.id}/`, request)
  return data
}

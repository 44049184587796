import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { ComponentPropsWithRef, forwardRef } from 'react'

interface Props extends ComponentPropsWithRef<'input'> {
	label?: string
	className?: string
	error?: string
	showPassword?: boolean
	setShowPassword?: () => void
}

export const ProfileInput = forwardRef<HTMLInputElement, Props>(
	(
		{
			label,
			className,
			error,
			disabled,
			showPassword,
			type,
			setShowPassword,
			...rest
		}: Props,
		ref,
	) => {
		return (
			<label className={clsx('font-medium text-dark block', className)}>
				{label && <span className='inline-block mb-1'>{label}</span>}
				<div className='flex flex-row w-full relative'>
					<input
						disabled={disabled}
						ref={ref}
						type={type}
						className={clsx(
							'block w-full rounded-lg border outline-none px-4 py-2.5 text-dark font-medium',
							disabled
								? 'border-[#E3E3E3] text-[#b7b7b7]'
								: 'border-[#D0D5DD] text-dark',
						)}
						{...rest}
					/>
					{showPassword != undefined && (
						<button
							type='button'
							className='absolute right-2.5 h-full flex items-center text-light-blue'
							onClick={setShowPassword}
						>
							{showPassword ? (
								<EyeIcon width={15} height={15} />
							) : (
								<EyeSlashIcon width={15} height={15} />
							)}
						</button>
					)}
				</div>
				{error ? (
					<span className='text-red font-medium text-sm'>{error}</span>
				) : (
					''
				)}
			</label>
		)
	},
)

ProfileInput.displayName = 'ProfileInput'

import { useNavigate } from '@tanstack/react-location'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getCountries, getRegions } from 'api/address'
import { createGuide } from 'api/guide/guide'
import { ProfileButton } from 'components/profile/ProfileButton'
import { ProfileInput } from 'components/profile/ProfileInput'
import { ProfileListBox } from 'components/profile/ProfileListBox'
import { ProfileTextarea } from 'components/profile/ProfileTextarea'
import { ContentBody } from 'components/ui/Content/ContentBody'
import { ContentHeader } from 'components/ui/Content/ContentHeader'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Map, Placemark, Polygon, YMaps } from 'react-yandex-maps'
import { GuideFields, URLFilter } from 'static'
import { useUserStore } from 'store/user'
import { removeSpaces } from 'utils/utils'

export const AddGuide = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<GuideFields>()
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const { user, editUser } = useUserStore()
	const { mutate } = useMutation(createGuide)
	const [coordinates, setCoordinates] = useState<number[]>([51.18, 71.45])
	const [filters, setFilters] = useState<URLFilter>({
		country_id: 1,
	})

	const {
		data: getCountriesQuery,
		isLoading: getCountriesLoading,
		isError: getCountriesError,
	} = useQuery(['countries'], getCountries)

	const {
		data: getAllRegionsQuery,
		refetch,
		isLoading: getAllRegionsLoading,
		isError: getAllRegionsError,
	} = useQuery(['regions'], () => getRegions(filters?.country_id))

	useEffect(() => {
		refetch()
	}, [filters.country_id])

	const region = getAllRegionsQuery?.find(t => t.id === filters.region_id)
	const country = getCountriesQuery?.find(t => t.id === filters.country_id)

	const onSubmit = (data: GuideFields) => {
		if (!user?.partner?.id) {
			toast.error('Ошибка при создании гида')
			return
		}
		mutate(
			{
				description: data.description,
				title: data.title,
				email: data.email,
				BIK: data.bik,
				IIK: data.iik,
				BIN: data.bin,
				currency: 1,
				org: user?.partner?.id,
				lat: coordinates[0],
				lon: coordinates[1],
				country: country?.id,
				region: region?.id,
				address: data.address,
				working_time: data.workingTime,
			},
			{
				onSuccess: response => {
					editUser({
						...user,
						partner: {
							...user.partner,
							pick: 'Guide',
							guide: {
								id: response.id,
							},
						},
					})
					queryClient.refetchQueries(['me'])
					toast.success('Создано')
					navigate({ to: '/' })
				},
				onError: (error: any) => {
					for (const key in error.response.data) {
						toast.error(error.response.data[key])
					}
				},
			},
		)
	}

	if (getAllRegionsLoading || getCountriesLoading) return <>Loading...</>

	if (getAllRegionsError || getCountriesError) return <>Error...</>

	return (
		<div className='mx-auto my-10'>
			<p className='text-2xl font-semibold text-dark text-left mb-10'>
				Добавление агентства
			</p>
			<form onSubmit={handleSubmit(onSubmit)}>
				<ContentHeader className='flex w-full items-center justify-between py-5'>
					<p className='font-medium text-xl text-dark'>Организация</p>
				</ContentHeader>
				<ContentBody className='flex flex-wrap flex-row gap-3 gap-y-3 mb-10'>
					<ProfileInput
						className='basis-[calc(50%-16px)]'
						error={errors.title?.message}
						placeholder='Название Организации'
						type='text'
						{...register('title', {
							required: 'Заполните поле',
							minLength: 3,
							validate: {
								noSpaces: value =>
									removeSpaces(value) ||
									'Поле не должно начинаться или заканчиваться пробелами',
							},
						})}
					/>
					<ProfileInput
						className='basis-[calc(50%-16px)]'
						error={errors.bin?.message}
						{...register('bin', {
							required: 'Заполните поле',
							validate: {
								noSpaces: value =>
									removeSpaces(value) ||
									'Поле не должно начинаться или заканчиваться пробелами',
								minLength: value =>
									value.length >= 12 || 'Минимальная длина 12 символов',
							},
						})}
						placeholder='Введите свой БИН'
						type='number'
					/>
					<ProfileInput
						className='basis-[calc(50%-16px)]'
						error={errors.iik?.message}
						{...register('iik', {
							required: 'Заполните поле',
							pattern: {
								value:
									/^([A-Z]{2}[-]?[0-9]{2})(?=(?:[-]?[A-Z0-9]){9,30}$)((?:[-]?[A-Z0-9]{3,5}){2,7})([-]?[A-Z0-9]{1,3})?$/,
								message: 'Введите ИИК в в верном формате',
							},
						})}
						placeholder='Введите свой ИИК (IBAN)'
						type='text'
					/>
					<ProfileInput
						className='basis-[calc(50%-16px)]'
						error={errors.bik?.message}
						{...register('bik', {
							required: 'Заполните поле',
							validate: {
								noSpaces: value =>
									removeSpaces(value) ||
									'Поле не должно начинаться или заканчиваться пробелами',
							},
						})}
						placeholder='Введите свой БИК'
						type='text'
						pattern='[A-Z0-9]{8}'
					/>
				</ContentBody>
				<ContentHeader className='flex w-full items-center justify-between py-5'>
					<p className='font-medium text-xl text-dark'>Контактная информация</p>
				</ContentHeader>
				<ContentBody className='flex flex-wrap flex-row gap-3 mb-10'>
					<ProfileInput
						className='basis-[calc(50%-16px)]'
						label='Адрес'
						placeholder='Адрес'
						{...register('address', {
							required: 'Заполните поле',
							minLength: 3,
							validate: {
								noSpaces: value =>
									removeSpaces(value) ||
									'Поле не должно начинаться или заканчиваться пробелами',
							},
						})}
						error={errors.address?.message}
					/>
					<ProfileInput
						className='basis-[calc(50%-16px)]'
						label='График работы (Пишите через запятую)'
						placeholder='Пн - Вт: 9:00 - 18:00, Сб - Вс: Выходной'
						{...register('workingTime', {
							required: 'Заполните поле',
							minLength: 3,
							validate: {
								noSpaces: value =>
									removeSpaces(value) ||
									'Поле не должно начинаться или заканчиваться пробелами',
							},
						})}
						error={errors.workingTime?.message}
					/>
					<ProfileInput
						className='basis-[calc(50%-16px)]'
						placeholder='info@example.com'
						label='Email'
						{...register('email', {
							required: 'Заполните поле',
							minLength: 3,
							validate: {
								noSpaces: value =>
									removeSpaces(value) ||
									'Поле не должно начинаться или заканчиваться пробелами',
							},
						})}
						error={errors.email?.message}
					/>
					<ProfileListBox
						label='Страна'
						className='basis-[calc(50%-16px)]'
						list={getCountriesQuery.map(country => {
							return { id: country.id, value: country.name }
						})}
						width='w-full'
						onChange={option => {
							setFilters(currentFilter => ({
								...(currentFilter?.category_id
									? {
											category_id: currentFilter?.category_id,
									  }
									: undefined),
								...(currentFilter?.region_id
									? {
											region_id: currentFilter?.region_id,
									  }
									: undefined),
								...(option.id !== 0 && { country_id: option.id }),
							}))
						}}
					/>
					<ProfileListBox
						label='Регион'
						className='basis-[calc(50%-16px)]'
						list={[
							...getAllRegionsQuery.map(region => {
								return { id: region.id, value: region.name }
							}),
						]}
						width='w-full'
						onChange={option => {
							setFilters(currentFilter => ({
								...(currentFilter?.category_id
									? {
											category_id: currentFilter?.category_id,
									  }
									: undefined),
								country_id: currentFilter?.country_id,
								...(option.id !== 0 && { region_id: option.id }),
							}))
						}}
					/>
				</ContentBody>
				<ContentHeader className='flex w-full items-center justify-between py-5'>
					<p className='font-medium text-xl text-dark'>Описание</p>
				</ContentHeader>
				<ContentBody className='flex flex-wrap flex-row gap-3 mb-10'>
					<ProfileTextarea
						rows={5}
						className='w-full resize-none'
						placeholder='Лечебно-оздоровительный комплекс или просто санаторий Ок-Жетпес расположен на берегу озера Боровое, немного в стороне от шумного курортного посёлка Бурабай. Здесь в тишине и покое его гости могут не только прекрасно отдохнуть, но и поправить своё здоровье.'
						{...register('description', {
							required: 'Заполните поле',
							validate: {
								noSpaces: value =>
									removeSpaces(value) ||
									'Поле не должно начинаться или заканчиваться пробелами',
							},
						})}
						error={errors.description?.message}
					/>
					<span>Напишите 500 символов</span>
				</ContentBody>
				<ContentHeader className='flex w-full items-center justify-between py-5'>
					<p className='font-medium text-xl text-dark'>
						Карта
						<span className='text-gray text-sm inline-block ml-2'>
							(Укажите на карте организацию)
						</span>
					</p>
				</ContentHeader>
				<ContentBody className='mb-10'>
					<YMaps query={{ apikey: 'f80bf96b-8fa3-4478-ac9a-39e597b9757a' }}>
						{/* <ReactNotification /> */}
						<Map
							// className='my-10'
							modules={['geocode']}
							defaultState={{ center: [51.18, 71.45], zoom: 9 }}
							width={'100%'}
							height={'250px'}
							onClick={(event: any) => {
								setCoordinates(event.get('coords'))
							}}
						>
							<Polygon options={{ visible: false }} />
							{coordinates && <Placemark geometry={coordinates} />}
						</Map>
					</YMaps>
				</ContentBody>
				<div className='flex justify-end'>
					<ProfileButton type='submit'>
						Сохранить
					</ProfileButton>
				</div>
			</form>
		</div>
	)
}

// TODO: Засунуть API ключ YMaps'а в .env

import React, {useState} from 'react'
import {Authorization} from '../../modules/pages/authorization/Authorization'
import {ForgotPassword} from '../../modules/pages/authorization/ForgotPassword'
import {Helmet} from 'react-helmet'

export const LoginPage = () => {

  const [isLogin, setIsLogin] = useState(false)
  const [isForgotPassword, setIsForgotPassword] = useState(false)

  return (
    <div className='flex justify-center items-center min-h-screen'>
      <Helmet>
        <title>Авторизация | MTour</title>
        <meta name='robots' content='noindex'/>
      </Helmet>
      <ForgotPassword
        onClose={() => {
          setIsForgotPassword(false)
        }}
        isVisible={isForgotPassword}
      />
      <Authorization
        forgotPassword={() => {
          setIsLogin(false)
          setTimeout(() => setIsForgotPassword(true), 400)
        }}
      />
    </div>
  )
}

